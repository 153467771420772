import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as PlayIcon } from '../../assets/images/play.svg';
import { ReactComponent as SkipForward15Seconds } from '../../assets/images/forward-15.svg';
import { ReactComponent as SkipBackward15Seconds } from '../../assets/images/back-15.svg';
import spinnerUrl from '../../assets/images/buffering-blue@3x.png';
import {
  ServiceFactory,
  VideoPlayerService,
  VideoPlayerConstants,
} from '../../servicelib';
import './video.component.scss';

const VIDEO_WRAPPER_ID = 'video-wrapper';
const overlayHideTimeout = { id: undefined, DELAY: 5000 };
const previousPlaybackState = { playbackReadyState: undefined };
const previousMediaId = { mediaId: undefined };
declare const $badger: any;

const Video = props => {
  const {
    channelLogoForVod,
    channelName,
    showLongTitle,
    episodeLongTitle,
    emissionDay,
    abbreviatedDuration,
    showSegmentViewer,
    showEdp,
    showRelatedContent,
    showSkipIcon,
    isPaused,
    SKIP_FORWARD_15_SECONDS_ICON,
    SKIP_BACKWARD_15_SECONDS_ICON,
    getElapsedTime,
    getDuration,
    playheadTimestamp,
    CONTENT_STATES,
    mediaId,
    showUpNext,
    videoDuration,
    episode,
    skip15IntervalId,
  } = props;
  const videoPlayerService = ServiceFactory.getInstance(
    VideoPlayerService,
  ) as VideoPlayerService;

  const isMounted = useRef(false);

  useEffect(() => {
    const videoWrapper = document.getElementById(VIDEO_WRAPPER_ID);
    const videoElement = videoWrapper.firstChild as any;

    if (showUpNext) {
      videoWrapper.style.visibility = null;
      videoWrapper.style.width = null;
      videoWrapper.style.height = null;
      videoWrapper.style.background = null;
      videoElement.style.visibility = null;
      videoElement.style.width = null;
      videoElement.style.height = null;
    } else {
      videoWrapper.style.visibility = 'visible';
      videoWrapper.style.width = '120rem';
      videoWrapper.style.height = '67.5rem';
      videoElement.style.visibility = 'visible';
      videoElement.style.width = '120rem';
      videoElement.style.height = '67.5rem';
    }

    return () => {
      videoWrapper.style.visibility = null;
      videoWrapper.style.width = null;
      videoWrapper.style.height = null;
      videoWrapper.style.background = null;
      videoElement.style.visibility = null;
      videoElement.style.width = null;
      videoElement.style.height = null;

      $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
        contentState: CONTENT_STATES.PAUSED,
      });
      videoPlayerService.pause().subscribe();
    };
  }, [showUpNext]);

  const [showSpinner, setShowSpinner] = useState(true);
  useEffect(() => {
    const subscription = videoPlayerService.playbackReady$.subscribe(
      isPlaybackReady => {
        //All this logic avoids the player from getting stuck when returning to a video from the Mini Player
        if (isPlaybackReady === null) {
          previousPlaybackState.playbackReadyState = 'LOADING';
        } else if (isPlaybackReady) {
          if (
            previousPlaybackState.playbackReadyState === 'LOADING' ||
            previousPlaybackState.playbackReadyState === undefined
          ) {
            if (!isPlaybackReady.willAutoplay) {
              videoPlayerService.resume().subscribe();
            }
            $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
              contentState: CONTENT_STATES.PLAYING,
            });

            setShowSpinner(false);
            overlayHideTimeout.id = setTimeout(() => {
              setShowOverlay(false);
            }, overlayHideTimeout.DELAY);
          } else {
            previousPlaybackState.playbackReadyState = 'LOADING';
          }
        }
      },
    );

    return () => {
      previousPlaybackState.playbackReadyState = undefined;
      subscription.unsubscribe();
    };
  }, []);

  const [showOverlay, setShowOverlay] = useState(true);
  useEffect(() => {
    if (isMounted && isMounted.current) {
      if (isPaused) {
        clearTimeout(overlayHideTimeout.id);
        setShowOverlay(true);
      } else {
        overlayHideTimeout.id = setTimeout(() => {
          setShowOverlay(false);
        }, overlayHideTimeout.DELAY);
      }
    } else {
      isMounted.current = true;
    }

    return () => clearTimeout(overlayHideTimeout.id);
  }, [isPaused]);

  useEffect(() => {
    if (previousMediaId.mediaId === undefined) {
      previousMediaId.mediaId = mediaId;
    } else if (previousMediaId.mediaId !== mediaId) {
      previousMediaId.mediaId = mediaId;
      setShowSpinner(true);
      setShowOverlay(true);
    }
  }, [mediaId]);

  useEffect(() => {
    if (isMounted.current) {
      if (skip15IntervalId) {
        setShowOverlay(true);
      } else {
        overlayHideTimeout.id = setTimeout(() => {
          setShowOverlay(false);
        }, overlayHideTimeout.DELAY);
      }
    }
  }, [skip15IntervalId]);

  const [progressBarMarkers, setProgressBarMarkers] = useState([]);
  useEffect(() => {
    if (episode) {
      const { segments = [], originalSegments = [] } = episode;
      const segmentsInVideo = segments.length ? segments : originalSegments;
      const segmentMarkers = [];

      segmentsInVideo.forEach((segment, segmentIndex) => {
        const segmentPercentage = (segment.duration / videoDuration) * 100;
        const previousSegmentDuration =
          segmentIndex - 1 < 0
            ? 0
            : segmentMarkers[segmentIndex - 1].combinedSegmentDuration;
        segmentMarkers.push({
          segmentPercentage,
          segmentDuration: segment.duration,
          previousSegmentDuration,
          combinedSegmentDuration: previousSegmentDuration + segment.duration,
        });
      });

      setProgressBarMarkers(segmentMarkers);
    } else {
      setProgressBarMarkers([]);
    }
  }, [episode, videoDuration]);

  const getSegmentProgress = (marker, playheadTimestamp) => {
    let segmentProgress;

    if (
      playheadTimestamp >= marker.previousSegmentDuration &&
      playheadTimestamp <= marker.combinedSegmentDuration
    ) {
      segmentProgress =
        ((playheadTimestamp - marker.previousSegmentDuration) /
          marker.segmentDuration) *
        100;
    } else if (playheadTimestamp <= marker.combinedSegmentDuration) {
      segmentProgress = 0;
    } else if (playheadTimestamp >= marker.combinedSegmentDuration) {
      segmentProgress = 100;
    }

    return segmentProgress;
  };

  return (
    <div className="now-playing-video-container">
      {(showSpinner ||
        videoPlayerService.getState() === VideoPlayerConstants.SEEKING) && (
        <div
          className="video-spinner"
          style={{ backgroundImage: `url("${spinnerUrl}")` }}
        />
      )}
      {!showEdp && !showRelatedContent && !showSegmentViewer && !showUpNext && (
        <>
          <div
            className={`now-playing-video-metadata-container ${
              showOverlay ? 'is-paused' : ''
            }`}
          >
            <div
              className="now-playing-video-show-logo"
              style={{
                backgroundImage: `url("${channelLogoForVod}?&width=180&height=144&preserveAspect=true")`,
              }}
            />
            <div className="now-playing-video-media-info-container">
              <div className="now-playing-video-channel-info">
                <span className="now-playing-video-channel">
                  {`${channelName ? channelName + ' - On Demand' : ''} `}
                </span>
                {showLongTitle && <span className="dot-icon" />}
                <span className="now-playing-video-show">
                  {showLongTitle || ''}
                </span>
              </div>
              <div className="now-playing-video-episode-title">
                {episodeLongTitle || ''}
              </div>
              <div className="now-playing-video-emission-info">
                <span className="now-playing-video-emission-day">
                  {emissionDay || ''}
                </span>
                {emissionDay && <span className="dot-icon" />}
                <span className="now-playing-video-duration">
                  {abbreviatedDuration || ''}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`now-playing-video-player-controls-container ${
              showOverlay ? 'is-paused' : ''
            }`}
          >
            <div
              className={`video-playback-icon-container ${
                isPaused || showSkipIcon.icon ? 'loaded' : ''
              }`}
            >
              <PlayIcon style={{ display: isPaused ? 'block' : 'none' }} />
              <SkipForward15Seconds
                style={{
                  display:
                    showSkipIcon.icon === SKIP_FORWARD_15_SECONDS_ICON
                      ? 'block'
                      : 'none',
                }}
              />
              <SkipBackward15Seconds
                style={{
                  display:
                    showSkipIcon.icon === SKIP_BACKWARD_15_SECONDS_ICON
                      ? 'block'
                      : 'none',
                }}
              />
            </div>
            <div className="video-progress-bar-container">
              <div className="video-current-time">{getElapsedTime()}</div>
              <div className="video-progress-bar">
                {progressBarMarkers.map((marker, markerIndex) => {
                  const segmentProgress = getSegmentProgress(
                    marker,
                    playheadTimestamp,
                  );

                  return (
                    <div
                      key={`video-progress-bar-segment-${markerIndex}`}
                      className="video-segment"
                      style={{
                        width: `${marker.segmentPercentage - 0.5}%`,
                      }}
                    >
                      <div className="video-progress-bar-background" />
                      <div
                        className="video-current-listening-position"
                        style={{
                          width: `${
                            segmentProgress > 100 ? 100 : segmentProgress
                          }%`,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="video-end-time">{getDuration()}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Video;
