const loadAdobeAnalytics = callback => {
  const existingScript = document.getElementById('adobe-analytics');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src =
      'https://assets.adobedtm.com/98980a4572ae/420292f703d5/launch-d16054950123.min.js';
    script.id = 'adobe-analytics';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback(true);
    };
    script.onerror = () => {
      if (callback) callback(true);
    };
  }
  if (existingScript && callback) callback(true);
};
export default loadAdobeAnalytics;
