import React from 'react';
import FocusDiv from '../focus-div/FocusDiv';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { getLastFocusedTileId } from '../../redux/selector/xdk.store';

type props = {
  nav: XDKNav;
  pageId: string;
  data: any;
  component: React.ComponentType<any>;
  classNameItemWrapper: string;
  onWrapperClick: any;
  onItemBlur: any;
  onItemFocus: any;
  customOnItemClick: any;
  customOnItemFocus: any;
  rowIndex: number;
  itemIndex: number;
};

const GridItem = ({
  nav,
  pageId,
  data,
  component: Component,
  classNameItemWrapper,
  onWrapperClick,
  onItemBlur,
  onItemFocus,
  customOnItemClick,
  customOnItemFocus,
  rowIndex,
  itemIndex,
}: props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [clicked, setClicked] = useState(0);
  const lastFocusedTile = useSelector(getLastFocusedTileId);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (onWrapperClick) {
      onWrapperClick(nav.id);
    } else {
      dispatch(
        saveLastFocusedTileId({
          ...lastFocusedTile,
          [pageId]: nav.id,
        }),
      );
    }
    setClicked(clicked + 1);
  }, [clicked]);

  return (
    <FocusDiv
      nav={nav}
      className={classNameItemWrapper}
      onFocus={() => {
        onItemFocus();
        setIsFocused(true);
      }}
      onBlur={() => {
        onItemBlur();
        setIsFocused(false);
      }}
      onClick={onClick}
    >
      <Component
        data={data}
        clicked={clicked}
        isFocused={isFocused}
        customOnItemClick={customOnItemClick}
        customOnItemFocus={customOnItemFocus}
        rowIndex={rowIndex}
        itemIndex={itemIndex}
      />
    </FocusDiv>
  );
};

export default GridItem;
