export class DeviceInfo {
  public appRegion: string;
  public language: string;
  public browser: string;
  public browserVersion: string;
  public clientCapabilities: Array<string>;
  public clientDeviceId: string;
  public clientDeviceType: string;
  public deviceModel: string;
  public osVersion: string;
  public platform: string;
  public player: string;
  public sxmAppVersion: string;
  public sxmGitHashNumber: string;
  public sxmTeamCityBuildNumber: string;
  public isChromeBrowser: boolean;
  public isMobile: boolean;
  public isNative: boolean;
  public supportsAddlChannels: boolean;
  public supportsVideoSdkAnalytics: boolean;
  public deviceMake: string;
  public supportsVideo: boolean;
}
