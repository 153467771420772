import React from 'react';
import FocusDiv from '../focus-div/FocusDiv';
import GridItemWrapper from './GridItemWrapper';
import styles from './grid.module.scss';

type props = {
  pageId: string;
  nav: XDKNav;
  itemsNav: XDKNav[];
  isVertical: boolean;
  data: any[];
  component: React.ComponentType<any>;
  classNameItemWrapper: string;
  onWrapperClick: any;
  onItemBlur: any;
  onItemFocus: any;
  customOnItemClick: any;
  customOnItemFocus: any;
  rowIndex: number;
};

const Row = ({
  pageId,
  nav,
  itemsNav,
  isVertical,
  data,
  component,
  classNameItemWrapper,
  onWrapperClick,
  onItemBlur,
  onItemFocus,
  customOnItemClick,
  customOnItemFocus,
  rowIndex,
}: props) => {
  return (
    <FocusDiv nav={nav}>
      <div className={isVertical ? '' : styles['row-wrapper']}>
        {data.map((_, idx) => {
          return (
            <GridItemWrapper
              pageId={pageId}
              key={itemsNav[idx].id}
              nav={itemsNav[idx]}
              data={data[idx]}
              component={component}
              classNameItemWrapper={classNameItemWrapper}
              onWrapperClick={onWrapperClick}
              onItemBlur={onItemBlur}
              onItemFocus={onItemFocus}
              customOnItemClick={customOnItemClick}
              customOnItemFocus={customOnItemFocus}
              rowIndex={rowIndex}
              itemIndex={idx}
            />
          );
        })}
      </div>
    </FocusDiv>
  );
};

export default Row;
