/**
 * @MODULE:     service-lib
 * @CREATED:    07/31/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * appRouteConstants are route constants are used for routing and Configure URL routes.
 **/

export const appRouteConstants = {
  AUTH: {
    LOGIN: '/login',
    LOGIN_CONFIRMATION: '/login-confirmation',
    OPEN_ACCESS_CONFIRMATION: '/open-access-confirmation',
    OPEN_ACCESS_EXPIRED: '/open-access-expired',

    //REGISTER: '/register',
    FRICTIONLESS: '/frictionless-sign-in',
  },

  //FreeTier
  FT_WELCOME: '/freetier/welcome',
  FT_UPSELL: '/freetier/upsell',
  SUBSCRIBE_SCREEN: '/subscribereg',
  EXPLORE: '/explorereg',
  ACCESS_NOW: '/accessnow',
  PRIVACY_POLICY: '/privacy-policy',
  ACTIVATE_DEVICE: '/activate-device',

  // Main navigation
  HOME: '/home',
  HOME_FORYOU: '/home/foryou',
  HOME_MUSIC: '/home/music',
  PROFILE: '/profile',
  FAVORITES: '/favorites',
  FAVORITES_CHANNELS: '/favorites/channels',
  WELCOME: '/welcome',
  // Sub-Nav
  CATEGORY: '/category-listing',
  CATEGORY_ROUTE_PARAMS: {
    LIST_VIEW: {
      ON_DEMAND: '/ondemand',
      CHANNELS: '/channels',
      SHOWS: '/shows',
      EPISODES: '/episodes',
    },
  },
  FOR_YOU: '/foryou',
  ENTERTAINMENT: '/entertainment',
  MUSIC: '/music',
  NEWS: '/news',
  SPORTS: '/sports',

  // Not sure that these two routes are still in use, but I'm cautious about removing them.
  HOWARD: '/howardstern',
  HOWARD_SUPERCATEGORY: '/howard',

  HOME_HOWARD: '/home/howard',

  SEARCH: '/query',
  SEARCH_RESULT: '/search-result/:keyword',
  NOW_PLAYING: '/now-playing',
  ON_DEMAND: {
    EPISODES_LIST: '/on-demand-episodes',
    TYPE_PARAM: '/type',
    VIDEO: '/video',
    AUDIO: '/audio',
    VIDEO_AND_AUDIO: '/video-and-audio',
  },
  RECENTLY_PLAYED: '/recently-played',
  APPLICATION_SETTINGS: '/application-settings',
  MESSAGING_SETTINGS: '/messaging-settings',
  REMINDER_SETTINGS: '/reminder-settings',
  SHOW_REMINDER_DETAILS: '/show-reminder-details',
  MANAGE_SHOW_REMINDERS: '/manage-show-reminders',
  SEEDED_SETTINGS: '/manage-pandora-stations',
  VIEW_ALL: '/view-all',
  ALL_CHANNELS: '/all-channels',
  ALL_ON_DEMAND: '/all-on-demand',
  ALL_VIDEO: '/all_video',
  ALL_PODCASTS: '/all_podcasts',
  CATEGORY_PAGE: '/category-page',
  PROFILE_USER: '/user',
  COLLECTION: '/collection-carousel',
  ENHANCED_EDP: '/enhanced-edp',
};

export const coreMenuRoutes = {
  'discover-button': appRouteConstants.HOME_FORYOU,
  'favorites-button': appRouteConstants.FAVORITES_CHANNELS,
  'recent-button': appRouteConstants.RECENTLY_PLAYED,
  'search-button': appRouteConstants.SEARCH,
  'settings-button': appRouteConstants.APPLICATION_SETTINGS,
};
