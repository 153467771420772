import { createSelector } from 'reselect';

export const selectXdkState = createSelector(
  state => (state as any).xdkStore,
  xdkStore => xdkStore,
);

export const getPageBackId = createSelector(
  selectXdkState,
  state => state.backId,
);
export const getPageExitId = createSelector(
  selectXdkState,
  state => state.exitId,
);
export const getPageFocusId = createSelector(
  selectXdkState,
  state => state.focusId,
);
export const getLastFocusedTileId = createSelector(
  selectXdkState,
  state => state.lastFocusedTile,
);
export const getShowPodcastsMenuOption = createSelector(
  selectXdkState,
  state => state.shouldShowPodcastsMenuOption,
);
export const getShowVideosMenuOption = createSelector(
  selectXdkState,
  state => state.shouldShowVideosMenuOption,
);
export const getPageRightId = createSelector(
  selectXdkState,
  state => state.rightId,
);
export const getPageLeftId = createSelector(
  selectXdkState,
  state => state.leftId,
);
export const getKeyPressedId = createSelector(
  selectXdkState,
  state => state.keyPressedId,
);
export const getFFWKeyPressedId = createSelector(
  selectXdkState,
  state => state.ffwId,
);
export const getRewindKeyPressedId = createSelector(
  selectXdkState,
  state => state.rewindId,
);
export const getPlayPauseKeyPressedId = createSelector(
  selectXdkState,
  state => state.playPauseId,
);
export const getFreeTrialCounter = createSelector(
  selectXdkState,
  state => state.timeRemaining,
);
export const getDoubleTapRightId = createSelector(
  selectXdkState,
  state => state.doubleTapRightId,
);
export const getDoubleTapLeftId = createSelector(
  selectXdkState,
  state => state.doubleTapLeftId,
);
