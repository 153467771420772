/**
 * Logger that outputs log statements to the console like:
 *
 *          13:36:22:069 [DEBUG] MyClass - methodName( some message. )
 *
 *      When called like:
 *
 *          logger.debug(`setName( first = ${myVar}, last = ${anotherVar} )`);
 *
 *      Performs string token substitution in ES6 style.
 *
 *      NOTE: By default the log level is set to "warn" in the constructor; however, the log level
 *      can also be set at runtime via the query string param `logLevel` using the respective levels.
 *      It can also be set to debug simply by setting a query string param `debug` to anything truthy.
 *
 */
/*  tslint:disable: no-console */
import { getCurrentUrlQueryParams } from '../util/utilities';

declare const $badger: any;

export class Logger {
  /**
   * @property {number} latestLoggerCode - Number indicating the latest created instantce number of the logger
   */
  public static latestLoggerCode = 0;

  /**
   * @property {number} loggerCode - Number assigned to the instance of this logger
   */
  public loggerCode = 0;

  /**
   * @property {Boolean} isEnabled - Flag indicating if this logger instance is enabled. If the "global",
   * static `isEnabled` property is true then this is ignored.
   */
  public static isEnabled = true;

  /**
   * A constant. Indicates the "log" logging level.
   * @type {Number}
   * @const
   */
  public static LEVEL_ALL = 0;

  /**
   * A constant. Indicates the "log" logging level.
   * @type {Number}
   * @const
   */
  public static LEVEL_LOG = 2;

  /**
   * A constant. Indicates the "debug" logging level.
   * @type {Number}
   * @const
   */
  public static LEVEL_DEBUG = 4;

  /**
   * A constant. Indicates the "info" logging level.
   * @type {Number}
   * @const
   */
  public static LEVEL_INFO = 6;

  /**
   * A constant. Indicates the "warn" logging level.
   * @type {Number}
   * @const
   */
  public static LEVEL_WARN = 8;

  /**
   * A constant. Indicates the "error" logging level.
   * @type {Number}
   * @const
   */
  public static LEVEL_ERROR = 10;

  /**
   * A constant. Indicates the "fatal" logging level.
   * @type {Number}
   * @const
   */
  public static LEVEL_FATAL = 1000;

  /**
   * @property {Number} isEnabled - Flag indicating if this logger instance is enabled. If the "global",
   * static `isEnabled` property is true then this is ignored.
   */
  public static level: number = Logger.LEVEL_ALL;

  /**
   * A constant that controls the max number of log lines that are kept around in the log history
   * @type {Number}
   * @const
   */
  public static MAX_LINES_HISTORY = 1000;

  /**
   * A constant. Indicates the "fatal" logging level.
   * @type {Number}
   * @const
   */
  public static logHistory: Array<string> = [];

  /**
   * Creates a logger that outputs the following format:
   *
   * 16:11:45:956 DEBUG [AuthenticationController] - login: username = a, password = a
   *
   * @param {String} context - The string name used for the logger. This is often the class name of the object the
   * logger is used in.
   * @returns {Logger} An instance of the WebAudioPlayerLogger logger.
   */
  public static getLogger(context: string): Logger {
    if (context == null) {
      throw new Error('invalid logger name');
    }

    return new Logger(context);
  }

  /**
   * Creates a print-friendly timestamp in the form of 16:11:45:956 for logging purposes.
   *
   * @return {String} A timestamp in the form of 16:11:45:956.
   */
  public static getTimestamp(date?: Date): string {
    date = date || new Date();
    let hours: number | string = date.getHours();
    let minutes: number | string = date.getMinutes();
    let seconds: number | string = date.getSeconds();
    let milliseconds: number | string = date.getMilliseconds();

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    if (milliseconds < 10) {
      milliseconds = '00' + milliseconds;
    } else if (milliseconds < 100) {
      milliseconds = '0' + milliseconds;
    }

    return hours + ':' + minutes + ':' + seconds + ':' + milliseconds;
  }

  /**
   * Creates a readable log message with the following format:
   *
   * 13:36:22:069 [DEBUG] MyClass - methodName( some message. )
   *
   * @param {String} context - Usually the class name or context for the logger.
   * @param {Number} level - The log level.
   * @param {String} msg - The message to be logged.
   * @returns {String}
   */
  public static getPrintFriendlyLogStatement(
    context: string = '',
    level: number = 0,
    msg: string,
  ): string {
    // TODO: BMR: Cleanup this log level switch as similar is done above.
    let levelStr = '';
    switch (level) {
      case Logger.LEVEL_INFO:
        levelStr = 'INFO';
        break;

      case Logger.LEVEL_WARN:
        levelStr = 'WARN';
        break;

      case Logger.LEVEL_ERROR:
      case Logger.LEVEL_FATAL:
        levelStr = 'ERROR';
        break;

      default:
        levelStr = 'DEBUG';
        break;
    }

    return `[JS] ${Logger.getTimestamp()} [${levelStr}] ${context} - ${msg}`;
  }

  /**
   * @property {String} context - The context is typically the class name or descriptor for
   * this logger instance.
   */
  public context = '';

  /**
   * Initialize the Logger and create a context for it.
   */
  constructor(context: string = '') {
    Logger.latestLoggerCode += 1;
    this.loggerCode = Logger.latestLoggerCode;
    this.context = context;
    Logger.level = this.getQueryStringLogLevel() || Logger.LEVEL_WARN;
  }

  /**
   * Attempts to grab the log level specified in the query string. If it finds any of the string values:
   *
   *      * all
   *      * debug
   *      * info
   *      * warn
   *      * error
   *      * fatal
   *
   * It will set the corresponding level. It is case insensitive.
   * @returns {number}
   */
  private getQueryStringLogLevel(): number {
    const params: any = getCurrentUrlQueryParams();
    const logLevel: string = params.debug ? 'debug' : params.logLevel || '';

    switch (logLevel.toLowerCase()) {
      case 'all':
        return Logger.LEVEL_ALL;

      case 'debug':
        return Logger.LEVEL_DEBUG;

      case 'info':
        return Logger.LEVEL_INFO;

      case 'warn':
        return Logger.LEVEL_WARN;

      case 'error':
        return Logger.LEVEL_ERROR;

      case 'fatal':
        return Logger.LEVEL_FATAL;

      default:
        return Logger.LEVEL_WARN;
    }
  }

  public getLogger(context: string) {
    return Logger.getLogger(context);
  }

  /**
   * Debug.
   */
  public debug(...args: any[]): void {
    this.logIt(Logger.LEVEL_DEBUG, args);
  }

  /**
   * Info.
   */
  public info(...args: any[]): void {
    this.logIt(Logger.LEVEL_INFO, args);
  }

  /**
   * Warn.
   */
  public warn(...args: any[]): void {
    this.logIt(Logger.LEVEL_WARN, args);
  }

  /**
   * Error.
   */
  public error(...args: any[]): void {
    this.logIt(Logger.LEVEL_ERROR, args);
  }

  /**
   * Log the user out.
   *
   * TODO: BMR: 02/09/2017: Consider using return console.log.bind(console, ...consoleArgs); -- details below.
   *
   * Something like:
   *
   * This function returns the bound console.log, which requires to do getLog(...)(). instead of logging directly
   * from here, which would be log(...), to preserve the correct file name and line number in the logs lines.
   */
  private logIt(level: number, ...args: any[]): void {
    const msg = Logger.getPrintFriendlyLogStatement(
      this.context,
      level,
      args[0][0],
    );

    Logger.logHistory.push(msg);

    if (Logger.logHistory.length > Logger.MAX_LINES_HISTORY) {
      Logger.logHistory.shift();
    }

    const validLevel = level >= Logger.level;

    // Don't allow logging if it's not enabled or not a valid level.
    if (Logger.isEnabled && validLevel) {
      // determine the log level and log to the console accordingly
      switch (level) {
        case Logger.LEVEL_INFO:
          console.info(msg);
          break;

        case Logger.LEVEL_WARN:
          console.warn(msg);
          break;

        case Logger.LEVEL_ERROR:
        case Logger.LEVEL_FATAL:
          console.error(msg);
          break;

        default:
          console.log(msg);
          break;
      }
    }

    if (level === Logger.LEVEL_ERROR) {
      $badger.errorMetricsHandler(msg, false, this.loggerCode);
    }
  }
}
