import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import './edp.component.scss';

const EDPActionButton = props => {
  const { children, className, ...rest } = props;
  const theme = {
    button: `edp-action-button ${className || ''}`,
    buttonFocused: 'edp-action-button-focused',
  };
  return (
    <FocusButton theme={theme} {...rest}>
      {children}
    </FocusButton>
  );
};

export default EDPActionButton;
