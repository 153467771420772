import config from './@accedo/xdk-device-x1-rdk/entry';

const CONFIG = {
  devices: {
    packages: [
      {
        id: 'x1',
        detection: config.detection,
        defaultConfig: () =>
          import('@accedo/xdk-device-workstation/esm/defaultConfig.js'),
        DevicePackage: () =>
          import('@accedo/xdk-device-workstation/esm/DevicePackage.js'),
      },
      {
        id: 'workstation',
        detection: () =>
          import('@accedo/xdk-device-workstation/esm/detection.js'),
        defaultConfig: () =>
          import('@accedo/xdk-device-workstation/esm/defaultConfig.js'),
        DevicePackage: () =>
          import('@accedo/xdk-device-workstation/esm/DevicePackage.js'),
      },
    ],
    // mandatory, details of the id, i.e workstation
    details: {
      workstation: {
        // same as the id from packages
        cookieName: 'sirius-xm-x1-cloud-cookie', //this is just an example, please update with your own app name
      },
      x1: {
        cookieName: 'sirius-xm-x1-cloud-cookie',
        storages: [
          {
            id: 'device/storage/Cookie',
            'type:': 'cookie',
          },
        ],
      },
    },
  },
};

export default CONFIG;
