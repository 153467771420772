import React, { useRef, useEffect, useState } from 'react';
import { take } from 'rxjs/operators';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { withFocus, focusManager } from '@accedo/vdkweb-navigation';
import { selectNPArtistSelectorSegment } from '../../redux/selector/carousel.store';
import {
  getIsInterstitial,
  getCurrentEpisodeZuluStartTime,
} from '../../redux/selector/now-playing.store';
import { useObservable, useFavorite } from '../../hooks';
import EDPSectionButton from './edp-section-button.component';
import EDPActionButton from './edp-action-button.component';
import remoteDown from '../../assets/images/remote-down.png';
import remoteUp from '../../assets/images/remote-up.png';
import remoteLeftRight from '../../assets/images/remote-left-right.png';
import remoteVideoDown from '../../assets/images/remote-video-down.png';
import remoteVideoUp from '../../assets/images/remote-video-up.png';
import remoteVideoLeftRight from '../../assets/images/remote-video-left-right.png';
import thumbsUp from '../../assets/images/thumbs-up.png';
import thumbsDown from '../../assets/images/thumbs-down.png';
import { ReactComponent as ThumbsDownIcon } from '../../assets/images/thumbs_down.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/images/thumbs_up.svg';
import { ReactComponent as NextIcon } from '../../assets/images/next.svg';
import {
  ServiceFactory,
  MediaTimestampService,
  LiveTimeService,
  SeekService,
  FavoriteService,
  ActionTypes,
  neriticActionConstants,
  AffinityConstants,
  AffinityService,
  AffinityType,
  CurrentlyPlayingService,
  ICurrentlyPlayingMedia,
  IDmcaInfoItem,
} from '../../servicelib';
import { NeriticLinkService } from '../../sxmservicelayer/neritic-links';
import { EDP } from '../../utils/navigationMap';
import './edp.component.scss';
import FreeTrialCounterComponent from '../free-trial-counter/free-trial-counter.component';

const favoriteDissmis = { timeoutId: null, DELAY: 3000 };
const dislikedSongTimeout = { id: null, DELAY: 1250 };

const FIVE_HOUR_BUFFER_MS = 18000000;
const ONE_HOUR_S = 3600;
const ONE_HOUR_MS = 3600000;

const EDPInformationDrawer = props => {
  const {
    showEdp,
    setShowEdp,
    albumImageUrl,
    channelLogoUrl,
    channelBackgroundImage,
    channel,
    episode,
    show,
    episodeStartTime,
    episodeEndTime,
    isLive,
    isDisallowed,
    isRestricted,
    isLiveAndDisallowed,
    isOnDemand,
    isSeededRadio,
    isAIC,
    isAod,
    isVod,
    isIrisPodcast,
    artistName,
    trackName,
    nowPlayingStore,
    nowPlayingNavId,
    dmcaService,
    onSkipForward,
    isSkipForwardDisabled,
    abbreviatedDuration,
    setShowSegmentViewer,
    emissionDay,
    posterImageUrl,
    mediaPlayerService,
    isPlayheadBehindLive,
    getLiveShowRestartPosition,
    isOnFreeTrial,
  } = props;
  const drawerRef = useRef(null);
  let drawerHeight = '-46.5rem';

  if (drawerRef.current && drawerRef.current.offsetHeight) {
    drawerHeight = `-${drawerRef.current && drawerRef.current.offsetHeight}px`;
  }

  useEffect(() => {
    if (showEdp) {
      focusManager.changeFocus('edp-section-button-info');
    }
  }, [showEdp]);

  let albumCoverImage = '';
  if (isVod && posterImageUrl) {
    albumCoverImage = `url("${posterImageUrl}")`;
  } else if (!isVod && albumImageUrl) {
    albumCoverImage = `url("${albumImageUrl}")`;
  } else if (channelBackgroundImage) {
    albumCoverImage = `url("${channelBackgroundImage}?&width=320&height=320&preserveAspect=true")`;
  }

  const channelLogoImage = channelLogoUrl
    ? `url("${channelLogoUrl}?&width=160&height=160&preserveAspect=true")`
    : '';

  const email = channel && channel.connectInfo && channel.connectInfo.email;
  const twitter = channel && channel.connectInfo && channel.connectInfo.twitter;
  const phone = channel && channel.connectInfo && channel.connectInfo.phone;
  const facebook =
    channel && channel.connectInfo && channel.connectInfo.facebook;

  const [isInfoTabFocused, setIsInfoTabFocused] = useState(false);
  useEffect(() => {
    if (isInfoTabFocused) {
      setHelpActionButtonFocused(EDP.SIRIUSXM_CHANNELS_NAV.id);
    }
  }, [isInfoTabFocused]);

  const [helpActionButtonFocused, setHelpActionButtonFocused] = useState(
    EDP.SIRIUSXM_CHANNELS_NAV.id as string,
  );
  const [
    remoteControllerImagesToShow,
    setRemoteControllerImagesToShow,
  ] = useState([]);
  useEffect(() => {
    if (helpActionButtonFocused === EDP.SIRIUSXM_CHANNELS_NAV.id) {
      setRemoteControllerImagesToShow([
        { image: remoteDown, text: 'Info & Settings' },
        { image: remoteUp, text: 'Related Content' },
        { image: remoteLeftRight, text: 'Change Track' },
      ]);
    } else if (helpActionButtonFocused === EDP.PANDORA_STATIONS_NAV.id) {
      setRemoteControllerImagesToShow([
        { image: remoteDown, text: 'Info & Settings' },
        { image: remoteUp, text: 'Related Content' },
        { image: thumbsDown, text: 'Hear less songs like this' },
        { image: thumbsUp, text: 'Hear more songs like this' },
      ]);
    } else if (helpActionButtonFocused === EDP.VIDEO_NAV.id) {
      setRemoteControllerImagesToShow([
        { image: remoteVideoDown, text: 'Info & Settings' },
        { image: remoteVideoUp, text: 'Related Content' },
        {
          image: remoteVideoLeftRight,
          text: 'Pause and swipe to navigate in videos',
        },
      ]);
    }
  }, [helpActionButtonFocused]);

  /****** PANDORA STATIONS LOGIC - START *********/

  const npArtistSelectorSegment = useSelector(selectNPArtistSelectorSegment);
  const isInterstitial = useSelector(getIsInterstitial);

  let isCreateSeededRadio = false;
  const showPandoraStationButton =
    npArtistSelectorSegment && !isInterstitial && !isOnDemand;
  if (showPandoraStationButton) {
    isCreateSeededRadio =
      npArtistSelectorSegment.action.actionType.indexOf('create') > -1;
  }

  const neriticLinkService = ServiceFactory.getInstance(
    NeriticLinkService,
  ) as NeriticLinkService;
  const takeArtistRadioButtonAction = segment => () => {
    const neriticLinkData = neriticLinkService.getNeriticData(
      segment.action.actionNeriticLink,
    );
    neriticLinkData.analyticsTag = segment.action.actionAnalyticsTag;
    const data = {
      description: artistName,
    };
    if (segment.action.actionType === ActionTypes.CREATE_SEEDED) {
      neriticLinkData.contentType = neriticActionConstants.CREATE_SEEDED_RADIO;
    }

    neriticLinkService.takeAction(neriticLinkData, data);
    setShowEdp(false);
    focusManager.changeFocus(nowPlayingNavId);
  };

  /****** PANDORA STATIONS LOGIC - END *********/

  /****** SKIPPING LOGIC - START *********/

  const mediaTimestampService = ServiceFactory.getInstance(
    MediaTimestampService,
  ) as MediaTimestampService;
  const liveTimeService = ServiceFactory.getInstance(
    LiveTimeService,
  ) as LiveTimeService;
  const seekService = ServiceFactory.getInstance(SeekService) as SeekService;

  const canShowGoToLive =
    isLive &&
    (dmcaService.isUnrestricted(nowPlayingStore) ||
      dmcaService.isRestricted(nowPlayingStore));
  const canRestart =
    isDisallowed || isSeededRadio || isAIC ? false : isLive || isOnDemand;

  const gotoLive = () => {
    liveTimeService.liveTime$.pipe(take(1)).subscribe(liveTime => {
      const nowInMs = moment().valueOf();
      const liveDeltaInSeconds = (nowInMs - liveTime.zuluMilliseconds) / 1000;

      if (isPlayheadBehindLive()) {
        //LARGE manifest file will have a duration always of 17993.142945 (for v4 variant streams)  when first loading. This can be considered the position of the livepoint;
        const livePositionInSeconds =
          mediaPlayerService?.mediaPlayer?.videoPlayerEventMonitor
            ?.currentLiveDuration || 17993.142945;
        const liveSyncPosition =
          mediaPlayerService?.mediaPlayer?.videoPlayer?.player?.hls
            ?.liveSyncPosition;

        //We grab the lowest duration as that should be the most accurate one and within the real bounds of the video's live edge. If we try to
        //seek past the live edge, that's when the video stalls.
        const liveEdge =
          livePositionInSeconds < liveSyncPosition
            ? livePositionInSeconds
            : liveSyncPosition;

        //If Tune Start made the playback begin way too far in the past, the stream's duration cannot be trusted. Therefore, from this
        //duration we must go back even further in time, three times, just to make sure that the player will not stall.
        const liveEdgeSafeTreshold = mediaPlayerService?.mediaPlayer
          ?.isTuneStartOutOfBounds
          ? 3
          : 1;

        seekService.seekThenPlay(
          liveEdge - liveDeltaInSeconds * liveEdgeSafeTreshold,
          false,
          true,
        );
        focusManager.changeFocus(nowPlayingNavId);
      }
    });
  };

  const liveTimeZulu = useObservable(liveTimeService.liveTime$) as any;
  const goBack1Hour = () => {
    const seconds = mediaTimestampService.playheadTimestamp - ONE_HOUR_S;
    seekService.seekThenPlay(seconds, false, true);
    focusManager.changeFocus(nowPlayingNavId);
  };

  const shouldDisableGoBack1hr = () => {
    let fiveHourBufferZulu = 0;
    if (liveTimeZulu) {
      fiveHourBufferZulu = liveTimeZulu.zuluMilliseconds - FIVE_HOUR_BUFFER_MS;
    } else {
      return true;
    }

    if (
      nowPlayingStore &&
      nowPlayingStore.playhead &&
      nowPlayingStore.playhead.currentTime
    ) {
      return (
        nowPlayingStore.playhead.currentTime.zuluMilliseconds -
          fiveHourBufferZulu <=
        ONE_HOUR_MS
      );
    } else {
      return true;
    }
  };

  const currentEpisodeZuluStartTime = useSelector(
    getCurrentEpisodeZuluStartTime,
  );
  const restartShow = () => {
    let time = 0;

    if (isLive) {
      time = getLiveShowRestartPosition(currentEpisodeZuluStartTime);
    }

    seekService.seekThenPlay(time, true, isLive);
    focusManager.changeFocus(nowPlayingNavId);
  };

  const skipToNextSong = nowPlayingStore => () => {
    onSkipForward(nowPlayingStore);
    focusManager.changeFocus(nowPlayingNavId);
  };

  /****** SKIPPING LOGIC - END *********/

  /****** FAVORITES LOGIC - START *********/

  const showAddShowTofavorites =
    channel &&
    episode &&
    show &&
    Object.keys(show).length > 0 &&
    !show.isPlaceholderShow;

  const showAddEpisodeTofavorites =
    channel &&
    isOnDemand &&
    episode &&
    show &&
    Object.keys(show).length > 0 &&
    !show.isPlaceholderShow;

  const showAddChannelTofavorites = !(
    showAddShowTofavorites && showAddEpisodeTofavorites
  );

  const favoriteService = ServiceFactory.getInstance(
    FavoriteService,
  ) as FavoriteService;

  const favoritesManager = useFavorite();
  const [isChannelFavorite, setIsChannelFavorite] = useState(false);
  const [isShowFavorite, setIsShowFavorite] = useState(false);
  const [isEpisodeFavorite, setIsEpisodeFavorite] = useState(false);

  useEffect(() => {
    if (channel && channel.channelId) {
      let isFavorite;

      if (isSeededRadio) {
        isFavorite = favoriteService.getFavorite(channel.channelId);
      } else {
        isFavorite = favoriteService.getFavoriteForChannel(channel.channelId);
      }

      setIsChannelFavorite(!!isFavorite);
    }
  }, [channel && channel.channelId]);

  useEffect(() => {
    if (show && show.assetGUID) {
      const isFavorite = favoriteService.getFavorite(show.assetGUID);
      setIsShowFavorite(!!isFavorite);
    }
  }, [show && show.assetGUID]);

  useEffect(() => {
    if (episode && show) {
      const episodeAssetGuid = favoritesManager.determineEpisodeGuid(
        episode,
        show,
        isAod,
      );
      const isFavorite = favoriteService.getFavorite(episodeAssetGuid);
      setIsEpisodeFavorite(!!isFavorite);
    }
  }, [episode, show]);

  useEffect(() => {
    return () => {
      //Prevents state update if now playing component is no longer present
      clearTimeout(favoriteDissmis.timeoutId);
    };
  }, []);

  const addChannelToFavorites = (
    channel,
    show,
    isFavorite,
    isSeededRadio,
    isAIC,
  ) => {
    return () => {
      setIsChannelFavorite(!isFavorite);
      favoritesManager.addChannelToFavorites(
        channel,
        show,
        isFavorite,
        isSeededRadio,
        isAIC,
      );
      favoriteDissmis.timeoutId = setTimeout(
        () => focusManager.changeFocus(nowPlayingNavId),
        favoriteDissmis.DELAY,
      );
    };
  };

  const addShowToFavorites = (show, isFavorite) => {
    return () => {
      setIsShowFavorite(!isFavorite);
      favoritesManager.addShowToFavorites(show, isFavorite);
      favoriteDissmis.timeoutId = setTimeout(
        () => focusManager.changeFocus(nowPlayingNavId),
        favoriteDissmis.DELAY,
      );
    };
  };

  const addEpisodeToFavorites = (episode, show, isAod, isFavorite) => {
    return () => {
      setIsEpisodeFavorite(!isFavorite);
      favoritesManager.addEpisodeToFavorites(episode, show, isAod, isFavorite);
      favoriteDissmis.timeoutId = setTimeout(
        () => focusManager.changeFocus(nowPlayingNavId),
        favoriteDissmis.DELAY,
      );
    };
  };

  /****** FAVORITES LOGIC - END *********/

  /****** AFFINITY LOGIC - START *********/

  const [songAffinity, setSongAffinity] = useState(AffinityConstants.NEUTRAL);
  const affinityService = ServiceFactory.getInstance(
    AffinityService,
  ) as AffinityService;
  const currentlyPlayingService = ServiceFactory.getInstance(
    CurrentlyPlayingService,
  ) as CurrentlyPlayingService;
  const currentlyPlayingMedia = useObservable(
    currentlyPlayingService.currentlyPlayingData,
  ) as ICurrentlyPlayingMedia;

  useEffect(() => {
    if (
      currentlyPlayingMedia &&
      currentlyPlayingMedia.cut &&
      currentlyPlayingMedia.cut.affinity
    ) {
      setSongAffinity(currentlyPlayingMedia.cut.affinity);
    }
  }, [currentlyPlayingMedia]);

  //To prevent a state update if the user dislikes and exits the screen before the timeout concludes
  const clearDislikeSongTimeout = () => clearTimeout(dislikedSongTimeout.id);
  useEffect(() => {
    return clearDislikeSongTimeout;
  }, []);

  const toggleLikeAffinity = songAffinity => () => {
    const newAffinity =
      songAffinity === AffinityConstants.LIKE
        ? AffinityConstants.NEUTRAL
        : AffinityConstants.LIKE;

    setSongAffinity(newAffinity);
    const elapsedTime: number = mediaTimestampService.playheadTimestamp;

    affinityService
      .updateAffinity(newAffinity as AffinityType, elapsedTime)
      .subscribe();
  };

  const toggleDislikeAffinity = (songAffinity, nowPlayingStore) => () => {
    const newAffinity =
      songAffinity === AffinityConstants.DISLIKE
        ? AffinityConstants.NEUTRAL
        : AffinityConstants.DISLIKE;

    setSongAffinity(newAffinity);
    const elapsedTime: number = mediaTimestampService.playheadTimestamp;

    affinityService
      .updateAffinity(newAffinity as AffinityType, elapsedTime)
      .subscribe(response => {
        let stationFactory =
          currentlyPlayingMedia.channel &&
          currentlyPlayingMedia.channel.stationFactory;

        stationFactory = stationFactory || currentlyPlayingMedia.mediaId;

        const item: IDmcaInfoItem = {
          mediaId: stationFactory,
        } as IDmcaInfoItem;
        if (!dmcaService.hasSkipsRemaining(item)) {
          return;
        }

        clearDislikeSongTimeout();
        dislikedSongTimeout.id = setTimeout(
          skipToNextSong(nowPlayingStore),
          dislikedSongTimeout.DELAY,
        );
      });
  };

  /****** AFFINITY LOGIC - END *********/

  return (
    <div
      className={`edp-information-drawer-container ${
        isOnFreeTrial ? 'edp-information-drawer-container-on-free-trial' : ''
      }`}
      ref={drawerRef}
      style={{ top: showEdp ? 0 : drawerHeight }}
    >
      <div
        className="edp-information-drawer-sections-container"
        style={isOnFreeTrial ? { borderBottom: 'none' } : null}
      >
        <EDPSectionButton
          nav={EDP.INFO_NAV}
          onFocus={() => setIsInfoTabFocused(true)}
        >
          Info
        </EDPSectionButton>
        <EDPSectionButton
          nav={EDP.HELP_NAV}
          onFocus={() => setIsInfoTabFocused(false)}
        >
          Help
        </EDPSectionButton>
      </div>
      {isOnFreeTrial && (
        <div className="edp-information-free-trial-counter-container">
          <FreeTrialCounterComponent />
        </div>
      )}
      <div className="edp-information-drawer-content-container">
        <section
          className={`edp-info-section ${isInfoTabFocused ? 'current' : ''}`}
          style={
            !showEdp ? { left: '5.625rem', transition: 'left 0s' } : undefined
          }
        >
          <div
            className="edp-information-album-cover"
            style={{
              backgroundImage: albumCoverImage,
              backgroundPosition: isAod || isVod ? 'right' : 'center',
            }}
          >
            {!albumImageUrl && (
              <div
                className="edp-information-channel-logo"
                style={{ backgroundImage: channelLogoImage }}
              />
            )}
          </div>
          <div className="edp-information-media-description">
            {!isSeededRadio && (
              <div className="edp-channel-info-container">
                <span className="edp-channel-number">
                  {((isAod || isVod) && channel && channel.name) || ''}
                  {!isAod &&
                    !isVod &&
                    !isIrisPodcast &&
                    `Ch ${(channel && channel.channelNumber) || ''}`}
                </span>
                {!isIrisPodcast && <span className="dot-icon" />}
                <span className="edp-channel-name">
                  {(isAod || isVod) && 'On Demand'}
                  {(!isAod && !isVod && channel && channel.name) || ''}
                </span>
              </div>
            )}
            {isSeededRadio && (
              <div className="edp-channel-info-container">
                <span className="edp-channel-name">Pandora Station</span>
              </div>
            )}
            <div className="edp-artist-and-track-container">
              <span className="now-playing-artist">
                {(isAod || isVod) && show && show.longTitle}
                {!isAod && !isVod && (artistName || '')}
              </span>
              {!isAod && !isVod && !isIrisPodcast && <span> — </span>}
              {!isAod && !isVod && !isIrisPodcast && (
                <span className="now-playing-track">{trackName || ''}</span>
              )}
            </div>
            <div className="edp-episode-container">
              <div className="episode-name">
                {(!isIrisPodcast && episode && episode.longTitle) || ''}
                {isIrisPodcast && (artistName || '')}
              </div>
              {!isSeededRadio && !isAIC && (
                <div className="episode-duration">
                  <span className="start-time">
                    {(isAod || isVod || isIrisPodcast) &&
                      episode &&
                      emissionDay}
                    {!isAod &&
                      !isVod &&
                      !isIrisPodcast &&
                      (episodeStartTime || '')}
                  </span>
                  {(isAod || isVod) && <span className="dot-icon" />}
                  {!isAod && !isVod && !isIrisPodcast && <span> — </span>}
                  <span className="end-time">
                    {(isAod || isVod || isIrisPodcast) && abbreviatedDuration}
                    {!isAod &&
                      !isVod &&
                      !isIrisPodcast &&
                      (episodeEndTime || '')}
                  </span>
                </div>
              )}
            </div>
            <div className="edp-channel-description">
              {isSeededRadio && nowPlayingStore && nowPlayingStore.channelName}
              {!isSeededRadio && (isAod || isVod)
                ? (episode && episode.longDescription) || ''
                : (channel && channel.mediumDescription) || ''}
            </div>
            <div className="edp-connect-info-container">
              <div className="connect-info-line-1">
                {email && <span className="email-icon"></span>}
                {email && <span className="email-connect-info">{email}</span>}
                {twitter && <span className="twitter-icon"></span>}
                {twitter && (
                  <span className="twitter-connect-info">{twitter}</span>
                )}
              </div>
              <div className="connect-info-line-2">
                {phone && <span className="phone-icon"></span>}
                {phone && <span className="phone-connect-info">{phone}</span>}
                {facebook && <span className="facebook-icon"></span>}
                {facebook && (
                  <span className="facebook-connect-info">{facebook}</span>
                )}
              </div>
            </div>
          </div>
          <div className="edp-information-actions-container">
            <>
              <EDPActionButton
                nav={{
                  ...EDP.PLAY_PANDORA_STATION_NAV,
                  skip: !(showPandoraStationButton && !isCreateSeededRadio),
                }}
                style={{
                  display:
                    showPandoraStationButton && !isCreateSeededRadio
                      ? 'block'
                      : 'none',
                }}
                onClick={takeArtistRadioButtonAction(npArtistSelectorSegment)}
              >
                Play Pandora Station
              </EDPActionButton>
              <EDPActionButton
                nav={{
                  ...EDP.CREATE_PANDORA_STATION_NAV,
                  skip: !(showPandoraStationButton && isCreateSeededRadio),
                }}
                style={{
                  display:
                    showPandoraStationButton && isCreateSeededRadio
                      ? 'block'
                      : 'none',
                }}
                onClick={takeArtistRadioButtonAction(npArtistSelectorSegment)}
              >
                Create Pandora Station
              </EDPActionButton>
              <EDPActionButton
                nav={{
                  ...EDP.ADD_CHANNEL_TO_FAVORITES_NAV,
                  skip: !showAddChannelTofavorites,
                }}
                style={{
                  display: showAddChannelTofavorites ? 'block' : 'none',
                }}
                onClick={addChannelToFavorites(
                  channel,
                  show,
                  isChannelFavorite,
                  isSeededRadio,
                  isAIC,
                )}
              >
                {isChannelFavorite ? 'Remove' : 'Add'} Channel{' '}
                {isChannelFavorite ? 'from' : 'to'} Favorites
              </EDPActionButton>
              <EDPActionButton
                nav={{
                  ...EDP.ADD_SHOW_TO_FAVORITES_NAV,
                  skip: !showAddShowTofavorites,
                }}
                style={{ display: showAddShowTofavorites ? 'block' : 'none' }}
                onClick={addShowToFavorites(show, isShowFavorite)}
              >
                {isShowFavorite ? 'Remove' : 'Add'} Show{' '}
                {isShowFavorite ? 'from' : 'to'} Favorites
              </EDPActionButton>
              <EDPActionButton
                nav={{
                  ...EDP.ADD_EPISODE_TO_FAVORITES_NAV,
                  skip: !showAddEpisodeTofavorites,
                }}
                style={{
                  display: showAddEpisodeTofavorites ? 'block' : 'none',
                }}
                onClick={addEpisodeToFavorites(
                  episode,
                  show,
                  isAod,
                  isEpisodeFavorite,
                )}
              >
                {isEpisodeFavorite ? 'Remove' : 'Add'} Episode{' '}
                {isEpisodeFavorite ? 'from' : 'to'} Favorites
              </EDPActionButton>
              <div className="action-buttons-divider" />

              <EDPActionButton
                nav={{
                  ...EDP.RESTART_SHOW_NAV,
                  skip: !canRestart || show.isPlaceholderShow,
                }}
                style={{
                  display:
                    !show.isPlaceholderShow && canRestart ? 'block' : 'none',
                }}
                onClick={restartShow}
              >
                Restart Show
              </EDPActionButton>
              <EDPActionButton
                nav={{
                  ...EDP.GO_BACK_1_HOUR_NAV,
                  skip: shouldDisableGoBack1hr() || !show.isPlaceholderShow,
                }}
                style={{
                  display:
                    show.isPlaceholderShow && !shouldDisableGoBack1hr()
                      ? 'block'
                      : 'none',
                }}
                onClick={goBack1Hour}
              >
                Back 1 Hour
              </EDPActionButton>
              <EDPActionButton
                nav={{
                  ...EDP.GO_LIVE_NAV,
                  skip: !canShowGoToLive && !isLiveAndDisallowed,
                }}
                style={{
                  display:
                    !canShowGoToLive && !isLiveAndDisallowed ? 'none' : 'block',
                }}
                onClick={gotoLive}
              >
                Go Live
              </EDPActionButton>
              <EDPActionButton
                nav={{
                  ...EDP.VIEW_ALL_SEGMENTS_NAV,
                  skip: isDisallowed || isRestricted || isLive || isIrisPodcast,
                }}
                style={{
                  display:
                    !isDisallowed && !isRestricted && !isLive && !isIrisPodcast
                      ? 'block'
                      : 'none',
                }}
                onClick={() => {
                  setShowSegmentViewer(true);
                  setShowEdp(false);
                }}
              >
                View All Segments
              </EDPActionButton>
              <div className="pandora-action-buttons">
                <EDPActionButton
                  nav={{ ...EDP.THUMBS_DOWN_NAV, skip: !isSeededRadio }}
                  onClick={toggleDislikeAffinity(songAffinity, nowPlayingStore)}
                  className={`${
                    songAffinity === AffinityConstants.DISLIKE
                      ? 'thumbs-down-affinity-selected'
                      : ''
                  } ${isSkipForwardDisabled ? 'double-width' : ''}`}
                  style={{ display: isSeededRadio ? 'block' : 'none' }}
                >
                  <ThumbsDownIcon />
                </EDPActionButton>
                <EDPActionButton
                  nav={{ ...EDP.THUMBS_UP_NAV, skip: !isSeededRadio }}
                  onClick={toggleLikeAffinity(songAffinity)}
                  className={`${
                    songAffinity === AffinityConstants.LIKE
                      ? 'thumbs-up-affinity-selected'
                      : ''
                  } ${isSkipForwardDisabled ? 'double-width' : ''}`}
                  style={{ display: isSeededRadio ? 'block' : 'none' }}
                >
                  <ThumbsUpIcon />
                </EDPActionButton>
                <EDPActionButton
                  nav={{
                    ...EDP.NEXT_TRACK_NAV,
                    skip: !isSeededRadio || isSkipForwardDisabled,
                  }}
                  style={{
                    display:
                      isSeededRadio && !isSkipForwardDisabled
                        ? 'block'
                        : 'none',
                  }}
                  onClick={skipToNextSong(nowPlayingStore)}
                >
                  <NextIcon />
                </EDPActionButton>
              </div>
            </>
          </div>
        </section>
        <section
          className={`edp-help-section ${!isInfoTabFocused ? 'current' : ''}`}
          style={
            !showEdp ? { left: '120rem', transition: 'left 0s' } : undefined
          }
        >
          <div className="edp-remote-controllers-container">
            {remoteControllerImagesToShow.map(({ image, text }, index) => {
              return (
                <div className="remote-controller-wrapper" key={index}>
                  <div
                    className="remote-controller-image"
                    style={{ backgroundImage: `url(${image})` }}
                  />
                  <div className="remote-controller-text">{text}</div>
                </div>
              );
            })}
          </div>
          <div className="edp-information-actions-container">
            <>
              <EDPActionButton
                nav={EDP.SIRIUSXM_CHANNELS_NAV}
                onFocus={() =>
                  setHelpActionButtonFocused(EDP.SIRIUSXM_CHANNELS_NAV.id)
                }
              >
                SiriusXM Channels
              </EDPActionButton>
              <EDPActionButton
                nav={EDP.PANDORA_STATIONS_NAV}
                onFocus={() =>
                  setHelpActionButtonFocused(EDP.PANDORA_STATIONS_NAV.id)
                }
              >
                Pandora Stations
              </EDPActionButton>
              <EDPActionButton
                nav={EDP.VIDEO_NAV}
                onFocus={() => setHelpActionButtonFocused(EDP.VIDEO_NAV.id)}
              >
                Video
              </EDPActionButton>
            </>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withFocus(EDPInformationDrawer);
