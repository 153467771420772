export const isBackgroundColorLight = (color: string) => {
  if (color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  }
  return false;
};

export const verticalSmoothScroll = (id: string) => {
  const carousel = document.getElementById(id);
  const carouselRect = carousel.getBoundingClientRect();
  const absoluteElementTop = carouselRect.top + window.pageYOffset;
  const middle = absoluteElementTop - window.innerHeight / 2.5;
  window.scrollTo({ left: 0, top: middle, behavior: 'smooth' });
};

export const verticalScroll = (id: string) => {
  const carousel = document.getElementById(id);
  const carouselRect = carousel.getBoundingClientRect();
  const absoluteElementTop = carouselRect.top + window.pageYOffset;
  const middle = absoluteElementTop - window.innerHeight / 2.5;
  window.scrollTo({ left: 0, top: middle });
};

export const getSuperCategoryAndCategoryFromPath = () => {
  const pathNameArray = location.pathname.split('/');
  const superCategory = pathNameArray?.[2];
  const category = pathNameArray?.[3];

  return `${superCategory}/${category}`;
};
