import { createSelector } from 'reselect';
import { IGroupedFavorites } from '../../servicelib';

export interface IFavoriteListStore {
  favorites: IGroupedFavorites;
}

export const selectFavoritesState = createSelector(
  state => (state as any).favoriteList,
  favoritesStore => favoritesStore,
);

export const getFavorites = createSelector(
  selectFavoritesState,
  (state: IFavoriteListStore): IGroupedFavorites => state.favorites,
);
