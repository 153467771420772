import React from 'react';
import PropTypes from 'prop-types';
import { FocusButton, LayoutX } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import styles from './focusKeyboardSmart.module.scss';
import { lowerCaseLayout } from './defaultFocusKeyboardSmartSettings';

class FocusKeyboardSmart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleVirtualKeyClick = this.handleVirtualKeyClick.bind(this);
    this.updatePrefix = this.updatePrefix.bind(this);
    this.updateLayoutMap = this.updateLayoutMap.bind(this);
    this.renderKey = this.renderKey.bind(this);
    this.getMatchedFeature = this.getMatchedFeature.bind(this);
  }

  componentDidMount() {
    if (!this.props.isDefault) {
      this.unlistenToFocusChanged = focusManager.listenToFocusChanged(data => {
        if (data.currentFocus === this.props.nav.id) {
          //Ensures the first key on the keyboard gets selected once the keyboard gets focused
          focusManager.changeFocus(''.concat(this.prefix, '0'));
        }
      });
    }
  }

  componentWillUnmount() {
    if (!this.props.isDefault) {
      this.unlistenToFocusChanged && this.unlistenToFocusChanged();
    }
  }

  getMatchedFeature(text) {
    const matchedFeature = Object.entries(this.props.layout.FEATURE_KEY).find(
      obj => {
        return text === obj[1].text;
      },
    );
    return matchedFeature;
  }

  updatePrefix() {
    let { prefix } = this;

    if (!prefix) {
      prefix = Date.now().toString(36);
    }

    this.prefix = prefix;
    return prefix;
  }

  updateLayoutMap() {
    let { layoutMap } = this;

    if (!layoutMap) {
      const prefix = this.updatePrefix(this);
      layoutMap = '';

      let spaceCount = 0;
      for (let i = 0, l = this.props.layout.textArray.length; i < l; i += 1) {
        const matchedFeature = this.getMatchedFeature(
          this.props.layout.textArray[i],
        );
        const elementColumns = matchedFeature ? matchedFeature[1].columns : 1;
        for (let j = 0; j < elementColumns; j += 1) {
          layoutMap += `${prefix + i}  `;
          spaceCount += 1;
        }

        if (spaceCount === this.props.col) {
          layoutMap += '\n';
          spaceCount = 0;
        }
      }
    }

    this.layoutMap = layoutMap;
    return layoutMap;
  }

  handleVirtualKeyClick(text) {
    const matchedFeature = this.getMatchedFeature(text);
    this.props.onVirtualKeyClick(text, matchedFeature);
  }

  renderKey(theme, text, index) {
    const matchedFeature = this.getMatchedFeature(text);
    const buttonContainerTheme =
      matchedFeature && matchedFeature[1].theme
        ? matchedFeature[1].theme
        : theme.buttonContainer;

    let toDisplay = null;
    if (matchedFeature && matchedFeature[1].component) {
      if (
        matchedFeature[0] === 'LAYOUT1' &&
        this.props.activeLayoutId === 'UPPERCASE'
      ) {
        toDisplay = matchedFeature[1].componentActivated;
      } else if (
        matchedFeature[0] === 'LAYOUT1' &&
        this.props.activeLayoutId === 'LAYOUT2'
      ) {
        toDisplay = matchedFeature[1].componentActivated;
      } else {
        toDisplay = matchedFeature[1].component;
      }
    } else {
      toDisplay = text;
    }

    return (
      <FocusButton
        key={text}
        nav={{
          id: ''.concat(this.prefix).concat(index),
          parent: this.props.nav.id,
        }}
        theme={{
          buttonContainer: buttonContainerTheme,
          button: theme.button,
          buttonFocused: theme.buttonFocused,
        }}
        onClick={() => {
          this.handleVirtualKeyClick(text);
        }}
      >
        {toDisplay}
      </FocusButton>
    );
  }

  render() {
    const layoutMap = this.updateLayoutMap(this.props.layout, this.props.col);

    const navOverride = {
      ...this.props.nav,
      forwardFocus: ''.concat(this.prefix, '0'),
    };

    const defaultButtonTheme = {
      buttonContainer: styles['button-container'],
      button: styles.button,
      buttonFocused: styles['button-focused'],
      buttonActive: styles['button-active'],
      layout: styles.layout,
    };

    const themeOverride = {
      ...defaultButtonTheme,
      ...this.props.theme,
    };

    const renderKeyBind = this.renderKey.bind(this, themeOverride);
    return (
      <LayoutX
        className={themeOverride.layout}
        nav={navOverride}
        layout={layoutMap}
      >
        {this.props.layout.textArray.map(renderKeyBind)}
      </LayoutX>
    );
  }
}

FocusKeyboardSmart.defaultProps = {
  layout: lowerCaseLayout,
  col: 6,
};

FocusKeyboardSmart.propTypes = {
  col: PropTypes.number,
  layout: PropTypes.shape({
    FEATURE_KEY: PropTypes.objectOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        component: PropTypes.component,
        theme: PropTypes.string,
        columns: PropTypes.number,
        func: PropTypes.func,
      }),
    ).isRequired,
    textArray: PropTypes.array.isRequired,
  }),
  isDefault: PropTypes.bool,
  activeLayoutId: PropTypes.string,
  nav: PropTypes.object,
  onVirtualKeyClick: PropTypes.func,
  theme: PropTypes.object,
};

export default FocusKeyboardSmart;
