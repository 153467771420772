import React from 'react';
import { ServiceFactory } from '../../servicelib';
import { useHistory } from 'react-router-dom';
import { Marquee } from '../marquee';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { CarouselTileService } from '../../sxmservicelayer/carousel-tile/carousel-tile.service';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import './CategoryTile.scss';

const CategoryTile = ({ isFocused, clicked, data, category }) => {
  const history = useHistory();
  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  const carouselTileService = ServiceFactory.getInstance(
    CarouselTileService,
  ) as CarouselTileService;

  useUpdateEffect(() => {
    if (data.categoryGuid) {
      carouselStoreService.selectSubCategory(data);
    }
    if (data.primaryNeriticLink) {
      carouselTileService.onClickNeriticLinkAction(data);
    } else {
      // Use push so that users can go back
      history.push(`/category-listing/${category}/${data.key}/channels`);
      window.scroll(0, 0);
    }
  }, [clicked]);

  return (
    data && (
      <div
        className={`category-card ${isFocused ? 'category-card-focused' : ''}`}
        style={{
          backgroundImage: `url(${data.bgImageUrl}?width=256&height=256&preserveAspect=true)`,
        }}
      >
        <img className="category-card-logo" src={data.fgImageUrl} alt="" />
        <div className="category-card-info">
          <Marquee
            text={data.line1 !== '' ? data.line1 : data.name}
            isFocused={isFocused}
            lightBackground={false}
          />
        </div>
      </div>
    )
  );
};

export default CategoryTile;
