const initialState = {
  startedAt: undefined,
  stoppedAt: undefined,
  baseTime: undefined,
};
export function inactivityTimerReducer(state = initialState, action) {
  switch (action.type) {
    case 'START_TIMER':
      return {
        ...state,
        baseTime: action.baseTime,
        startedAt: action.now,
      };
    case 'STOP_TIMER':
      return {
        ...state,
        stoppedAt: action.now,
      };
    default:
      return state;
  }
}
