import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  ServiceFactory,
  ChannelLineupService,
  CarouselService,
  ISuperCategory,
  ICarouselDataByType,
  Logger,
} from '../servicelib';
import {
  loadSuperCategories,
  selectSuperCategory,
} from '../redux/action/channel-list.action';
import {
  showPodcastsMenuOption,
  showVideosMenuOption,
} from '../redux/action/xdk.action';
import {
  getShowPodcastsMenuOption,
  getShowVideosMenuOption,
} from '../redux/selector/xdk.store';
import { selectSuperCategoryCarousel as selectSuperCategoryCarouselAction } from '../redux/action/carousel.action';
import {
  getSuperCategories,
  getSelectedSuperCategory,
} from '../redux/selector/channel-list.store';
import {
  zoneCategory,
  podcastsDiscoverZoneButton,
  videosDiscoverZoneButton,
} from '../components/navigation-menu/navigation-menu-utils';

const logger: Logger = Logger.getLogger('useNavigation');

//Needed to keep the latest value for pathname inside the observable's subscribe closure
const lastLocation = {
  pathname: '',
};
const lastShowPodcasts = {
  shouldShowPodcastsMenuOption: false,
};
const lastShowVideos = {
  shouldShowVideosMenuOption: false,
};

export const useNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const superCategories = useSelector(getSuperCategories);
  const selectedSuperCategory = useSelector(getSelectedSuperCategory);
  const shouldShowPodcastsMenuOption = useSelector(getShowPodcastsMenuOption);
  const shouldShowVideosmenuOption = useSelector(getShowVideosMenuOption);
  const channelLineupService = ServiceFactory.getInstance(
    ChannelLineupService,
  ) as ChannelLineupService;
  const carouselService = ServiceFactory.getInstance(
    CarouselService,
  ) as CarouselService;
  lastLocation.pathname = location.pathname;
  lastShowPodcasts.shouldShowPodcastsMenuOption = shouldShowPodcastsMenuOption;
  lastShowVideos.shouldShowVideosMenuOption = shouldShowVideosmenuOption;

  useEffect(() => {
    const subscription = channelLineupService.channelLineup.superCategories.subscribe(
      (data: Array<ISuperCategory>): void => {
        logger.debug(
          `onLineupLoaded( Channel lineup has ${data.length} supercategories )`,
        );

        if (data.length !== 0) {
          let matchedSuperCategory = null;
          let subscriptionPackageSupercategories = lastShowPodcasts.shouldShowPodcastsMenuOption
            ? ['all_podcasts']
            : [];
          subscriptionPackageSupercategories = lastShowVideos.shouldShowVideosMenuOption
            ? [...subscriptionPackageSupercategories, 'all_video']
            : subscriptionPackageSupercategories;

          data.forEach(superCategory => {
            if (lastLocation.pathname.includes(superCategory.key)) {
              matchedSuperCategory = superCategory;
            }

            //Gathers all supercategories available for this user
            subscriptionPackageSupercategories.push(superCategory.key);
          });

          //Filters supercategories that are not part of the current user's subscription

          zoneCategory.discoverZone.buttons = zoneCategory.discoverZone.buttons.filter(
            sc => {
              return subscriptionPackageSupercategories.some(
                superCategoryOnSubscription =>
                  superCategoryOnSubscription === sc.superCategory,
              );
            },
          );

          if (matchedSuperCategory) {
            dispatch(loadSuperCategories(data));
            dispatch(selectSuperCategory(matchedSuperCategory));
          }
        }
      },
    );

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (superCategories && superCategories.length) {
      const matchedSuperCategory = superCategories.find(superCategory => {
        return location.pathname.includes(superCategory.key);
      });

      if (matchedSuperCategory) {
        dispatch(selectSuperCategory(matchedSuperCategory));
      } else {
        //Need to swap the last selected supercategory when changing to Podcasts or Video, as they don't count as such
        //And the useEffect listening to this object will not update once we return the previously selected supercategory before
        //having selected Podcasts/Video
        dispatch(selectSuperCategory({} as any));
      }
    }
  }, [location.pathname]);

  const findPodcastsAndVideosSupercategory = (
    carousels: ICarouselDataByType,
  ) => {
    if (carousels.selectors) {
      const topSelector = carousels.selectors.find(selector => {
        return selector.class === 'top';
      });

      const moreSelector = carousels.selectors.find(selector => {
        return selector.class === 'moreSelector';
      });

      const selectors = [topSelector, moreSelector];
      let podcastsFounds = false;

      for (let i = 0; i < selectors.length; i++) {
        if (selectors[i] && selectors[i].segments) {
          const podcastsSegment = selectors[i].segments.find(segment => {
            return segment.title === 'PODCASTS';
          });

          if (!podcastsSegment) {
            podcastsFounds = false;
          } else {
            podcastsFounds = true;
            break;
          }
        }
      }

      let videosFound = false;
      for (let i = 0; i < selectors.length; i++) {
        if (selectors[i] && selectors[i].segments) {
          const videosSegment = selectors[i].segments.find(segment => {
            return segment.title === 'VIDEOS';
          });

          if (!videosSegment) {
            videosFound = false;
          } else {
            videosFound = true;
            break;
          }
        }
      }

      if (videosFound) {
        const videosIndex = zoneCategory.discoverZone.buttons.findIndex(
          button => button.superCategory === 'all_video',
        );

        if (videosIndex === -1) {
          zoneCategory.discoverZone.buttons.push(videosDiscoverZoneButton);
          dispatch(showVideosMenuOption(true));
        }
      }

      if (podcastsFounds) {
        const podcastsIndex = zoneCategory.discoverZone.buttons.findIndex(
          button => button.superCategory === 'all_podcasts',
        );

        if (podcastsIndex === -1) {
          zoneCategory.discoverZone.buttons.push(podcastsDiscoverZoneButton);
          dispatch(showPodcastsMenuOption(true));
        }
      }
    }
  };

  useEffect(() => {
    if (selectedSuperCategory.key) {
      logger.debug(`selectedSupercategory = ${selectedSuperCategory.key}`);

      const subscription = carouselService
        .getCarouselsBySuperCategory(selectedSuperCategory)
        .subscribe((carousels: ICarouselDataByType) => {
          findPodcastsAndVideosSupercategory(carousels);
          dispatch(selectSuperCategoryCarouselAction(carousels));
        });

      return () => subscription.unsubscribe();
    }
  }, [selectedSuperCategory]);
};
