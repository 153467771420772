import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastFocusedTileId } from '../../redux/selector/xdk.store';
import { selectSubCategoryCarousel } from '../../redux/selector/carousel.store';
import MenuButton from '../../components/navigation-menu/menu-button';
import { Page } from '@accedo/vdkweb-tv-ui';
import { useLocation, useHistory, useParams } from 'react-router';
import { appRouteConstants } from '../../routing/app.route.constants';
import { focusManager, navigationService } from '@accedo/vdkweb-navigation';
import { getPageBackId } from '../../redux/selector/xdk.store';
import './category.component.scss';
import { CATEGORY, CATEGORY_BUTTON_NAV } from '../../utils/navigationMap';
import {
  getSuperCategoryAndCategoryFromPath,
  verticalScroll,
} from '../../utils/pageUtils';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getHorizontalNav, getVerticalNav } from '../../utils/navigationHelper';
import FocusDiv from '../../components/focus-div/FocusDiv';
import Grid from '../../components/grid/Grid';
import ChannelTile from '../../components/tiles/ChannelTile';
import Swimlane from '../../components/swimlane/Swimlane';
import HeroTile from '../../components/tiles/HeroTile';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import SiriusXMLogo from '../../assets/images/sxm-logo-blue@1x.png';
import FreeTrialCounterComponent from '../../components/free-trial-counter/free-trial-counter.component';

const { PAGE, CONTAINER, GRID } = CATEGORY;

const pageNav = {
  PAGE: {
    id: PAGE,
    nextup: '',
  },
  CONTAINER: {
    id: CONTAINER,
    parent: PAGE,
    forwardFocus: '',
    nextup: '',
  },
  GRID: {
    id: GRID,
  },
};

const getContainerNav = (containersList: any[]) => {
  const containerNavIds = getVerticalNav(
    containersList.map(
      (container, index) => `${container?.carousel?.guid}-id${index}`,
    ),
    {
      parent: CONTAINER,
    },
  );

  pageNav.CONTAINER.forwardFocus = `${containersList?.[0]?.carousel?.guid}-id0`;

  return containerNavIds;
};

export const CategoryPage = props => {
  const { setIsCoreMenuOpen, isOnFreeTrial } = props;
  const location = useLocation();
  const subCategoryCarousels = useSelector(selectSubCategoryCarousel);

  const [navIds, setNavIds] = useState({});
  const [isChannelsActive, setIsChannelActive] = useState(true);
  const buttonNav = useRef({});
  const heroCarouselId = useRef(null);
  const channelsGridId = useRef(null);
  const showsId = useRef(null);
  const [animation, setAnimation] = useState(true);
  const dispatch = useDispatch();
  const forwardFocus = useRef(null);

  const { category: categoryName, type: categoryType } = useParams() as any;
  const isAllXtraChannelsView = useRef(null);
  useEffect(() => {
    if (categoryName === 'music' && categoryType === 'allxtra') {
      isAllXtraChannelsView.current = true;
    }
  }, [categoryName, categoryType]);

  useEffect(() => {
    setIsCoreMenuOpen(false);
    focusManager.changeFocus(CATEGORY_BUTTON_NAV[0]);

    return () => {
      navigationService.unlistenToFocusEvent({
        id: channelsGridId.current,
        fn: data => {
          if (data.currentFocus === channelsGridId.current) {
            (document.activeElement as any).blur();
          }
        },
      });
    };
  }, []);

  /** Handles the BACK button navigation logic **/
  const history = useHistory();
  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    history.goBack();
  }, [backId]);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[pageNav.PAGE.id]) {
      if (!lastFocusedTile[pageNav.PAGE.id].includes('GRID')) {
        focusManager.changeFocus(lastFocusedTile[pageNav.PAGE.id]);
      } else {
        forwardFocus.current = lastFocusedTile[pageNav.PAGE.id];
        focusManager.changeFocus(pageNav.GRID.id);
      }
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.PAGE.id]: null }),
      );
    }

    setTimeout(() => {
      forwardFocus.current = null;
      setAnimation(true);
    }, 500);
  }, [history.location]);

  useEffect(() => {
    if (location.pathname.includes('channels')) {
      setIsChannelActive(true);
    } else {
      setIsChannelActive(false);
    }
  }, [location.pathname]);

  const onPageFocus = useCallback(() => {
    if (heroCarouselId.current) {
      focusManager.changeFocus(heroCarouselId.current);
    } else {
      if (isChannelsActive) {
        if (channelsGridId.current) {
          focusManager.changeFocus(channelsGridId.current);
        }
      } else {
        if (showsId.current) {
          focusManager.changeFocus(pageNav.CONTAINER.id);
        }
      }
    }
  }, [
    isChannelsActive,
    heroCarouselId.current,
    channelsGridId.current,
    showsId.current,
  ]);

  useEffect(() => {
    const navs = {};
    let showCarouselNavs = null;
    const subCategoryData = subCategoryCarousels?.selectors?.[0]?.segments;

    if (!isAllXtraChannelsView.current && subCategoryCarousels) {
      const heroGuid = subCategoryCarousels?.zone?.[0]?.hero?.[0]?.guid;
      if (heroGuid) {
        navs[heroGuid] = {
          id: heroGuid,
          nextup: subCategoryData ? CATEGORY_BUTTON_NAV[0] : '',
        };
        pageNav.CONTAINER.nextup = heroGuid;
        heroCarouselId.current = heroGuid;
      }
    }

    if (subCategoryData) {
      if (subCategoryData?.[0]?.carousels?.[0]?.guid) {
        navs[pageNav.GRID.id] = {
          id: pageNav.GRID.id,
          nextup: heroCarouselId.current,
          parent: PAGE,
        };
        channelsGridId.current = pageNav.GRID.id;

        navigationService.listenToFocusEvent({
          id: pageNav.GRID.id,
          fn: data => {
            if (data.currentFocus === pageNav.GRID.id) {
              (document.activeElement as any).blur();
            }
          },
        });
      }

      if (subCategoryData?.[1]?.groupedCarousels) {
        showCarouselNavs = getContainerNav(
          subCategoryData?.[1].groupedCarousels.filter(
            el => el.carousel?.tiles?.length > 0,
          ),
        );

        if (Object.entries(showCarouselNavs).length > 0) {
          showsId.current = true;
        }

        if (!heroCarouselId.current) {
          pageNav.CONTAINER.nextup = CATEGORY_BUTTON_NAV[0];
        }
      }

      if (
        channelsGridId.current &&
        Object.entries(showCarouselNavs).length > 0
      ) {
        buttonNav.current = getHorizontalNav(CATEGORY_BUTTON_NAV, {
          nextup: '',
          nextdown: PAGE,
        });
        pageNav.PAGE.nextup = CATEGORY_BUTTON_NAV[0];
      } else if (channelsGridId.current && !isAllXtraChannelsView.current) {
        buttonNav.current = getHorizontalNav([CATEGORY_BUTTON_NAV[0]], {
          nextup: '',
          nextdown: PAGE,
        });
        pageNav.PAGE.nextup = CATEGORY_BUTTON_NAV[0];
      } else if (Object.entries(showCarouselNavs || {}).length > 0) {
        buttonNav.current = getHorizontalNav([CATEGORY_BUTTON_NAV[1]], {});
        pageNav.PAGE.nextup = CATEGORY_BUTTON_NAV[1];
        history.replace(
          `${
            appRouteConstants.CATEGORY
          }/${getSuperCategoryAndCategoryFromPath()}/shows`,
        );
      } else {
        //Some categories don't have channels or shows tab to show, only hero carousels. In this case, we must focus it, otherwise focus is lost
        setTimeout(() => {
          if (heroCarouselId.current) {
            focusManager.changeFocus(heroCarouselId.current);
          } else if (isAllXtraChannelsView.current) {
            focusManager.changeFocus(pageNav.GRID.id);
          }
        }, 100);
      }
    } else {
      setTimeout(() => {
        if (heroCarouselId.current) {
          focusManager.changeFocus(heroCarouselId.current);
        }
      }, 100);
    }

    setNavIds({ ...navs, ...showCarouselNavs });
  }, [subCategoryCarousels]);

  return (
    <Page
      className="category-page"
      nav={{
        ...pageNav.PAGE,
      }}
      onFocus={onPageFocus}
    >
      <div id={pageNav.PAGE.id}>
        {isAllXtraChannelsView.current && (
          <>
            <div className="xtra-channels-nav-bar-placeholder" />
            {isOnFreeTrial && (
              <div className="xtra-channels-nav-bar-placeholder" />
            )}
            <div className="xtra-channels-nav-bar">
              <span>Xtra Channels</span>
              <img src={SiriusXMLogo} />
            </div>
            <div className="xtra-channels-navbar-blue-divider-container">
              <div className="xtra-channels-navbar-blue-divider" />
              {isOnFreeTrial && <FreeTrialCounterComponent />}
            </div>
          </>
        )}
        {!isAllXtraChannelsView.current && subCategoryCarousels && (
          <>
            <div className="category-navbar-placeholder" />
            {isOnFreeTrial && <div className="category-navbar-placeholder" />}
            <div className="category-navbar-container">
              <div className="category-navbar">
                {subCategoryCarousels.pageLogo &&
                  subCategoryCarousels.pageTitle && (
                    <div className="category-menu">
                      <img
                        className="category-menu-logo"
                        src={`${subCategoryCarousels.pageLogo.imageLink}?width=74&height=74&preserveAspect=true`}
                        alt={subCategoryCarousels.pageLogo.imageAltText}
                      />
                      <div className="category-menu-title">
                        {subCategoryCarousels.pageTitle.textValue}
                      </div>
                    </div>
                  )}
                <div className="zone-menu-names">
                  {buttonNav.current[CATEGORY_BUTTON_NAV[0]] && (
                    <MenuButton
                      type={isChannelsActive ? 'zone' : 'zone-inactive'}
                      nav={buttonNav.current[CATEGORY_BUTTON_NAV[0]]}
                      onClick={() => {
                        history.replace(
                          `${
                            appRouteConstants.CATEGORY
                          }/${getSuperCategoryAndCategoryFromPath()}/channels`,
                        );
                      }}
                      className="zone-menu-button-container"
                      key={'Channels'}
                      onFocus={() => {
                        (document.activeElement as any).blur();
                      }}
                    >
                      <div
                        className={
                          isChannelsActive
                            ? 'zone-menu-name-focused'
                            : 'zone-menu-name'
                        }
                      >
                        Channels
                        {isChannelsActive && (
                          <span className="underline"></span>
                        )}
                      </div>
                    </MenuButton>
                  )}
                  {buttonNav.current[CATEGORY_BUTTON_NAV[1]] && (
                    <MenuButton
                      type={!isChannelsActive ? 'zone' : 'zone-inactive'}
                      nav={
                        buttonNav.current[CATEGORY_BUTTON_NAV[0]]
                          ? buttonNav.current[CATEGORY_BUTTON_NAV[1]]
                          : {
                              id: CATEGORY_BUTTON_NAV[0],
                              nextup: '',
                              nextdown: PAGE,
                            }
                      }
                      onClick={() => {
                        history.replace(
                          `${
                            appRouteConstants.CATEGORY
                          }/${getSuperCategoryAndCategoryFromPath()}/shows`,
                        );
                      }}
                      className="zone-menu-button-container"
                      key={'Shows'}
                      onFocus={() => {
                        (document.activeElement as any).blur();
                      }}
                    >
                      <div
                        className={
                          !isChannelsActive
                            ? 'zone-menu-name-focused'
                            : 'zone-menu-name'
                        }
                      >
                        Shows
                        {!isChannelsActive && (
                          <span className="underline"></span>
                        )}
                      </div>
                    </MenuButton>
                  )}
                </div>
              </div>
              {isOnFreeTrial && <FreeTrialCounterComponent />}
            </div>
          </>
        )}
        {subCategoryCarousels &&
          (subCategoryCarousels.zone.length ? subCategoryCarousels.zone : [{}]) //Some business channels don't have a hero carousel, but this shouldn't prevent the channels grid from loading
            .map(zone => {
              return (
                <Fragment key={zone.zoneId}>
                  {heroCarouselId.current &&
                    zone.hero &&
                    zone.hero.map(heroCarousel => {
                      return (
                        <Swimlane
                          pageId={pageNav.PAGE.id}
                          key={heroCarousel.guid}
                          nav={{
                            ...navIds[heroCarousel.guid],
                            nextdown: isChannelsActive
                              ? channelsGridId.current
                              : pageNav.CONTAINER.id,
                          }}
                          className={'home-hero-carousel-wrapper'}
                          headerClassName={'carousel-categories-header-top'}
                          itemWrapperClassName={'hero-wrapper'}
                          data={heroCarousel.tiles}
                          component={HeroTile}
                          displayText={zone.zoneTitle}
                          animation={animation}
                          onFocus={() => {
                            if (!animation) {
                              verticalScroll(heroCarousel.guid);
                            }
                          }}
                        />
                      );
                    })}
                  <FocusDiv
                    nav={pageNav.CONTAINER}
                    className={`${
                      heroCarouselId.current ? '' : 'carousel-header'
                    }`}
                  >
                    {!isChannelsActive &&
                      subCategoryCarousels?.selectors?.[0]?.segments?.[1]
                        ?.class === 'aod' &&
                      subCategoryCarousels?.selectors?.[0]?.segments?.[1]?.groupedCarousels.map(
                        (groupedCarousel, index) => {
                          const customID =
                            groupedCarousel.carousel.guid + '-id' + index;
                          return (
                            <Swimlane
                              pageId={pageNav.PAGE.id}
                              key={customID}
                              nav={navIds[customID]}
                              className={'home-hero-carousel-wrapper'}
                              headerClassName={'carousel-categories-header-top'}
                              itemWrapperClassName={'channel-wrapper'}
                              data={groupedCarousel.carousel.tiles}
                              component={ChannelTile}
                              displayText={groupedCarousel.groupName.textValue}
                              animation={animation}
                              onFocus={() => {
                                if (!animation) {
                                  verticalScroll(customID);
                                }
                              }}
                            />
                          );
                        },
                      )}
                  </FocusDiv>
                  {isChannelsActive &&
                    channelsGridId.current &&
                    subCategoryCarousels &&
                    subCategoryCarousels.selectors?.map(selector => {
                      return (
                        <>
                          {selector.segments &&
                            selector.segments.map(segment => {
                              return (
                                <>
                                  {segment.class === 'channels' &&
                                    segment.carousels &&
                                    segment.carousels.map(carousel => {
                                      return (
                                        <>
                                          <h2
                                            className={`${
                                              heroCarouselId.current
                                                ? 'content-grid-header'
                                                : 'content-grid-no-hero'
                                            }`}
                                          >
                                            {!isAllXtraChannelsView.current &&
                                              subCategoryCarousels.pageTitle
                                                ?.textValue}{' '}
                                            {!isAllXtraChannelsView.current &&
                                              'Channels'}
                                          </h2>
                                          <Grid
                                            pageId={pageNav.PAGE.id}
                                            nav={{
                                              ...navIds[pageNav.GRID.id],
                                            }}
                                            data={carousel.tiles}
                                            component={ChannelTile}
                                            className="content-grid grid-general-style"
                                            classNameItemWrapper={''}
                                            maxItemsRow={5}
                                            forwardFocus={forwardFocus.current}
                                            onFocus={id => {
                                              if (!animation) {
                                                verticalScroll(id);
                                              }
                                            }}
                                          />
                                        </>
                                      );
                                    })}
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                </Fragment>
              );
            })}
      </div>
    </Page>
  );
};
