import React, { useEffect, useState, useRef } from 'react';
import { combineLatest, timer, of } from 'rxjs';
import { useSelector, useDispatch } from 'react-redux';
import { switchMap, catchError } from 'rxjs/operators';
import { useHistory } from 'react-router-dom';
import xdk, { device } from '@accedo/xdk-core';
import { ReactComponent as SiriusXMLogo } from '../../assets/images/sxm-logo-blue.svg';
import { ReactComponent as SiriusXMLogoWaveless } from '../../assets/images/sxm-logo-blue-waveless.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import {
  ServiceFactory,
  InitializationService,
  InitializationStatusCodes,
  ResumeService,
  AppMonitorService,
  AuthenticationService,
  ConfigService,
  SessionMonitorService,
  Logger,
  StorageService,
  StorageKeyConstant,
  ApiDelegate,
  ApiCodes,
} from '../../servicelib';
import './splash-page.component.scss';
import { CarouselTileService } from '../../sxmservicelayer/carousel-tile/carousel-tile.service';
import { SXMServiceLayerModule } from '../../sxmservicelayer/sxm.service.layer.module';
import { BrowserUtil } from '../../utils/browser.util';
import { zoneCategory } from '../../components/navigation-menu/navigation-menu-utils';
import { NeriticLinkService } from '../../sxmservicelayer/neritic-links';
import { storageManager } from '../../config/storage-manager';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { terminateFreeTrial } from '../../utils/free-trial-expiration.util';
import {
  showPodcastsMenuOption,
  showVideosMenuOption,
} from '../../redux/action/xdk.action';
import loadAdobeAnalytics from '../../utils/loadAdobeAnalytics';
declare const $badger: any;
const payload = {
  moduleList: {
    modules: [
      {
        moduleType: 'PartnerDevices',
        moduleRequest: {
          deviceInfo: {
            osVersion: 'Linux',
            sxmAppVersion: process.env.REACT_APP_VERSION,
            oem: 'Comcast',
            clientDeviceId: '',
            deviceModel: 'Flex',
            platform: 'Home',
            mobileCarrier: '',
            deviceMake: 'comcasteverestv4',
          },
        },
        moduleArea: 'Routing',
      },
    ],
  },
};
const DEEPLINKING_PARAMETERS = {
  LAUNCHPOINT: 'launchpoint',
  QUERY: 'query',
  ASSET_ID: 'asstedId',
  ASSET_TYPE: 'assetType',
  ENTITY_ID: 'entityId',
  SECTION_NAME: 'sectionName',
  LIMITED_AD_TRACKING: 'lmt',
  US_PRIVACY: 'us_privacy',
};
const LAUNCHPOINT_VALUES = {
  HOME: 'home',
  DETAIL: 'detail',
  PLAYBACK: 'playback',
  SEARCH: 'search',
  SECTION: 'section',
};
const useSplashPageComponent = shouldDismissSplashScreen => {
  const history = useHistory();
  const [isStorageManagerLoaded, setIsStorageManagerLoaded] = useState(false);
  useEffect(() => {
    xdk.load().then(() => {
      device.storageManager.getStorage('cookie').then(storage => {
        storageManager.cloudCookieManager = storage;
        setIsStorageManagerLoaded(true);
      });
    });
  }, []);

  const [isAdobeLoaded, setIsAdobeLoaded] = useState(false);

  useEffect(() => {
    loadAdobeAnalytics(val => {
      setIsAdobeLoaded(val);
    });
  });

  //TODO: The default device id must be set as '' to ensure it is retrieved first once app can be tested on devices
  const [clientDeviceId, setClientDeviceId] = useState('');
  useEffect(() => {
    if (isStorageManagerLoaded && isAdobeLoaded) {
      if (!!navigator && !!navigator.userAgent) {
        const language = navigator.language || '';
        let [, appRegion] = language.split('-');
        appRegion = (appRegion || '').toUpperCase();
        storageManager.cloudCookieManager.set(
          StorageKeyConstant.APP_REGION,
          appRegion === 'CA' ? appRegion : 'US',
        );
        const agent = navigator.userAgent.toLowerCase();
        if (agent.indexOf('wpe') !== -1) {
          $badger
            .info()
            .success(data => {
              storageManager.cloudCookieManager.set(
                StorageKeyConstant.CLIENT_DEVICE_ID,
                data.deviceHash,
              );
              storageManager.cloudCookieManager.set(
                StorageKeyConstant.PARTNER_ID,
                data.partnerId,
              );
              setClientDeviceId(data.deviceHash);
            })
            .failure(data => {
              console.error(data);
              storageManager.cloudCookieManager.set(
                StorageKeyConstant.CLIENT_DEVICE_ID,
                'THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie',
              );
              setClientDeviceId(
                'THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie',
              );
            });
        } else {
          storageManager.cloudCookieManager.set(
            StorageKeyConstant.CLIENT_DEVICE_ID,
            'THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie',
          );
          setClientDeviceId('THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie');
        }
      } else {
        storageManager.cloudCookieManager.set(
          StorageKeyConstant.CLIENT_DEVICE_ID,
          'THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie',
        );
        setClientDeviceId('THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie');
      }
    }
  }, [isStorageManagerLoaded, isAdobeLoaded]);

  const [isHostRoutingComplete, setIsHostRoutingComplete] = useState(false);
  useEffect(() => {
    if (clientDeviceId) {
      payload.moduleList.modules[0].moduleRequest.deviceInfo.clientDeviceId = clientDeviceId;
      fetch(`${BrowserUtil.hostname}/rest/v2/experience/modules/get/partner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .catch(() => {
          return {
            json: () => {},
          };
        })
        .then(response => {
          return response.json();
        })
        .then(response => {
          let routingUrl;
          try {
            routingUrl =
              response['ModuleListResponse'].moduleList.modules[0]
                .moduleResponse.partnerDeviceList.partnerDevices[0].routingUrl;
          } catch (error) {
            routingUrl = BrowserUtil.getApiEndpoint();
          } finally {
            BrowserUtil.hostname = routingUrl;
            SXMServiceLayerModule.injectAppConfig();
            setIsHostRoutingComplete(true);
          }
        });
    }
  }, [clientDeviceId]);
  const [freeTrialDuratonText, setFreeTrialDurationText] = useState('');
  useEffect(() => {
    if (isHostRoutingComplete) {
      const appRegion = storageManager.cloudCookieManager.get(
        StorageKeyConstant.APP_REGION,
      );
      fetch(
        `${BrowserUtil.hostname}/rest/v5/experience/screenInfo?screenName=iap_screens&platform=web&language=en&app-region=${appRegion}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .catch(() => {
          return {
            json: () => {},
          };
        })
        .then(response => {
          return response.json();
        })
        .then(response => {
          let freeTrialDuration = 'Try FREE for 3 months';
          let platinumPlanFields = {
            planTitle: 'All Access (App Only)',
            planLine1:
              'Ad-free music for every genre & decade plus artist-created channels',
            planLine2:
              'SiriusXM video library of in-studio shows & performances',
            planLine3: '2 Howard Stern channels, including video',
            planAction: 'Get 3 months free',
            planPrice: 'then $9.99 a month after • cancel anytime.',
          };
          try {
            const iapWelcomeScreen = response[
              'screenInfoResponse'
            ].screenInfo[0].screens.find(screen => {
              return screen.id === 'iap_welcome_screen';
            });
            freeTrialDuration = iapWelcomeScreen.fields.find(
              field => field.name === 'button1',
            ).value;

            const iapWelcomePlanScreen = response[
              'screenInfoResponse'
            ].screenInfo[0].screens.find(screen => {
              return screen.id === 'iap_welcome_plan_screen';
            });
            const planTitle = iapWelcomePlanScreen.fields.find(
              field => field.name === 'plan-title',
            ).value;
            const planDescriptionFields = iapWelcomePlanScreen.fields.find(
              field => field.name === 'plan-description',
            ).fields;
            const planLine1 = planDescriptionFields.find(
              field => field.name === 'line1',
            ).value;
            const planLine2 =
              'SiriusXM video library of in-studio shows & performances';
            const planLine3 = planDescriptionFields.find(
              field => field.name === 'line2',
            ).value;
            const planAction = iapWelcomePlanScreen.fields.find(
              field => field.name === 'plan-action',
            ).value;
            const planPrice = iapWelcomePlanScreen.fields.find(
              field => field.name === 'plan-price',
            ).value;
            platinumPlanFields = {
              planTitle,
              planLine1,
              planLine2,
              planLine3,
              planAction,
              planPrice: `${planPrice.charAt(0).toLowerCase()}${planPrice.slice(
                1,
              )}`,
            };
          } finally {
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.FREE_TRIAL_DURATION_TEXT,
              freeTrialDuration,
            );
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.PLATINUM_PLAN_TEXT,
              platinumPlanFields,
            );
            setFreeTrialDurationText(freeTrialDuration);
          }
        });

      fetch(
        `${BrowserUtil.hostname}/rest/v5/experience/screenInfo?screenName=iap_rokutv_screens&platform=web&language=en&app-region=${appRegion}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .catch(() => {
          return {
            json: () => {},
          };
        })
        .then(r => r.json())
        .then(r => {
          let readyListen = "You're ready to listen to 400+ channels!";
          let readyListenTitle = 'Welcome to SiriusXM';
          try {
            readyListen = '';
            const iapConfirmation = r[
              'screenInfoResponse'
            ].screenInfo[0].screens.find(screen => {
              return screen.id === 'iap_confirmation';
            });
            readyListenTitle = iapConfirmation.fields.find(
              field => field.name === 'header',
            ).value;
            readyListen = iapConfirmation.fields.find(
              field => field.name === 'text1',
            ).value;
          } finally {
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.READY_TO_LISTEN,
              readyListen,
            );
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.READY_TO_LISTEN_TITLE,
              readyListenTitle,
            );
          }
        });
    }
  }, [isHostRoutingComplete]);
  //Imports the services that are used at application boot so their instances can be created
  //and the initialization process gets triggered.
  let initializationService;
  let resumeService;
  let appMonitorService;
  let authenticationService;
  let configService;
  let sessionMonitorService;
  let carouselTileService;
  if (freeTrialDuratonText) {
    Logger.isEnabled = true;
    Logger.level = Logger.LEVEL_INFO;
    initializationService = ServiceFactory.getInstance(
      InitializationService,
    ) as InitializationService;
    resumeService = ServiceFactory.getInstance(ResumeService) as ResumeService;
    appMonitorService = ServiceFactory.getInstance(
      AppMonitorService,
    ) as AppMonitorService;
    authenticationService = ServiceFactory.getInstance(
      AuthenticationService,
    ) as AuthenticationService;
    configService = ServiceFactory.getInstance(ConfigService) as ConfigService;
    sessionMonitorService = ServiceFactory.getInstance(
      SessionMonitorService,
    ) as SessionMonitorService;
    carouselTileService = ServiceFactory.getInstance(CarouselTileService);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      initializationService &&
      resumeService &&
      appMonitorService &&
      authenticationService &&
      configService &&
      sessionMonitorService &&
      carouselTileService
    ) {
      let sessionMessage: any = {};
      const sessionMessageSubscription = sessionMonitorService.sessionMessageSubject$.subscribe(
        value => {
          sessionMessage = value;
        },
      );
      const freeTrialStatus = { isExpired: undefined };
      const apiDelegate = ServiceFactory.getInstance(
        ApiDelegate,
      ) as ApiDelegate;
      const apiDelegateSubscription = apiDelegate.addApiCodeHandler(
        ApiCodes.FREE_TIER_EXPIRED,
        (codes, apiMessages, response) => {
          freeTrialStatus.isExpired = true;
        },
      );
      const subscription = combineLatest({
        isResumeComplete: resumeService.resumeIsComplete$,
        initState: initializationService.initState,
        delay: timer(1000),
      })
        .pipe(
          switchMap(({ isResumeComplete, initState }) => {
            if (
              isResumeComplete === true &&
              (initState === InitializationStatusCodes.UNAUTHENTICATED ||
                initState === InitializationStatusCodes.RUNNING)
            ) {
              return of(sessionMessage);
            } else if (
              isResumeComplete === false &&
              initState === InitializationStatusCodes.UNAUTHENTICATED
            ) {
              //Means resume still needs to be done for the first time to determine if OpenAccess is available or not.
              return resumeService.resume();
            }
          }),
          catchError((err, caught) => caught),
        )
        .subscribe((result: any) => {
          if (freeTrialStatus && freeTrialStatus.isExpired) {
            authenticationService.logout().subscribe({
              next: () => {
                dispatch(showPodcastsMenuOption(false));
                dispatch(showVideosMenuOption(false));
                terminateFreeTrial();
                history.replace(appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED);
              },
            });
          } else {
            const launchpoint = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.LAUNCHPOINT,
            );
            const entityId = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.ENTITY_ID,
            );
            const assetId = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.ASSET_ID,
            );
            const query = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.QUERY,
            );
            const sectionName = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.SECTION_NAME,
            );
            if (!launchpoint || launchpoint === LAUNCHPOINT_VALUES.HOME) {
              history.replace(appRouteConstants.FT_WELCOME);
            } else if (launchpoint === LAUNCHPOINT_VALUES.DETAIL && entityId) {
              //It is assumed that this will be an on-demand episode listing screen
              const subpath = decodeURIComponent(entityId);
              history.replace(
                `${appRouteConstants.ON_DEMAND.EPISODES_LIST}/${subpath}`,
              );
            } else if (launchpoint === LAUNCHPOINT_VALUES.PLAYBACK && assetId) {
              //It is assumed that assetId will include the encoded neritic link data
              const neriticLinkDataString = decodeURIComponent(assetId);
              let neriticLinkData: any = {};
              neriticLinkDataString.split('&').forEach(param => {
                const [key, value] = param.split('=');
                neriticLinkData = {
                  ...neriticLinkData,
                  [key]: value,
                };
              });
              history.replace(appRouteConstants.HOME_FORYOU);
              const neriticLinkService = ServiceFactory.getInstance(
                NeriticLinkService,
              ) as NeriticLinkService;
              const options: any = {
                onSuccess: () => {
                  const storageService = ServiceFactory.getInstance(
                    StorageService,
                  ) as StorageService;
                  storageService.setItem(
                    StorageKeyConstant.IS_DEEPLINKED,
                    true,
                  );
                },
              };
              neriticLinkService.tuneNeriticAction(
                neriticLinkData,
                null,
                options,
              );
            } else if (launchpoint === LAUNCHPOINT_VALUES.SEARCH && query) {
              history.replace(`${appRouteConstants.SEARCH}/${query}`);
            } else if (
              launchpoint === LAUNCHPOINT_VALUES.SECTION &&
              sectionName
            ) {
              const superCategory = zoneCategory.discoverZone.buttons.some(
                button => button.superCategory === sectionName,
              );
              if (superCategory) {
                if (
                  sectionName === 'all_podcasts' ||
                  sectionName == 'all_video'
                ) {
                  history.replace(
                    `${appRouteConstants.CATEGORY_PAGE}/${sectionName}`,
                  );
                } else {
                  history.replace(`${appRouteConstants.HOME}/${sectionName}`);
                }
              } else {
                history.replace(appRouteConstants.HOME_FORYOU);
              }
            } else {
              history.replace(appRouteConstants.FT_WELCOME);
            }
          }
          $badger.dismissLoadingScreen();
          shouldDismissSplashScreen(true);
        });
      return () => {
        apiDelegateSubscription.removeCodeHandler();
        subscription.unsubscribe();
        sessionMessageSubscription.unsubscribe();
      };
    }
  }, [
    initializationService,
    resumeService,
    appMonitorService,
    authenticationService,
    configService,
    carouselTileService,
  ]);
  /***** Handles the BACK button navigation logic to exit the app - START *****/
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);
  useEffect(() => {
    if (isMounted.current && isStorageManagerLoaded) {
      $badger.shutdown();
    } else {
      isMounted.current = true;
    }
  }, [backId]);
  /***** Handles the BACK button navigation logic to exit the app - END *****/
};
export const SplashPageComponent = props => {
  useSplashPageComponent(props.shouldDismissSplashScreen);
  return (
    <div className="splash-page">
      <div className="splash-logo-container">
        <div>
          <SiriusXMLogoWaveless />
          <SiriusXMLogo />
        </div>
      </div>
      <span className="gotham-black"> </span>
      <span className="gotham-medium"> </span>
      <span className="gotham-bold"> </span>
      <span className="gotham-book"> </span>
      <span className="gotham-narrow-ssm-book"> </span>
      <span className="gotham-narrow-ssm-medium"> </span>
      <span className="gotham-narrow-ssm-black"> </span>
    </div>
  );
};
