import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import './edp.component.scss';

const EDPSectionButton = props => {
  const { children, ...rest } = props;
  const theme = {
    button: 'edp-section-button',
    buttonFocused: 'edp-section-button-focused',
  };
  return (
    <FocusButton theme={theme} {...rest}>
      {children}
    </FocusButton>
  );
};

export default EDPSectionButton;
