import { detection as detect } from '@accedo/xdk-core';

const isRDK = () => {
  // Comcast WPE userAgent: Mozilla/5.0 (Linux; x86_64 GNU/Linux) AppleWebKit/601.1 (KHTML, like Gecko) Version/8.0 Safari/601.1 WPE

  var agent;
  if (!!navigator && !!navigator.userAgent) {
    agent = navigator.userAgent.toLowerCase();
    if (
      agent.indexOf('nativexrereceiver') !== -1 ||
      agent.indexOf('wpe') !== -1
    ) {
      return true;
    }
  }
  return false;
};

const detection = detect(isRDK);

export default detection;
