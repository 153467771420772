export const createRows = (initialArray: any[], chunkSize: number): any[] => {
  const finalArray: any[] = [];

  return finalArray.concat.apply(
    [],
    initialArray.map((_, idx) => {
      return idx % chunkSize ? [] : [initialArray.slice(idx, idx + chunkSize)];
    }),
  );
};

export const createItemId = (id: any): string => {
  return `GRID-ITEM-${id}`;
};

export const createRowId = (id: any): string => {
  return `ROW-${id}`;
};

export const createGridNavigation = (
  array: any,
  nav: XDKNav,
  restoreFocusTrail: any,
): any => {
  if (!array) {
    return;
  }

  const finalGridPositions = [];

  for (let i = 0; i < array.length; i += 1) {
    const row = [];

    for (let j = 0; j < array[i].length; j += 1) {
      const item: XDKNav = {
        id: createItemId(`${i}${j}`),
        nextup: i === 0 ? nav.nextup : '',
        parent: createRowId(i),
        internal: {
          nextup: i === 0 ? restoreFocusTrail : null,
          nextdown: i === array.length - 1 ? restoreFocusTrail : null,
        },
      };

      // check left
      if (j > 0) {
        item.nextleft = createItemId(`${i}${j - 1}`);
      }

      // check up
      if (i > 0) {
        item.nextup = createItemId(`${i - 1}${j}`);
      }

      // check right
      if (j < array[i].length - 1) {
        item.nextright = createItemId(`${i}${j + 1}`);
      }

      // check down
      if (i < array.length - 1) {
        if (j <= array[i + 1].length - 1) {
          item.nextdown = createItemId(`${i + 1}${j}`);
        }
      }

      row.push(item);
    }

    finalGridPositions.push(row);
  }

  return finalGridPositions;
};

export const createColumns = (
  initialArray: any[],
  chunkSize: number,
): any[] => {
  const finalArray: any[] = [];

  return finalArray.concat.apply(
    [],
    initialArray.map((_, idx) => {
      return idx % chunkSize ? [] : [initialArray.slice(idx, idx + chunkSize)];
    }),
  );
};

export const createColumnId = (id: any): string => {
  return `COLUMN-${id}`;
};

export const createVerticalGridNavigation = (
  array: any,
  nav: XDKNav,
  restoreFocusTrail: any,
): any => {
  if (!array) {
    return;
  }

  const finalGridPositions = [];

  for (let i = 0; i < array.length; i += 1) {
    const column = [];

    for (let j = 0; j < array[i].length; j += 1) {
      const item: XDKNav = {
        id: createItemId(`${i}${j}`),
        nextup: i === 0 ? nav.nextleft : '',
        parent: createColumnId(i),
        internal: {
          nextleft: i === 0 ? restoreFocusTrail : null,
          nextright: i === array.length - 1 ? restoreFocusTrail : null,
        },
      };

      // check left
      if (i > 0) {
        item.nextleft = createItemId(`${i - 1}${j}`);
      }

      // check right
      if (i < array.length - 1) {
        if (j <= array[i + 1].length - 1) {
          item.nextright = createItemId(`${i + 1}${j}`);
        }
      }

      // check up
      if (j > 0) {
        item.nextup = createItemId(`${i}${j - 1}`);
      }

      // check down
      if (j < array[i].length - 1) {
        item.nextdown = createItemId(`${i}${j + 1}`);
      }

      column.push(item);
    }

    finalGridPositions.push(column);
  }

  return finalGridPositions;
};
