import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import './rounded-button.component.scss';
import { ReactComponent as Spinner } from '../../assets/images/spinner.svg';

const RoundedButton = props => {
  const {
    children,
    type = 'primary',
    isActive = true,
    isLoading = false,
    isHeight = true,
    ...rest
  } = props;
  const theme = {
    button: `rounded-button rounded-button-${type} ${
      isHeight ? '' : 'no-height'
    }`,
    buttonFocused: 'rounded-button-focused',
    buttonActive: 'rounded-button-active',
  };
  return (
    <FocusButton theme={theme} isActive={isActive} {...rest}>
      {isLoading ? <Spinner /> : children}
    </FocusButton>
  );
};

export default RoundedButton;
