export interface IExternalAsset
{
    kind: 'external';
    name: string;
    url: string;
    onLoadCallback: Function;
}

export interface IInternalAsset
{
    kind: 'internal';
    name: string;
    dirPath: string;
    onLoadCallback: Function;
}
export type AssetKindType = "external" | "internal";
export type AssetNameType = "visitor api";
export type IAsset = IExternalAsset | IInternalAsset;

export const ASSET_KIND_CONST: { [key: string]: AssetKindType } = {
    EXTERNAL: "external",
    INTERNAL: "internal"
};

export const ASSET_NAME_CONST: { [key: string]: AssetNameType } = {
    VISITOR_API: 'visitor api'
};
