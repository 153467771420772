import React, { useState, useEffect } from 'react';
import usePrevious from '../../hooks/usePrevious';
import SwimlaneItemWrapper from './SwimlaneItemWrapper';

type Props<ItemDataType> = {
  items: SwimlaneItemWrapperProps<ItemDataType>[];
  hideOverflow?: boolean;
  swimlaneId: string;
  pageId: string;
  onSwimlaneReady?: (swimlaneId: string) => void;
};

const VisualWindow = <ItemDataType,>({
  items = [],
  swimlaneId,
  pageId,
  onSwimlaneReady,
}: Props<ItemDataType>) => {
  const [itemsReady, setItemsReady] = useState(false);
  const prevItemsReady = usePrevious(itemsReady);

  useEffect(() => {
    setItemsReady(true);

    return () => {
      setItemsReady(false);
    };
  }, [items]);

  useEffect(() => {
    if (itemsReady && !prevItemsReady) {
      onSwimlaneReady?.(swimlaneId);
    }
  }, [itemsReady]);

  return (
    <>
      {items.map(item => (
        <SwimlaneItemWrapper
          pageId={pageId}
          {...item}
        />
      ))}
    </>
  );
};

export default VisualWindow;
