import React from 'react';
import './sticky-navbar.component.scss';
import FreeTrialCounterComponent from '../free-trial-counter/free-trial-counter.component';

type Props = {
  children?: React.ReactNode | string;
  isCoreMenuOpen?: boolean;
  isOnFreeTrial?: boolean;
};

const StickyNavbar = ({ children, isCoreMenuOpen, isOnFreeTrial }: Props) => {
  return (
    <>
      <div className="sticky-navbar-wrapper">
        <div
          className={`sticky-navbar ${
            isCoreMenuOpen === false ? 'core-menu-closed' : ''
          }`}
        >
          <div className="sticky-navbar-children">{children}</div>
        </div>
        {isOnFreeTrial && (
          <div className="sticky-navbar-free-trial-wrapper">
            <FreeTrialCounterComponent />
          </div>
        )}
      </div>
      {isOnFreeTrial && <div className="sticky-navbar-placeholder" />}
      <div className="sticky-navbar-placeholder" />
    </>
  );
};

export default StickyNavbar;
