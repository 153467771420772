import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBackspace as fasBackspace } from '@fortawesome/free-solid-svg-icons/faBackspace';
import { faArrowAltCircleUp as fasArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleUp';

import { faArrowAltCircleUp as farArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleUp';

import styles from './focusKeyboardSmart.module.scss';

export const backspace = <FontAwesomeIcon icon={fasBackspace} />;

export const uppercase = <FontAwesomeIcon icon={farArrowAltCircleUp} />;

export const uppercaseActivated = (
  <FontAwesomeIcon icon={fasArrowAltCircleUp} />
);

export const layout1 = <div>AB12</div>;
export const layout1Activated = <div>ab12</div>;

export const layout2 = <div>{'!?#$'}</div>;

export const layout2Activated = <div>{'!?#$'}</div>;

const space = <div style={{ transform: 'rotate(-90deg)' }}>[</div>;

const CHAR_CODE_ZERO = 48;
const CHAR_CODE_ONE = 49;
const CHAR_CODE_A_LOWER = 97;
const CHAR_CODE_A_UPPER = 65;
const SEARCH_KEY = {
  BACKSPACE: {
    text: 'delete',
    value: '\b',
    component: backspace,
    columns: 2,
    theme: styles['button-double-size-container'],
    func: null,
  },
  SPACE: {
    text: 'space',
    value: ' ',
    component: space,
    columns: 1,
    theme: styles['button-container'],
    func: null,
  },
};

const FEATURE_KEY = {
  SPACE: {
    text: 'space',
    value: ' ',
    component: space,
    columns: 1,
    theme: styles['button-container'],
    func: null,
  },
  BACKSPACE: {
    text: 'delete',
    value: '\b',
    component: backspace,
    columns: 2,
    theme: styles['button-double-size-container'],
    func: null,
  },
  /*UPPERCASE: {
    text: 'uppercase',
    value: ' ',
    component: uppercase,
    componentActivated: uppercaseActivated,
    columns: 2,
    theme: styles['button-double-size-container'],
    func: null,
  },*/
  LAYOUT1: {
    text: 'layout1',
    value: ' ',
    component: layout1,
    componentActivated: layout1Activated,
    columns: 3,
    theme: styles['button-triple-size-container'],
    func: null,
  },
  LAYOUT2: {
    text: 'layout2',
    value: ' ',
    component: layout2,
    componentActivated: layout2Activated,
    columns: 3,
    theme: styles['button-triple-size-container'],
    func: null,
  },
  CLEAR: {
    text: 'Clear',
    value: ' ',
    theme: styles['button-double-size-container'],
    columns: 2,
    func: null,
  },
  CANCEL: {
    text: 'Cancel',
    value: ' ',
    theme: styles['button-triple-size-container'],
    columns: 3,
    func: null,
  },
  OK: {
    text: 'OK',
    value: ' ',
    theme: styles['button-double-size-container'],
    columns: 2,
    func: null,
  },
};

const EMAIL_FEATURE_KEY = {
  AT: {
    text: '@',
    value: ' ',
    theme: styles['button-container'],
    columns: 1,
    func: null,
  },
  DOLLAR: {
    text: '$',
    value: ' ',
    theme: styles['button-container'],
    columns: 1,
    func: null,
  },
  GMAIL: {
    text: '@gmail',
    alternateText: '@gmail.com',
    value: ' ',
    theme: styles['button-triple-size-container'],
    columns: 3,
    func: null,
  },
  YAHOO: {
    text: '@yahoo',
    alternateText: '@yahoo.com',
    value: ' ',
    theme: styles['button-triple-size-container'],
    columns: 3,
    func: null,
  },
  HOTMAIL: {
    text: '@hotmail',
    alternateText: '@hotmail.com',
    value: ' ',
    theme: styles['button-triple-size-container'],
    columns: 3,
    func: null,
  },
  COM: {
    text: '.com',
    value: ' ',
    theme: styles['button-double-size-container'],
    columns: 2,
    func: null,
  },
  NET: {
    text: '.net',
    value: ' ',
    theme: styles['button-double-size-container'],
    columns: 2,
    func: null,
  },
};

const lowercaseTextArray = Array.from(new Array(26), (x, index) => {
  return String.fromCharCode(CHAR_CODE_A_LOWER + index);
})
  .concat(
    Array.from(new Array(9), (x, index) => {
      return String.fromCharCode(CHAR_CODE_ONE + index);
    }),
  )
  .concat(
    Array.from(new Array(1), (x, index) => {
      return String.fromCharCode(CHAR_CODE_ZERO + index);
    }),
  );

const uppercaseTextArray = Array.from(new Array(26), (x, index) => {
  return String.fromCharCode(CHAR_CODE_A_UPPER + index);
})
  .concat(
    Array.from(new Array(9), (x, index) => {
      return String.fromCharCode(CHAR_CODE_ONE + index);
    }),
  )
  .concat(
    Array.from(new Array(1), (x, index) => {
      return String.fromCharCode(CHAR_CODE_ZERO + index);
    }),
  );

const symbolsTextArray = [
  '-',
  '/',
  ':',
  ';',
  '[',
  ']',
  '*',
  '%',
  '<',
  '>',
  '_',
  '|',
  '#',
  '+',
  '=',
  '?',
  '!',
  '.',
  "'",
  '{',
  '}',
  '&',
  '"',
  ',',
  '@',
  '^',
]
  .concat(
    Array.from(new Array(9), (x, index) => {
      return String.fromCharCode(CHAR_CODE_ONE + index);
    }),
  )
  .concat(
    Array.from(new Array(1), (x, index) => {
      return String.fromCharCode(CHAR_CODE_ZERO + index);
    }),
  );

export const lowerCaseLayout = {
  FEATURE_KEY,
  textArray: lowercaseTextArray.concat(
    Object.entries(FEATURE_KEY).map(obj => {
      return obj[1].text;
    }),
  ),
};

export const upperCaseLayout = {
  FEATURE_KEY,
  textArray: uppercaseTextArray.concat(
    Object.entries(FEATURE_KEY).map(obj => {
      return obj[1].text;
    }),
  ),
};

export const searchLayout = {
  FEATURE_KEY,
  textArray: uppercaseTextArray.concat(
    Object.entries(SEARCH_KEY).map(obj => {
      return obj[1].text;
    }),
  ),
};

export const symbolsLayout = {
  FEATURE_KEY,
  textArray: symbolsTextArray.concat(
    Object.entries(FEATURE_KEY).map(obj => {
      return obj[1].text;
    }),
  ),
};

const { SPACE, BACKSPACE, ...featureKeyRest } = FEATURE_KEY;
const { AT, DOLLAR, ...emailFeatureKeyRest } = EMAIL_FEATURE_KEY;
const FEATURE_KEY_EXPANDED = {
  AT,
  BACKSPACE,
  ...emailFeatureKeyRest,
  ...featureKeyRest,
};
const FEATURE_KEY_EXPANDED_NO_EMAIL = {
  AT,
  BACKSPACE,
  ...featureKeyRest,
};
const SYMBOLS_FEATURE_KEY_EXPANDED = {
  DOLLAR,
  BACKSPACE,
  ...emailFeatureKeyRest,
  ...featureKeyRest,
};
const SYMBOLS_FEATURE_KEY_EXPANDED_NO_EMAIL = {
  DOLLAR,
  BACKSPACE,
  ...featureKeyRest,
};

export const lowerCaseLayoutWithEmail = {
  FEATURE_KEY: { SPACE, ...FEATURE_KEY_EXPANDED },
  textArray: lowercaseTextArray.concat(
    Object.entries(FEATURE_KEY_EXPANDED).map(obj => {
      return obj[1].text;
    }),
  ),
};

export const lowerCaseLayoutWithNoEmail = {
  FEATURE_KEY: { SPACE, ...FEATURE_KEY_EXPANDED_NO_EMAIL },
  textArray: lowercaseTextArray.concat(
    Object.entries(FEATURE_KEY_EXPANDED_NO_EMAIL).map(obj => {
      return obj[1].text;
    }),
  ),
};

export const upperCaseLayoutWithEmail = {
  FEATURE_KEY: { SPACE, ...FEATURE_KEY_EXPANDED },
  textArray: uppercaseTextArray.concat(
    Object.entries(FEATURE_KEY_EXPANDED).map(obj => {
      return obj[1].text;
    }),
  ),
};

export const upperCaseLayoutWithNoEmail = {
  FEATURE_KEY: { SPACE, ...FEATURE_KEY_EXPANDED_NO_EMAIL },
  textArray: uppercaseTextArray.concat(
    Object.entries(FEATURE_KEY_EXPANDED_NO_EMAIL).map(obj => {
      return obj[1].text;
    }),
  ),
};

const symbolsTextArrayWithSpace = [...symbolsTextArray];
symbolsTextArrayWithSpace[symbolsTextArrayWithSpace.indexOf('@')] = 'space';

export const symbolsLayoutWithEmail = {
  FEATURE_KEY: {
    SPACE,
    ...SYMBOLS_FEATURE_KEY_EXPANDED,
  },
  textArray: symbolsTextArrayWithSpace.concat(
    Object.entries(SYMBOLS_FEATURE_KEY_EXPANDED).map(obj => {
      return obj[1].text;
    }),
  ),
};

export const symbolsLayoutWithNoEmail = {
  FEATURE_KEY: {
    SPACE,
    ...SYMBOLS_FEATURE_KEY_EXPANDED_NO_EMAIL,
  },
  textArray: symbolsTextArrayWithSpace.concat(
    Object.entries(SYMBOLS_FEATURE_KEY_EXPANDED_NO_EMAIL).map(obj => {
      return obj[1].text;
    }),
  ),
};
