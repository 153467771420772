import { createSelector } from 'reselect';
export const selectInactivityTimer = createSelector(
  state => (state as any).inactivityTimeStore,
  inactivityTimeStore => inactivityTimeStore,
);
export const getStartedTime = createSelector(
  selectInactivityTimer,
  state => state.startedAt,
);
export const getStopedTime = createSelector(
  selectInactivityTimer,
  state => state.stoppedAt,
);
