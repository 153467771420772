import React, { useEffect, useState, useRef, Fragment } from 'react';
import { distinctUntilChanged } from 'rxjs/operators';
import SiriusXMLogo from '../../assets/images/sxm-logo-blue@2x.png';
import { ReactComponent as SettingsIcon } from '../../assets/images/settings-on-blue.svg';
import Button from '../../components/button/Button';
import { RoundedButton } from '../../components/rounded-button';
import { StickyNavbar } from '../../components/sticky-navbar';
import { Page } from '@accedo/vdkweb-tv-ui';
import { SETTINGS, MENU } from '../../utils/navigationMap';
import { focusManager } from '@accedo/vdkweb-navigation';
import { INavMap, getVerticalNav } from '../../utils/navigationHelper';
import {
  settingsItems,
  settingsToggleItems,
  legalButtonExtra,
  popupCloseButtonNav,
  popupSignOutButtonNav,
  SettingsConfig,
} from './settings-page-utils';
import { getServiceConfig } from '../../sxmservicelayer/service.config.utils';
import { useObservable } from '../../hooks';
import { useHistory } from 'react-router';
import { appRouteConstants } from '../../routing/app.route.constants';
import {
  ServiceFactory,
  SettingsService,
  AuthenticationService,
  StorageService,
  ProfileService,
  StorageKeyConstant,
  SettingsConstants,
  BypassMonitorService,
  MediaPlayerService,
  TuneService,
  CarouselService,
} from '../../servicelib';
import './settings-page.component.scss';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { useSelector, useDispatch } from 'react-redux';
import { getPageBackId } from '../../redux/selector/xdk.store';
import {
  showPodcastsMenuOption,
  showVideosMenuOption,
} from '../../redux/action/xdk.action';
import {
  zoneCategory,
  originalDiscoverZoneButtons,
} from '../../components/navigation-menu/navigation-menu-utils';

const { PAGE } = SETTINGS;
const { CORE_MENU } = MENU;

const pageNav = {
  id: PAGE,
  nextup: CORE_MENU,
  internal: {
    nextup: () => {},
  },
};

const getContainerNav = (containersList: any[]) => {
  const containerNavIds = getVerticalNav(
    containersList.map(container => `id-${container.id}`),
    {},
  );

  return containerNavIds;
};

export const SettingsPageComponent = ({
  isCoreMenuOpen,
  setIsCoreMenuOpen,
  isOnFreeTrial,
  setAutoplayOnStartup,
  AUTOPLAY_STATES,
}) => {
  const username = useRef('');
  const [showPopup, setShowPopup] = useState(false);
  const [settingsToggleValue, setSettingsToggleValue] = useState({});
  const [settingsConfig, setSettingsConfig] = useState({} as SettingsConfig);
  const [isLogoutInProgress, setIsLogoutInProgress] = useState(false);

  const containerNavIds: INavMap<string, any> = getContainerNav(settingsItems);

  const history = useHistory();

  const settingsService = ServiceFactory.getInstance(
    SettingsService,
  ) as SettingsService;
  const authenticationService = ServiceFactory.getInstance(
    AuthenticationService,
  ) as AuthenticationService;
  const storageService = ServiceFactory.getInstance(
    StorageService,
  ) as StorageService;
  const profileService = ServiceFactory.getInstance(
    ProfileService,
  ) as ProfileService;
  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;
  const tuneService = ServiceFactory.getInstance(TuneService) as TuneService;
  const carouselService = ServiceFactory.getInstance(
    CarouselService,
  ) as CarouselService;
  const globalAndDeviceSettings = useObservable(
    settingsService.settings,
  ) as any;

  const userInfoResult = useObservable(profileService.userInfo) as any;

  useEffect(() => {
    focusManager.changeFocus(PAGE);
    settingsService.retrieveSettings().subscribe();
    if (userInfoResult?.profileName) {
      username.current = userInfoResult?.profileName;
    } else {
      username.current = storageService.getItem(
        StorageKeyConstant.USERNAME,
        'UNAUTH_USER_ID',
      );
    }
    const appConfig = getServiceConfig();
    const newSettingsConfig = {
      ...settingsConfig,
      autoplayOnStartup: storageService.getItem(
        StorageKeyConstant.AUTOPLAY_ON_STARTUP,
      ),
      appVersion: appConfig?.deviceInfo?.sxmAppVersion,
      appBuild: appConfig?.deviceInfo?.sxmTeamCityBuildNumber,
      showVersion: true,
    };
    setSettingsConfig(newSettingsConfig);
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  const dispatch = useDispatch();
  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    if (showPopup) {
      setShowPopup(false);
      focusManager.changeFocus('id-' + settingsConfig.trackFocus);
    } else if (!isCoreMenuOpen) {
      setIsCoreMenuOpen(true);
    } else {
      focusManager.changeFocus(CORE_MENU);
    }
  }, [backId]);

  useUpdateEffect(() => {
    if (isCoreMenuOpen) {
      focusManager.changeFocus(CORE_MENU);
    }
  }, [isCoreMenuOpen]);

  useEffect(() => {
    if (globalAndDeviceSettings) {
      const settings = {};
      globalAndDeviceSettings.globalSettings.forEach(settingField => {
        if (settingsToggleItems.includes(settingField.name)) {
          settings[settingField.name] = settingField.value;
        }
      });
      setSettingsToggleValue(settings);
    }
  }, [globalAndDeviceSettings]);

  const internalNextUp = {
    nextup: () => {
      setIsCoreMenuOpen(true);
    },
  };

  const leftoverSettingsItems = settingsItems.slice(
    1,
    settingsItems.length - 1,
  );

  const capitalize = (input: string) => {
    if (input) {
      return input.charAt(0).toUpperCase() + input.slice(1);
    }
    return '';
  };

  const toggleSetting = (settingName: string) => {
    const settingCurrentStatus = globalAndDeviceSettings.globalSettings.find(
      (setting: any) => setting.name === settingName,
    );
    if (settingCurrentStatus) {
      const updatedStatus =
        settingCurrentStatus.value === SettingsConstants.ON
          ? SettingsConstants.OFF
          : SettingsConstants.ON;
      settingsService
        .setGlobalSettingValue(updatedStatus, settingName)
        .subscribe();
    }
  };

  //Toggles the flags if there's a bypass mode active so that the corresponding menu items get disabled.
  const [skippableMenuItems, setSkippableMenuItems] = useState({
    AutoPlay: false,
    TuneStart: false,
  });
  useEffect(() => {
    const bypassMonitorService = ServiceFactory.getInstance(
      BypassMonitorService,
    ) as BypassMonitorService;

    const subscription = bypassMonitorService.bypassErrorState
      .pipe(
        distinctUntilChanged((prev, current) => {
          return (
            prev.GUP_BYPASS === current.GUP_BYPASS &&
            prev.GUP_BYPASS2 === current.GUP_BYPASS2
          );
        }),
      )
      .subscribe(bypassErrorState => {
        skippableMenuItems['AutoPlay'] =
          bypassErrorState.GUP_BYPASS || bypassErrorState.GUP_BYPASS2;
        skippableMenuItems['TuneStart'] =
          bypassErrorState.GUP_BYPASS || bypassErrorState.GUP_BYPASS2;
        setSkippableMenuItems(skippableMenuItems);
      });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <Page
      className="settings-page"
      nav={{
        ...pageNav,
        forwardFocus: containerNavIds[`id-${settingsItems[0].id}`].id,
      }}
    >
      <StickyNavbar
        isCoreMenuOpen={isCoreMenuOpen}
        isOnFreeTrial={isOnFreeTrial as boolean}
      >
        <div className="children-wrapper">
          <SettingsIcon className="children-icon" />
          <div className="children-title">Settings</div>
        </div>
      </StickyNavbar>
      <div className="settings-page-container">
        <div className="settings-page-left-side">
          <div className="settings-page-buttons-container">
            <Button
              nav={{
                ...containerNavIds[`id-${settingsItems[0].id}`],
                nextup: CORE_MENU,
                internal: internalNextUp,
              }}
              className={settingsItems[0].className}
              classNameFocused={settingsItems[0].classNameFocused}
              onFocus={() => {
                setSettingsConfig({ ...settingsConfig, showVersion: true });
              }}
            >
              {userInfoResult?.avatar ? (
                <img
                  src={userInfoResult.avatar}
                  alt="User Avatar"
                  className="profile-button-avatar"
                />
              ) : (
                <div className="profile-button-img-background">
                  <div className="profile-button-img-letter">
                    {username.current?.charAt(0)?.toUpperCase()}
                  </div>
                </div>
              )}
              <div className="profile-button-details">
                <div className="profile-button-username">
                  {username.current}
                </div>
                <div className="profile-button-current">Current Profile</div>
              </div>
            </Button>
            {leftoverSettingsItems?.length > 0 &&
              leftoverSettingsItems.map((container: any, id: number) => {
                return (
                  container && (
                    <Button
                      key={id}
                      nav={{
                        ...containerNavIds[`id-${container.id}`],
                        skip: skippableMenuItems[container.settingsName],
                      }}
                      className={`${container.className} ${
                        skippableMenuItems[container.settingsName]
                          ? 'disabled'
                          : ''
                      }`}
                      classNameFocused={container.classNameFocused}
                      onFocus={() => {
                        setIsCoreMenuOpen(false);
                        const newSettingsConfig = {
                          ...settingsConfig,
                          rightSideTitle: container.content,
                          rightSideDetail: container.detail,
                          trackFocus: container.id,
                          showVersion: false,
                        };
                        setSettingsConfig(newSettingsConfig);
                      }}
                      onClick={() => {
                        if (container.useStorageService) {
                          const autoplayOnStartupStatus =
                            storageService.getItem(
                              StorageKeyConstant.AUTOPLAY_ON_STARTUP,
                            ) === SettingsConstants.ON;
                          const updatedStatus = autoplayOnStartupStatus
                            ? SettingsConstants.OFF
                            : SettingsConstants.ON;
                          const newSettingsConfig = {
                            ...settingsConfig,
                            autoplayOnStartup: updatedStatus,
                          };
                          setSettingsConfig(newSettingsConfig);
                          storageService.setItem(
                            StorageKeyConstant.AUTOPLAY_ON_STARTUP,
                            updatedStatus,
                          );
                        } else {
                          toggleSetting(container.settingsName);
                        }
                      }}
                    >
                      <div className="middle-section-button-title">
                        {container.content}
                      </div>
                      {container.toggle && (
                        <div className="middle-section-button-toggle">
                          {container.useStorageService
                            ? capitalize(settingsConfig.autoplayOnStartup)
                            : capitalize(
                                settingsToggleValue[container.settingsName],
                              )}
                        </div>
                      )}
                    </Button>
                  )
                );
              })}
            <Button
              nav={
                containerNavIds[
                  `id-${settingsItems[settingsItems.length - 1].id}`
                ]
              }
              className={settingsItems[settingsItems.length - 1].className}
              classNameFocused={
                settingsItems[settingsItems.length - 1].classNameFocused
              }
              onFocus={() => {
                const newSettingsConfig = {
                  ...settingsConfig,
                  trackFocus: settingsItems[settingsItems.length - 1].id,
                  showVersion: true,
                };
                setSettingsConfig(newSettingsConfig);
              }}
              onClick={() => {
                setShowPopup(true);
                focusManager.changeFocus(popupCloseButtonNav.id);
              }}
            >
              <div>{settingsItems[settingsItems.length - 1].content}</div>
            </Button>
          </div>
        </div>
        <div className="settings-page-right-side">
          {settingsConfig.showVersion ? (
            <div className="settings-page-logo-version-build">
              <div
                className="settings-sirius-xm-logo"
                style={{ backgroundImage: `url(${SiriusXMLogo})` }}
              ></div>
              <div className="settings-page-version">
                Version {process.env.REACT_APP_VERSION}
              </div>
              <div className="settings-page-build">
                build {settingsConfig.appBuild}
              </div>
            </div>
          ) : (
            <div className="settings-page-description">
              <div className="settings-page-description-title">
                {settingsConfig.rightSideTitle}
              </div>
              <div
                className={`${
                  settingsConfig.rightSideTitle === 'Legal'
                    ? 'settings-page-description-detail-left'
                    : 'settings-page-description-detail'
                }`}
              >
                {settingsConfig.rightSideDetail}
              </div>
              {settingsConfig.rightSideTitle === 'Legal' &&
                legalButtonExtra.map(extra => {
                  return (
                    <Fragment key={extra.title}>
                      <div className="legal-title">{extra.title}</div>
                      <div className="legal-website">{extra.website}</div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
        {showPopup && (
          <div className="settings-page-popup">
            <div className="settings-page-popup-text">{`Logged in as ${username.current}`}</div>
            <div className="settings-page-popup-buttons">
              <RoundedButton
                type="settings"
                className="settings-page-popup-close-button"
                onClick={() => {
                  setShowPopup(false);
                  focusManager.changeFocus('id-' + settingsConfig.trackFocus);
                }}
                nav={popupCloseButtonNav}
              >
                Cancel
              </RoundedButton>
              <RoundedButton
                type="settings"
                className="settings-page-popup-sign-out-button"
                isLoading={isLogoutInProgress}
                onClick={() => {
                  setIsLogoutInProgress(true);
                  authenticationService.logout().subscribe({
                    next: () => {
                      if (mediaPlayerService.mediaPlayer) {
                        mediaPlayerService.mediaPlayer.stop();
                        mediaPlayerService.mediaPlayer.mediaId = null;
                        mediaPlayerService.mediaPlayer.mediaType = null;
                      }

                      tuneService.clearTuneMediaTimeLineData();
                      storageService.clearAll();
                      carouselService.clearAllCarouselCache();
                      setIsCoreMenuOpen(true);
                      zoneCategory.discoverZone.buttons = originalDiscoverZoneButtons;
                      dispatch(showPodcastsMenuOption(false));
                      dispatch(showVideosMenuOption(false));
                      tuneService.stopPeriodicLiveUpdateRequests();
                      setAutoplayOnStartup(AUTOPLAY_STATES.INITIALIZATION);
                      history.push(appRouteConstants.FT_WELCOME);
                    },
                    error: error => {
                      setIsLogoutInProgress(false);
                      console.error(error);
                    },
                  });
                }}
                nav={popupSignOutButtonNav}
              >
                Sign Out
              </RoundedButton>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};
