import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Page } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import BlueButton from '../../components/blue-button/blue-button-component';
import { appRouteConstants } from '../../routing/app.route.constants';
import './welcome-page.component.scss';
import { StorageKeyConstant } from '../../servicelib';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { storageManager } from '../../config/storage-manager';
import QrCodeCox from '../../assets/images/qr-code-cox.svg';
import QrCodeInfinity from '../../assets/images/qr-code-infinity.svg';
import QrCodeRogers from '../../assets/images/qr-code-rogers.svg';
import QrCodeXclass from '../../assets/images/qr-code-xclass.svg';
import SiriusXMLogo from '../../assets/images/sxm-logo-blue@2x.png';
import TryFreeModalComponent from './try-free-modal.component';
import BlueCheck from '../../assets/images/blue-check.svg';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
// import WelcomeBackgroundVideo from '../../assets/images/welcome-background.mp4';

declare const $badger: any;
const pageNav = { id: 'welcome-page' };
const freeTrialButtonNav = {
  id: 'free-trial-button',
  nextleft: 'sign-in-button',
  nextdown: 'privacy-policy-button',
};
const signInButtonNav = {
  id: 'sign-in-button',
  nextright: 'free-trial-button',
};
const privacyPolicyButtonNav = {
  id: 'privacy-policy-button',
  nextup: 'free-trial-button',
};
const privacyPolicyButtonTheme = {
  button: 'privacy-policy-button',
  buttonFocused: 'privacy-policy-button-focused',
  buttonActive: 'privacy-policy-button-active',
};

export const WelcomePageComponent = () => {
  const history = useHistory();
  const [hasTryFree, setHasTryFree] = useState(false);
  const hasTryFreeContext = useRef(freeTrialButtonNav.id);
  const [subscriptionUrl, setSubscriptionUrl] = useState({
    link: 'siriusxm.com/xclass',
    image: QrCodeXclass,
  });
  const [hasVideoEnded, setHasVideoEnded] = useState(false);
  const backgroundVideoRef = useRef(null);

  useEffect(() => {
    const partnerId = storageManager.cloudCookieManager.get(
      StorageKeyConstant.PARTNER_ID,
    );
    const operatorsSignUpUrlMap = {
      comcast: {
        link: 'siriusxm.com/xfinity',
        image: QrCodeInfinity,
      },
      xfinity: {
        link: 'siriusxm.com/xfinity',
        image: QrCodeInfinity,
      },
      cox: {
        link: 'siriusxm.com/cox',
        image: QrCodeCox,
      },
      rogers: {
        link: 'siriusxm.com/rogers',
        image: QrCodeRogers,
      },
    };
    const defaultSingUpUrl = {
      link: 'siriusxm.com/xclass',
      image: QrCodeXclass,
    };
    setSubscriptionUrl(operatorsSignUpUrlMap[partnerId] || defaultSingUpUrl);
  }, []);

  const handleSignIn = () => {
    history.push(appRouteConstants.ACTIVATE_DEVICE);
    window?.adobeDataLayer?.push({
      event: 'user-click',
      clickInfo: { clickName: 'Sign In Button' },
    });
  };

  useEffect(() => {
    focusManager.changeFocus(freeTrialButtonNav.id);
  }, []);

  const handleTryFree = () => {
    window?.adobeDataLayer?.push({
      event: 'user-click',
      clickInfo: { clickName: 'Free Trial Button' },
    });
    setHasTryFree(true);
    backgroundVideoRef.current.pause();
  };

  const handlePrivacyPolicy = () => {
    window.adobeDataLayer?.push({
      event: 'user-click',
      clickInfo: { clickName: 'Privacy Policy Button' },
    });
    history.push(appRouteConstants.PRIVACY_POLICY);
  };

  /* Handles the press of the BACK button to exit the app */
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);
  useEffect(() => {
    if (isMounted.current) {
      if (hasTryFree) {
        focusManager.changeFocus(focusManager.getCurrentFocus());

        if (!hasVideoEnded) {
          backgroundVideoRef.current.play();
        }

        setHasTryFree(false);
      } else {
        $badger.shutdown();
      }
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  const handleBackButton = () => {
    hasTryFreeContext.current = freeTrialButtonNav.id;
    setHasTryFree(false);
    window?.adobeDataLayer?.push({
      event: 'user-click',
      clickInfo: { clickName: 'Back Button' },
    });
  };

  const freeTrialDurationText = storageManager.cloudCookieManager.get(
    StorageKeyConstant.FREE_TRIAL_DURATION_TEXT,
  );
  const platinumPlanText = storageManager.cloudCookieManager.get(
    StorageKeyConstant.PLATINUM_PLAN_TEXT,
  );
  const subscriptionBenefits = [
    platinumPlanText.planLine1,
    platinumPlanText.planLine2,
    platinumPlanText.planLine3,
  ];

  const onTimeUpdate = e => {
    const { currentTime, duration } = e.target;
    if (currentTime >= duration - 2) {
      e.target.pause();
    }
    if (currentTime >= duration - 4) {
      setHasVideoEnded(true);
    }
  };

  useEffect(() => {
    backgroundVideoRef.current.src =
      'aamps://siriusxm-prod-vod.mountain.siriusxm.com/east/tokenbypass-SXM-Onboarding-Video/hls/tokenbypass-SXM-Onboarding-Video.m3u8';
    backgroundVideoRef.current.load();
    backgroundVideoRef.current.play();
  }, []);

  return (
    <>
      <video
        id="welcome-background-video"
        autoPlay
        onTimeUpdate={onTimeUpdate}
        preload={'true'}
        ref={backgroundVideoRef}
      ></video>
      <div
        id="welcome-gradient"
        style={{ opacity: hasVideoEnded ? 1 : 0 }}
      ></div>
      <div
        id="bottom-gradient"
        style={{ opacity: hasVideoEnded ? 0 : 1 }}
      ></div>
      <Page nav={pageNav} className="welcome-page">
        <TryFreeModalComponent
          hasTryFree={hasTryFree}
          handleBackButton={handleBackButton}
          subscriptionUrl={subscriptionUrl}
          restoreFocus={hasTryFreeContext.current}
          freeTrialDurationText={freeTrialDurationText}
          handlePrivacyPolicy={handlePrivacyPolicy}
        />
        <div className="welcome-wrapper">
          <div className="welcome-content-container">
            <div className="welcome-content">
              <div
                className="welcome-subscription-container"
                style={{ opacity: hasVideoEnded ? 1 : 0 }}
              >
                <div className="welcome-logo">
                  <div style={{ backgroundImage: `url(${SiriusXMLogo})` }} />
                </div>
                <div className="welcome-subscription-title">
                  <span>SiriusXM {platinumPlanText.planTitle}</span>
                  <span>Music, sports, news, talk and comedy.</span>
                </div>
                <div className="welcome-subscription-details">
                  {subscriptionBenefits.map((benefitText, benefitIndex) => {
                    return (
                      <div
                        className="welcome-subscription-benefit"
                        key={`benefit-${benefitIndex}`}
                      >
                        <div className="subscription-benefit-check">
                          <img src={BlueCheck} />
                        </div>
                        <div className="subscription-benefit-text">
                          {benefitText}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="welcome-buttons-container">
                <div className="welcome-sign-in-button-container">
                  <BlueButton
                    className={'sign-in-button'}
                    nav={signInButtonNav}
                    type={'primary'}
                    onClick={handleSignIn}
                  >
                    Sign In
                  </BlueButton>
                </div>
                <div className="welcome-free-trial-button-container">
                  <BlueButton
                    className={'sign-in-button'}
                    nav={freeTrialButtonNav}
                    type={'primary'}
                    isHeight={false}
                    onClick={handleTryFree}
                  >
                    {freeTrialDurationText}
                  </BlueButton>
                  <div className="welcome-privacy-policy-container">
                    Try FREE for 3 months, then $9.99/ month. Cancel anytime.
                    <FocusButton
                      nav={privacyPolicyButtonNav}
                      theme={privacyPolicyButtonTheme}
                      onClick={handlePrivacyPolicy}
                    >
                      Privacy Policies
                    </FocusButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};
