import React from 'react';
import './music-bars.component.scss';

const MusicBars = props => {
  return (
    <div className="music-bars">
      <div className={`bar ${!props.isPlaying ? 'paused' : ''}`}></div>
      <div className={`bar ${!props.isPlaying ? 'paused' : ''}`}></div>
      <div className={`bar ${!props.isPlaying ? 'paused' : ''}`}></div>
    </div>
  );
};

export default MusicBars;
