import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { IAppConfig } from '../config/interfaces/app-config.interface';
import { IClientConfiguration } from '../config';
import {
  ISession,
  openAccessStatus,
  OpenAccessStatus,
  FreeTierStatus,
} from './session.interface';
import {
  IAuthenticationResponse,
  IAuthenticationLogoutResponse,
} from './authentication.response.interface';
import {
  IProviderDescriptor,
  addProvider,
  ApiCodes,
  AppErrorCodes,
} from '../service';
import { Logger } from '../logger';
import { HTTP_ERROR_MESSAGE } from '../service/consts';
import { SessionMonitorService } from '../session/session-monitor.service';
import { AppMonitorService } from '../app-monitor';
import { AuthenticationDelegate } from './authentication.delegate';
import { NuDetectPayload } from './authentication.constants';
import { inBeta } from '../util';
import { AuthenticationModel } from './authentication.model';
import { InitializationModel } from '../initialization/initialization.model';
import { InitializationStatusCodes } from '../initialization/initialization.const';
import { IResumeGlobalSetting } from '../resume/resume.interface';
import * as _ from 'lodash';
import { EFreeTierFlow } from '../free-tier/free-tier.interface';
import { StorageService, StorageKeyConstant } from '../storage';

/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  AuthenticationService used to make user authenticate user
 */
export class AuthenticationService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('AuthenticationService');

  /**
   * subject for delivering the user session data through the session observable
   * @type {any}
   */
  private sessionSubject: BehaviorSubject<ISession> = null;

  /**
   * Contains the session data for the user.
   */
  public session: ISession = new Session();

  /*
   * Observable (hot, subscribe returns most recent item) that can be used to obtain the users session data
   */
  public userSession: Observable<ISession> = null;

  public authenticatingSubject: BehaviorSubject<boolean> = null;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(AuthenticationService, AuthenticationService, [
      AppMonitorService,
      AuthenticationDelegate,
      'IAppConfig',
      SessionMonitorService,
      AuthenticationModel,
      InitializationModel,
      StorageService,
    ]);
  })();

  /**
   * Constructor
   * @param authenticationDelegate used to make calls API calls
   * @param SERVICE_CONFIG injected by the client to configure the service layer.
   */
  constructor(
    public appMonitorService: AppMonitorService,
    private authenticationDelegate: AuthenticationDelegate,
    private SERVICE_CONFIG: IAppConfig,
    private sessionMonitorService: SessionMonitorService,
    private authenticationModel: AuthenticationModel,
    private initializationModel: InitializationModel,
    private storageService: StorageService,
  ) {
    this.sessionSubject = new BehaviorSubject(this.session);
    this.userSession = this.sessionSubject.pipe(filter(session => !!session));
    this.authenticationModel.userSession = this.session;

    this.sessionMonitorService.apiCodesSubject$
      .pipe(filter(codes => !!codes))
      .subscribe(codes => {
        this.setAuthRequired(codes, this.session, this.sessionSubject);
      });

    if (this.SERVICE_CONFIG.loginRequired) {
      this.logout();
    }

    this.initializationModel.initializationState$
      .pipe(
        filter(
          status => !!status && status === InitializationStatusCodes.OPENACCESS,
        ),
      )
      .subscribe(status => {
        this.openAccessLogin();
      });
  }

  /**
   * If API Sends any of authLoginCodes then this function called . This is used to set authentuication required.
   * @param {Array<number>} codes
   * @param {ISession} session
   * @param {BehaviorSubject<ISession>} sessionObject
   */
  private setAuthRequired(
    codes: Array<number>,
    session: ISession,
    sessionObject: BehaviorSubject<ISession>,
  ): void {
    this.SERVICE_CONFIG.inPrivateBrowsingMode.subscribe(isInPrivateMode => {
      AuthenticationService.setPrivateBrowsingStatus(
        isInPrivateMode,
        session,
        sessionObject,
      );

      // If we are NOT in private browsing mode AND we are NOT in public beta then we can allow the user to
      // take an open access session.
      //
      // If we are in private (incognito) browsing mode OR we are running in public beta then we want the user
      // to have to login normally.  The reasons for this are
      //
      // 1) In incognito mode we cannot control the amount of time an open access session will be valid for
      //    This is due to the fact that cookies and local storage are not persisted across sessions
      // 2) In public beta (beta.siriusxm.com) we only want selected users with valid subscriptions to use
      //    the app.
      if (
        !isInPrivateMode &&
        !inBeta(this.SERVICE_CONFIG.apiEndpoint) &&
        !this.SERVICE_CONFIG.loginRequired
      ) {
        AuthenticationService.setOpenAccessStatus(
          session,
          sessionObject,
          codes,
        );
      }

      AuthenticationService.authRequired(session, sessionObject, codes);
    });
  }

  /**
   * Mark the session as not being authenticated and trigger the sessionSubject that there has been a change to
   * the session
   * @param session is the session model for the user
   * @param sessionSubject allows triggering the session observable to indicate that session has changed
   */
  private static authRequired(
    session: ISession,
    sessionSubject: BehaviorSubject<ISession>,
    apiCodes?: Array<Number>,
  ) {
    if (apiCodes && apiCodes.length > 0) {
      apiCodes.forEach((code: number) => {
        switch (code) {
          case ApiCodes.ACCOUNT_LOCKED:
            session.accountLocked = true;
            break;

          case ApiCodes.EXPIRED_SUBSCRIPTION:
            session.accountExpired = true;
            break;

          case ApiCodes.EXPIRED_PROSPECT_TRIAL_ACCOUNT:
            session.trialAccountExpired = true;
            break;

          case ApiCodes.AUTH_REQUIRED:
          case ApiCodes.ALC_CODE_NOT_FOUND:
            session.authenticated = false;
            session.activeOpenAccessSession = false;
            break;

          case ApiCodes.SIMULTANEOUS_LISTEN:
          case ApiCodes.SIMULTANEOUS_LISTEN_SAME_DEVICE:
            session.duplicateLogin = true;
            break;

          case ApiCodes.IT_DOWN:
            session.itDown = true;
            break;
        }
      });
    }

    sessionSubject.next(session);
  }

  /**
   * Sets the open access status
   * @param {ISession} session
   * @param {BehaviorSubject<ISession>} sessionSubject
   * @param {Array<number>} apiCodes
   */
  private static setOpenAccessStatus(
    session: ISession,
    sessionSubject: BehaviorSubject<ISession>,
    apiCodes: Array<number>,
  ): void {
    // The api response for invalid creds doesn't tell us whether the user is OA eligible or not.
    // Because an OA eligible user might still try to log in. So we should not update the
    // openAccessStatus based off of the return from this call.
    if (apiCodes.indexOf(ApiCodes.INVALID_CREDENTIALS) >= 0) {
      return;
    }

    const openAccessEligibility =
      apiCodes.indexOf(ApiCodes.OPEN_ACCESS_ELIGIBLE) >= 0
        ? openAccessStatus.ELIGIBLE
        : openAccessStatus.INELIGIBLE;

    const currentOpenAccessStatus =
      apiCodes.length === 1 && apiCodes[0] === ApiCodes.AUTH_REQUIRED
        ? openAccessStatus.UNAVAILABLE
        : openAccessEligibility;

    session.openAccessStatus = currentOpenAccessStatus;
  }

  /**
   * Sets the private browsing (incognito) status
   * @param {boolean} status whether or not the user is in a private browsing mode.
   * @param {ISession} session
   * @param {BehaviorSubject<ISession>} sessionSubject
   */
  private static setPrivateBrowsingStatus(
    status: boolean,
    session: ISession,
    sessionSubject: BehaviorSubject<ISession>,
  ): void {
    session.isInPrivateBrowsingMode = status;
  }

  /**
   * login method used to login the user using API
   * @param username - username passed to API
   * @param password - password passed to API
   * @returns observable that can be subscribed to login response
   */
  public login(
    username: string,
    password: string,
    screenFlow: EFreeTierFlow = null,
  ): Observable<IAuthenticationResponse> {
    AuthenticationService.logger.debug(`login( ${username} )`);

    let nuDetectPayload: NuDetectPayload;
    const hiddenValue = this.SERVICE_CONFIG.nuDetect.getHiddenValue();

    /**
     * If we have a hidden value then nudetect is enabled and we can populate the data needed by the
     * authentication call.
     *
     * If we do not have a hidden value then the nuDetectPayload will be undefined and no nudetect data will be
     * sent to the API.
     */
    if (hiddenValue) {
      nuDetectPayload = {};
      nuDetectPayload.ndPayload = JSON.parse(hiddenValue);
      nuDetectPayload.ndSessionId = this.SERVICE_CONFIG.nuDetect.sessionId;
    }

    this.authenticationModel.setAuthenticatingValue(true);

    const authResponse = this.authenticationDelegate.login(
      username,
      password,
      screenFlow,
      nuDetectPayload,
    );

    authResponse.subscribe(
      onAuthenticationSuccess.bind(this),
      onAuthenticationFault.bind(this),
    );

    return authResponse;

    /**
     * On the authentication response becomes available, save it to session
     * @param  response - returned by the delegate.
     */
    function onAuthenticationSuccess(response: IAuthenticationResponse): void {
      this.SERVICE_CONFIG.loginRequired = false;

      AuthenticationService.logger.debug(
        `loginResult( username = ${username} )`,
      );

      const authData = response.authenticationData;

      Object.keys(authData).forEach((key: string) => {
        this.session[key] = authData[key];
      });

      this.session.username = username;
      this.session.authenticated = true;
      this.session.loginAttempts = 0;
      this.session.activeOpenAccessSession = false;
      this.storageService.setItem(StorageKeyConstant.USERNAME, username);
      // Note: If login happens from OA then init state is unauthenticated need to reset the flag
      // Edge/race condition noticed when app on OA in progress and signed in Init state is running
      // if we reset flag then before refresh making remaining consume/noop calls to avoid the race condition
      // resetting flag only when not authenticated state.
      if (
        this.initializationModel.state ===
        InitializationStatusCodes.UNAUTHENTICATED
      ) {
        this.authenticationModel.setAuthenticatingValue(false);
      }

      this.authenticationModel.userSession = this.session;
      this.authenticationModel.authenticated = true;
      this.sessionSubject.next(this.session);
    }

    /**
     * On the delegate throws exception then fault handler get called. and propagate error back to the caller.
     * @param error - returned by the delegate.
     */
    function onAuthenticationFault({ message, modules, code }): void {
      AuthenticationService.logger.error(
        `onAuthenticationFault( code = ${code}, message = ${JSON.stringify(
          message,
        )} )`,
      );

      this.authenticationModel.setAuthenticatingValue(false);

      if (message === HTTP_ERROR_MESSAGE) {
        this.appMonitorService.triggerFaultError({
          faultCode: AppErrorCodes.FLTT_HTTP_AUTHENTICATION_FAILURE,
        });
      }

      if (modules && modules.authenticationData) {
        Object.assign(this.session, modules.authenticationData);
        this.authenticationModel.userSession = this.session;
        this.sessionSubject.next(this.session);
      }

      this.authenticationModel.authenticated = false;
    }
  }

  /**
   * starts the open Access login.
   */
  public openAccessLogin(): void {
    AuthenticationService.logger.debug(`openAccessLogin() )`);

    if (this.session.openAccessStatus === openAccessStatus.ELIGIBLE) {
      this.session.authenticated = true;
      this.session.activeOpenAccessSession = true;
      this.authenticationModel.userSession = this.session;
      this.sessionSubject.next(this.session);
    }
  }

  /**
   * logout method used to logout the user using API
   * @returns {Observable<IAuthenticationLogoutResponse>}
   */
  public logout(): Observable<IAuthenticationLogoutResponse> {
    AuthenticationService.logger.debug(`logout()`);

    this.exit();

    const obs = this.authenticationDelegate.logout(false);

    const authRequired = () => {
      AuthenticationService.authRequired(this.session, this.sessionSubject, [
        ApiCodes.AUTH_REQUIRED,
      ]);
    };

    obs.subscribe(authRequired, authRequired);
    this.sessionMonitorService.logout();

    return obs;
  }

  /**
   * Exit will exit the app.  This currently involves making sure we send a final consume call to tuneOut of the
   * the media that is currently playing
   */
  public exit() {
    this.session.exited = true;
    this.authenticationModel.userSession = this.session;
    this.storageService.setItem(StorageKeyConstant.USERNAME, null);
    this.sessionSubject.next(this.session);
  }

  /**
   * Determines if the user is authenticated.
   * @returns {boolean}
   */
  public isAuthenticated(): boolean {
    if (this.SERVICE_CONFIG.loginRequired) {
      return false;
    }
    return (
      (this.session.authenticated &&
        !!this.storageService.getItem(StorageKeyConstant.USERNAME)) ||
      (this.session.activeOpenAccessSession &&
        !this.SERVICE_CONFIG.isFreeTierEnable)
    );
  }

  /**
   * Determines if the user is eligible for the open access
   */
  public isOpenAccessEligible(): boolean {
    return (
      this.session.openAccessStatus === openAccessStatus.ELIGIBLE &&
      !this.session.isInPrivateBrowsingMode
    );
  }

  /**
   * Determines if the user is in open access session
   */
  public isActiveOpenAccessSession(): boolean {
    return this.session.activeOpenAccessSession;
  }

  /**
   * update the open access session details
   * @param {boolean} openAccessPeriodState
   */
  public updateOpenAccessSession({
    openAccessPeriodState = false,
  }: IClientConfiguration): void {
    const wasAuthenticated = this.session.authenticated;
    this.session.activeFreeTierSession = false;
    this.session.activeOpenAccessSession = openAccessPeriodState;
    //TODO: According to the unit tests, if openAccessPeriodState is true then the user should be considered as authenticated
    //However this is causing issues, so for now we're going to comment this logic, since it's possible Open Access will not be part of the app at all
    /*this.session.authenticated =
      !this.SERVICE_CONFIG.isFreeTierEnable &&
      this.session.activeOpenAccessSession
        ? true
        : false;
    */
    this.authenticationModel.userSession = this.session;
    if (
      wasAuthenticated !== this.session.authenticated ||
      this.SERVICE_CONFIG.isFreeTierEnable
    ) {
      this.sessionSubject.next(this.session);
    }
  }

  public updateFreeTierAccessSession(resumeResponse) {
    this.session.freeTierStatus = this.getFreeTierStatus(
      resumeResponse.globalSettingList,
    );
    this.session.activeFreeTierSession =
      !resumeResponse.clientConfiguration.openAccessPeriodState &&
      this.session.freeTierStatus === FreeTierStatus.ELIGIBLE;
    this.sessionSubject.next(this.session);
  }

  public isUserRegistered() {
    return (
      this.SERVICE_CONFIG.isFreeTierEnable &&
      !this.session.activeOpenAccessSession
    );
  }

  /**
   * Returns true if FreeTier is expired
   */
  public isFreeTierExpired() {
    return this.session.freeTierStatus === FreeTierStatus.INELIGIBLE;
  }

  public getFreeTierStatus(globalSettingList): FreeTierStatus {
    if (globalSettingList && globalSettingList.globalSettings) {
      const globalSettings: IResumeGlobalSetting[] =
        globalSettingList.globalSettings;
      const iapPreviewEndTimeSetting = _.filter(globalSettings, {
        settingName: 'iapPreviewEndTime',
      })[0];

      const iapPreviewEndTimeMs =
        iapPreviewEndTimeSetting && iapPreviewEndTimeSetting.settingValue
          ? new Date(iapPreviewEndTimeSetting.settingValue).getTime()
          : 0;

      const currentTime = new Date().getTime();
      if (!iapPreviewEndTimeMs) {
        return FreeTierStatus.UNAVAILABLE;
      }
      if (!!iapPreviewEndTimeMs && iapPreviewEndTimeMs > currentTime) {
        return FreeTierStatus.ELIGIBLE;
      }
      return FreeTierStatus.INELIGIBLE;
    }
  }

  /**
   * Reclaims the session when duplicate login is detected
   * This will trigger Resume call
   */
  public reclaimSession() {
    this.session.itDown = false;
    this.session.duplicateLogin = false;
    this.authenticationModel.userSession = this.session;
    this.sessionSubject.next(this.session);
  }

  /**
   * method used to create a 6 digit activation code to authenticate
   * @returns observable that can be subscribed to code creation response
   */
  public createAlternateLogin(): Observable<IAuthenticationResponse> {
    AuthenticationService.logger.debug('createAlternateLogin()');

    const authResponse = this.authenticationDelegate.createAlternateLogin();

    authResponse.subscribe(
      onCodeCreationSuccess.bind(this),
      onCodeCreationFault.bind(this),
    );

    return authResponse;

    /**
     * Once the authentication response with the activation code becomes available, save it to session
     * @param  response - returned by the delegate.
     */
    function onCodeCreationSuccess(response: IAuthenticationResponse): void {
      const authData = response.authenticationData;

      Object.keys(authData).forEach((key: string) => {
        this.session[key] = authData[key];
      });

      this.authenticationModel.userSession = this.session;
      this.sessionSubject.next(this.session);
    }

    /**
     * Once the delegate throws exception then fault handler gets called, and propagates the error back to the caller.
     * @param error - returned by the delegate.
     */
    function onCodeCreationFault({ message, modules, code }): void {
      AuthenticationService.logger.error(
        `onCodeCreationFault( code = ${code}, message = ${JSON.stringify(
          message,
        )} )`,
      );

      if (message === HTTP_ERROR_MESSAGE) {
        this.appMonitorService.triggerFaultError({
          faultCode: AppErrorCodes.FLTT_HTTP_AUTHENTICATION_FAILURE,
        });
      }

      if (modules && modules.authenticationData) {
        Object.assign(this.session, modules.authenticationData);
        this.authenticationModel.userSession = this.session;
        this.sessionSubject.next(this.session);
      }
    }
  }

  /**
   * method used to complete the authentication process linked to the activation code provided
   * @param regCode - registration code for which teh app will verify if the authentication process has completed
   * @returns observable that can be subscribed to authentication response
   */
  public completeAlternateLogin(
    regCode: string,
  ): Observable<IAuthenticationResponse> {
    AuthenticationService.logger.debug(`completeAlternateLogin( ${regCode} )`);

    const authResponse = this.authenticationDelegate.completeAlternateLogin(
      regCode,
    );

    authResponse.subscribe(
      onAlternateLoginCompletionSuccess.bind(this),
      onAlternateLoginCompletionFailure.bind(this),
    );

    return authResponse;

    /**
     * Once the authentication response with the activation code becomes available, save it to session
     * @param  response - returned by the delegate.
     */
    function onAlternateLoginCompletionSuccess(
      response: IAuthenticationResponse,
    ): void {
      this.SERVICE_CONFIG.loginRequired = false;
      const authData = response.authenticationData;

      Object.keys(authData).forEach((key: string) => {
        this.session[key] = authData[key];
      });

      this.session.authenticated = true;
      this.session.loginAttempts = 0;
      this.session.activeOpenAccessSession = false;
      this.storageService.setItem(
        StorageKeyConstant.USERNAME,
        authData.username,
      );

      this.authenticationModel.userSession = this.session;
      this.sessionSubject.next(this.session);
    }

    /**
     * Once the delegate throws exception then fault handler gets called, and propagates the error back to the caller.
     * @param error - returned by the delegate.
     */
    function onAlternateLoginCompletionFailure({
      message,
      modules,
      code,
    }): void {
      AuthenticationService.logger.debug(
        `onAlternateLoginCompletionFailure( code = ${code}, message = ${JSON.stringify(
          message,
        )} )`,
      );

      if (message === HTTP_ERROR_MESSAGE) {
        this.appMonitorService.triggerFaultError({
          faultCode: AppErrorCodes.FLTT_HTTP_AUTHENTICATION_FAILURE,
        });
      }

      if (modules && modules.authenticationData) {
        Object.assign(this.session, modules.authenticationData);
        this.authenticationModel.userSession = this.session;
        this.sessionSubject.next(this.session);
      }
    }
  }

  public authorizeUserOnItBypass() {
    this.SERVICE_CONFIG.loginRequired = false;
    this.session.authenticated = true;
    this.session.loginAttempts = 0;
    this.session.activeOpenAccessSession = false;
    this.authenticationModel.userSession = this.session;
    this.sessionSubject.next(this.session);
  }
}

/**
 * Class for holding the user's session data
 */
export class Session implements ISession {
  public authenticated = true;
  public exited = false;
  public username = '';
  public forgotPassword = false;
  public startTrial = false;
  public loggedOut = false;
  public loginAttempts = 0;
  public loginFaultCode = 0;
  public loginFaultMessages: Array<string> = [];
  public timeStamp = Date.now();
  public sessionID = '';
  public accountLocked = false;
  public accountExpired = false;
  public trialAccountExpired = false;
  public remainingLockoutMinutes = 0;
  public remainingLockoutSeconds = 0;
  public activeOpenAccessSession = false;
  public openAccessStatus: OpenAccessStatus = openAccessStatus.UNAVAILABLE;
  public isInPrivateBrowsingMode = false;
  public duplicateLogin = false;
  public itDown = false;
  public activeFreeTierSession = false;
  public freeTierStatus: FreeTierStatus = FreeTierStatus.UNAVAILABLE;
  public registrationCode = '';
  public regExpiration = '';
  public pollFrequency = 20; //seconds
}
