import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { catchError, filter, flatMap, map, share, take } from 'rxjs/operators';
import * as _ from 'lodash';
import moment from 'moment';
import { IAppConfig } from '../config/interfaces/app-config.interface';
import { DateUtil } from '../util/date.util';
import {
  IChunk,
  IClip,
  IHlsManifestFile,
  IMediaCut,
  IMediaEndPoint,
  IMediaItem,
  IMediaItemWithDuration,
  IMediaLiveCuePoint,
  IMediaSegment,
  IMediaVideo,
  ITime,
  IPosition,
} from './tune.interface';
import {
  addProvider,
  AffinityConstants,
  AffinityConstMapping,
  AffinityType,
  ApiCodes,
  ApiLayerTypes,
  ContentTypes,
  findMediaItemByTimestamp,
  HttpProvider,
  IChannel,
  ICutMarker,
  IDmcaInfo,
  IEpisodeMarker,
  IHttpRequestConfig,
  ILegacyId,
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  IMarkerList,
  IMediaEpisode,
  IMediaShow,
  IProviderDescriptor,
  IRelativeUrlSetting,
  IrNavClassConstants,
  ISegmentMarker,
  Logger,
  MediaUtil,
  NAME_BACKGROUND,
  NAME_COLOR_CHANNEL_LOGO,
  normalizeEpisodeSegments,
  NowPlayingConsts,
  PLATFORM_ANY,
  PLATFORM_WEBEVEREST,
  PlayerTypes,
  ServiceEndpointConstants,
  TuneResponse,
} from '../index';
import { MediaPlayerConstants } from '../mediaplayer/media-player.consts';
import { IShowMarker, IVideoMarker } from '../service/types/marker.types';
import { VideoPlayerConstants } from '../mediaplayer/videoplayer/video-player.consts';
import { ContextualUtilities } from '../contexual/contextual.utilities';
import { getMarkers } from '../util/tune.util';
import { ConfigService } from '../config';
import { AudioPlayerConstants } from '../mediaplayer/audioplayer/audio-player.consts';
import { MultiTrackList } from '../mediaplayer/multitrackaudioplayer/multi-track-list';
import { CreativeArtsTypes } from '../service/types/creative.arts.item';
import { ChromecastModel } from '../chromecast/chromecast.model';
import { TuneChromecastService } from '../chromecast/tune.chromecast.service';
import { RefreshTracksService } from '../refresh-tracks/refresh-tracks.service';
import { msToSeconds, relativeUrlToAbsoluteUrl } from '../util/utilities';
import { RefreshTracksModel } from '../refresh-tracks/refresh-tracks.model';
import { TuneModel, TuneState, TuneStatus } from './tune.model';
import { PlayheadTimestampService } from '../mediaplayer/playhead-timestamp.service';

interface IQueuedRequest {
  endPoint: string;
  responseType: string;
  config: IHttpRequestConfig;
  subject: BehaviorSubject<TuneResponse>;
}

/**
 * @MODULE:     service-lib
 * @CREATED:    09/18/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  TuneDelegate used to Make API Calls to tune live & update live & tune AOD.
 */
export class TuneDelegate {
  /**
   * Internal logger.
   * @type {Logger}
   */
  private static logger: Logger = Logger.getLogger('TuneDelegate');

  /**
   * Constant for the HLS_OUTPUT_MODE for live channel updates
   * @type {string}
   */
  private static HLS_OUTPUT_MODE_UPDATE = 'none';

  /**
   * Constant for the MARKER_PROPERTY_CUT for CUT
   * @type {string}
   */
  private static MARKER_PROPERTY_CUT = 'cut';

  /**
   * Constant for the MARKER_PROPERTY_DURATION for DURATION
   * @type {string}
   */
  private static MARKER_PROPERTY_DURATION = 'duration';

  /**
   * Outstanding request that has not been returned yet
   */
  private outStandingRequest: Observable<TuneResponse>;

  /**
   * Queued request that is waiting to be sent pending the response from an outstanding request
   */
  private queuedRequest: IQueuedRequest;

  /**
   * Reference to the last known, valid media endpoints. These are used after the first call to
   * the now-playing API as subsequent calls don't return endpoints based on simplified query string
   * params the client is required to send.
   */
  private static lastValidMediaEndpoints: IMediaEndPoint[] = [];

  /**
   * Allows static methods to access the injected instance of the SERVICE_CONFIG.
   * @type {null}
   */
  private static serviceConfig: IAppConfig = null;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(TuneDelegate, TuneDelegate, [
      HttpProvider,
      'IAppConfig',
      ConfigService,
      TuneChromecastService,
      ChromecastModel,
      RefreshTracksService,
      RefreshTracksModel,
      TuneModel,
      PlayheadTimestampService,
    ]);
  })();

  /**
   * Constructor
   * @param http - Used to make API calls
   * @param SERVICE_CONFIG contains the runtime application configuration
   * @param configService allows us to get the configuration parameters for the client
   * @param tuneChromecastService is the service that allows us to tune a chromecast receiver
   * @param chromecastModel allows us to ob serve the state of the chromecast functionality in the client
   * @param refreshTracksService is the service for getting more tracks for multi track playback types
   * @param refreshTracksModel is the state of track refreshing for the client
   * @param tuneModel is the state of tuning for the client
   * @param playheadTimestampService to get the current playhead timestamp.
   */
  constructor(
    private http: HttpProvider,
    SERVICE_CONFIG: IAppConfig,
    private configService: ConfigService,
    private tuneChromecastService: TuneChromecastService,
    private chromecastModel: ChromecastModel,
    private refreshTracksService: RefreshTracksService,
    private refreshTracksModel: RefreshTracksModel,
    private tuneModel: TuneModel,
    private playheadTimestampService: PlayheadTimestampService,
  ) {
    TuneDelegate.serviceConfig = SERVICE_CONFIG;
  }

  /**
   * Used to Make API call to get live data for a channel.
   * @param channelId - Used to send value to the API
   * @param assetGuid - Optional
   * @param showGuid -  Optional
   * @param enableAdsWizz - boolean to configure adEligble flag
   * @returns {Observable<TuneResponse>}- an observable that can be subscribed to to get the
   * results of the API call.
   */
  public tuneLive(
    channelId: string,
    assetGuid?: string,
    showGuid?: string,
    enableAdsWizz?: boolean,
    cutAssetGuid?: string,
    fromRetune?: boolean,
  ): Observable<TuneResponse> {
    TuneDelegate.logger.debug('tuneLive()');

    const params = _.cloneDeep(NowPlayingConsts.LIVEPARAMS);
    params.channelId = channelId;
    params.assetGuid = cutAssetGuid;

    if (
      TuneDelegate.serviceConfig.adsWizzSupported &&
      TuneDelegate.serviceConfig.deviceInfo.appRegion !==
        ApiLayerTypes.REGION_CA
    ) {
      params.adsEligible = true;
    }

    if (
      this.chromecastModel.playerType === PlayerTypes.REMOTE ||
      !enableAdsWizz
    ) {
      params.fbSXMBroadcast = true;
    }

    /**
     * To remain on current playhead while switching from AIS to SXM Broadcast
     */
    if (fromRetune) {
      const timeStamp = this.playheadTimestampService.playhead.getValue()
        .currentTime.zuluMilliseconds
        ? this.playheadTimestampService.playhead.getValue().currentTime
            .zuluMilliseconds
        : null;
      params.timestamp = timeStamp;
    }

    const config: IHttpRequestConfig = { params: params };
    TuneDelegate.lastValidMediaEndpoints = [];
    return this.tune(
      ServiceEndpointConstants.endpoints.NOW_PLAYING.V4_LIVE,
      ApiLayerTypes.RESPONSE_TYPE_LIVE,
      config,
    );
  }

  /**
   * Used to create and return tuneResponse.
   * API send mediaData and normalizes into TuneResponse class.
   * @param mediaData is returned from API and media data can be AOD or live.
   * @param response is returned from API.
   * @returns {TuneResponse}
   */
  public static normalizeMediaData(
    mediaData: any,
    liveVideoEnabled: boolean,
    relativeUrls: Array<IRelativeUrlSetting> = [],
    seededRadioBackgroundUrl: string,
  ): TuneResponse {
    let tuneResponse = new TuneResponse();
    tuneResponse.liveCuePoint = undefined;
    tuneResponse.channelId = mediaData.channelId;
    tuneResponse.streamingAdsId = mediaData.streamingAdsId;
    tuneResponse.mediaType = mediaData.mediaType;
    tuneResponse.wallClockRenderTime = mediaData.wallClockRenderTime
      ? new Date(
          DateUtil.convertToDate(mediaData.wallClockRenderTime),
        ).getTime()
      : null;

    let normalizeMediaEndpoints = TuneDelegate.normalizeMediaEndPoints(
      mediaData.mediaType,
    );

    switch (mediaData.mediaType) {
      case ContentTypes.AOD:
        tuneResponse.mediaId = mediaData.accessControlIdentifier;
        break;

      case ContentTypes.PODCAST:
        tuneResponse = TuneDelegate.normalizePodcastMediaData(
          mediaData,
          tuneResponse,
          relativeUrls,
        );
        return tuneResponse;
        break;

      case ContentTypes.LIVE_AUDIO:
        tuneResponse.liveCuePoint = normalizeLiveCuePoint(mediaData);
        tuneResponse.mediaId = mediaData.channelId;
        break;

      case ContentTypes.VOD:
        normalizeMediaEndpoints = TuneDelegate.normalizeVideoEndPoints;
        mediaData = TuneDelegate.generateFakeVideoMarkers(mediaData);
        tuneResponse.mediaId = findMediaIdForVOD(mediaData);
        break;

      case ContentTypes.ADDITIONAL_CHANNELS:
        tuneResponse = TuneDelegate.normalizeAdditionalChannelData(
          mediaData,
          tuneResponse,
          relativeUrls,
        );
        break;
      case ContentTypes.SEEDED_RADIO:
        tuneResponse = TuneDelegate.normalizeSeededRadioData(
          mediaData,
          tuneResponse,
          relativeUrls,
          seededRadioBackgroundUrl,
        );
        break;
    }

    if (
      mediaData.mediaType === ContentTypes.ADDITIONAL_CHANNELS ||
      mediaData.mediaType === ContentTypes.SEEDED_RADIO
    ) {
      return tuneResponse;
    }

    tuneResponse.updateFrequency = mediaData.updateFrequency;
    tuneResponse.connectInfo = mediaData.connectInfo;
    tuneResponse.hlsConsumptionInfo = mediaData.hlsConsumptionInfo;
    tuneResponse.aodEpisodeCount = mediaData.aodEpisodeCount;
    tuneResponse.isDataComeFromResume = false;
    tuneResponse.isDataComeFromResumeWithDeepLink = false;
    tuneResponse.inactivityTimeOut = mediaData.inactivityTimeOut
      ? mediaData.inactivityTimeOut
      : 0;
    tuneResponse.scoreUpdates = []; //TODO: Find out the name of the layer for sports scores

    //Temporary till we're ready to go live with live video
    // remove only need to check "liveVideoEnabled" flag once done, remove the rest
    const liveVideoReallyEnabled: boolean =
      liveVideoEnabled &&
      !!TuneDelegate.serviceConfig &&
      !ContextualUtilities.isProdServer(TuneDelegate.serviceConfig.apiEndpoint);

    const videoMarkerLayer: string = MediaUtil.isAudioMediaTypeLive(
      mediaData.mediaType,
    )
      ? ApiLayerTypes.LIVE_VIDEO_LAYER
      : ApiLayerTypes.VIDEO_LAYER;

    tuneResponse.mediaEndPoints = normalizeMediaEndpoints(mediaData);
    tuneResponse.videos =
      liveVideoReallyEnabled ||
      MediaUtil.isVideoMediaTypeOnDemand(mediaData.mediaType)
        ? TuneDelegate.normalizeVideoMarker(
            mediaData,
            videoMarkerLayer,
            liveVideoEnabled,
          )
        : [];
    tuneResponse.cuts = TuneDelegate.normalizeCutMarker(mediaData);
    tuneResponse.segments = TuneDelegate.normalizeSegmentMarker(mediaData);
    tuneResponse.originalSegments = tuneResponse.segments;
    tuneResponse.shows = TuneDelegate.normalizeShowMarker(mediaData);

    // TODO: sometimes segments have no titles, if this ever gets fixed in the API then this code has no effect
    //       and can be removed.  Segments with no titles get the title of the cut that contains the start time
    //       of the segment
    tuneResponse.segments = fixupSegmentTitles(
      tuneResponse.segments,
      tuneResponse.cuts,
    );

    tuneResponse.episodes = TuneDelegate.normalizeEpisodeMarker(
      mediaData,
      tuneResponse.segments,
      tuneResponse.cuts,
      tuneResponse.videos,
      ApiLayerTypes.EPISODE_LAYER,
    );
    //recalculate episodes if it's a placeholder show
    tuneResponse.episodes = TuneDelegate.normalizePlaceholderEpisodeMarker(
      tuneResponse.episodes,
    );

    tuneResponse.futureEpisodes = TuneDelegate.normalizeEpisodeMarker(
      mediaData,
      tuneResponse.segments,
      tuneResponse.cuts,
      tuneResponse.videos,
      ApiLayerTypes.FUTURE_EPISODE_LAYER,
    );

    // If there's at least one video marker then add the live video endpoint to the list of available live endpoints.
    if (
      tuneResponse.videos.length > 0 &&
      mediaData.mediaType === ContentTypes.LIVE_AUDIO
    ) {
      tuneResponse.mediaEndPoints = tuneResponse.mediaEndPoints.concat(
        TuneDelegate.normalizeLiveVideoEndPoints(mediaData),
      );
    }

    tuneResponse.zuluOffset =
      tuneResponse.episodes.length > 0
        ? tuneResponse.episodes[0].times.zuluStartTime
        : 0;

    TuneDelegate.lastValidMediaEndpoints =
      tuneResponse.mediaEndPoints.length > 0
        ? tuneResponse.mediaEndPoints
        : TuneDelegate.lastValidMediaEndpoints;

    if (tuneResponse.mediaType === ContentTypes.AOD) {
      tuneResponse.startTime = getStartTimeForAOD(mediaData, tuneResponse);
    }

    return tuneResponse;

    /**
     * used to set Live cue point object and normalize time to times object. this object used to set scrub end
     * times.
     * @param mediaData is returned from API and media data can be AOD or live.
     * @returns {any} - returns cue point.
     */
    function normalizeLiveCuePoint(mediaData: any): IMediaLiveCuePoint {
      if (!mediaData.cuePointList) {
        return {} as IMediaLiveCuePoint;
      }

      let liveCuePoint = _.find(
        mediaData.cuePointList.cuePoints,
        (cuePoint: IMediaItem): boolean => cuePoint.layer === 'livepoint',
      );

      if (!liveCuePoint) {
        return {} as IMediaLiveCuePoint;
      }

      liveCuePoint = TuneDelegate.normalizeTime(liveCuePoint, undefined);

      delete liveCuePoint.layer;
      delete liveCuePoint.active;

      return liveCuePoint;
    }

    /**
     * VOD does not have a mediaId embedded in the respose, so we find the first episode marker for which we
     * have an assetGuid and an episode object, and we use the vodEpisodeGUID from the episode object as the
     * value for mediaId.  This keep live, AOD and VOD consistent in the rune responses
     *
     * @param mediaData is the API response for tuning VOD
     * @returns {string} the value to be used for the mediaId property on the tuine response to the service
     */
    function findMediaIdForVOD(mediaData: any): string {
      const episodeMarkers = getMarkers(
        mediaData.markerLists,
        ApiLayerTypes.EPISODE_LAYER,
      );
      const episodeMarker: IEpisodeMarker = _.find(
        episodeMarkers,
        marker =>
          marker.assetGUID !== undefined && marker.episode !== undefined,
      ) as IEpisodeMarker;

      return episodeMarker ? episodeMarker.episode.vodEpisodeGUID : null;
    }

    /**
     * Sometimes segments do not have titles.  This function will find segments without titles, and get the title
     * for the cut that covers the timestamp for the start of the segment
     *
     * @param {Array<IMediaSegment>} segments array of segments to process
     * @param {Array<IMediaCut>} cuts array of cuts to use to give titles to segments that do not have titles
     */
    function fixupSegmentTitles(
      segments: Array<IMediaSegment>,
      cuts: Array<IMediaCut>,
    ): Array<IMediaSegment> {
      segments.forEach((segment: IMediaSegment) => {
        const hasTitle: boolean = segment.hasOwnProperty('title');
        if (!hasTitle) {
          const cut: IMediaItemWithDuration = findMediaItemByTimestamp(
            segment.times.zuluStartTime,
            cuts,
          ) as IMediaItemWithDuration;
          segment.title = cut ? cut.title : '';
        }
      });

      return segments;
    }

    function getStartTimeForAOD(mediaData, tuneResponse) {
      const pausePoint: number =
        mediaData && mediaData.pausePointOffset
          ? mediaData.pausePointOffset
          : 0;
      const episodeDurationInMs: number =
        tuneResponse.episodes &&
        tuneResponse.episodes[0] &&
        tuneResponse.episodes[0].duration
          ? tuneResponse.episodes[0].duration * 1000
          : 0;

      const startTime: number =
        pausePoint >= episodeDurationInMs ? 0 : pausePoint;

      return startTime;
    }
  }

  /**
   * Return Normailized Tune pandora podcast response
   * @param mediaData
   * @param tuneResponse
   */
  public static normalizePodcastMediaData(
    mediaData: any,
    tuneResponse: TuneResponse,
    relativeUrls: Array<IRelativeUrlSetting> = [],
  ) {
    tuneResponse.mediaId = mediaData.episode.pandoraId;
    tuneResponse.channelId = '';
    tuneResponse.updateFrequency = mediaData.updateFrequency;
    tuneResponse.aodEpisodeCount = mediaData.show.episodeCount;
    tuneResponse.isDataComeFromResume = false;
    tuneResponse.isDataComeFromResumeWithDeepLink = false;
    tuneResponse.inactivityTimeOut = mediaData.inactivityTimeOut
      ? mediaData.inactivityTimeOut
      : 0;
    tuneResponse.scoreUpdates = [];
    tuneResponse.startTime = getStartTime(mediaData);

    tuneResponse.mediaEndPoints = TuneDelegate.normalizeIrisPodcastEndPoints(
      mediaData,
    );
    tuneResponse.channel = TuneDelegate.normalizeIrisPodcastChannel(
      mediaData,
      relativeUrls,
    );
    tuneResponse.cuts = TuneDelegate.normalizeIrisPodcastChannelCuts(
      mediaData.episode,
      mediaData.show,
      relativeUrls,
    );
    tuneResponse.shows = [];
    tuneResponse.episodes = TuneDelegate.normalizedIrisPodcastEpisodes(
      mediaData,
    );

    tuneResponse.segments = [
      {
        duration: tuneResponse.episodes[0].duration,
        legacyId: mediaData.episode.pandoraId,
        assetGUID: mediaData.episode.pandoraId,
        longDescription: tuneResponse.episodes[0].longTitle,
        shortDescription: '',
        endTimeInSeconds: tuneResponse.episodes[0].duration,
        startTimeInSeconds: 0,
        title: tuneResponse.episodes[0].title,
        type: '',
        times: tuneResponse.episodes[0].times,
        layer: '',
      },
    ];
    tuneResponse.originalSegments = tuneResponse.segments;

    tuneResponse.connectInfo = {
      email: '',
      facebook: '',
      phone: '',
      twitter: '',
    };

    return tuneResponse;

    /**
     * Returns start time of Iris Podcast episode.
     * @param mediaData
     */
    function getStartTime(mediaData) {
      const pausePoint: number =
        mediaData.audioInfo && mediaData.audioInfo.pausePointOffset
          ? mediaData.audioInfo.pausePointOffset
          : 0;
      const episodeDurationInMs: number =
        mediaData.episode && mediaData.episode.duration
          ? mediaData.episode.duration * 1000
          : 0;

      const startTime: number =
        pausePoint >= episodeDurationInMs ? 0 : pausePoint;

      return startTime;
    }
  }

  /**
   * Used to Make API call to get Iris AOD Podcast data for a show.
   * @param  episodeId - Used to send value to the API
   * @returns {Observable<TuneResponse>}
   */
  public tuneIrisPodcast(
    channelId: string,
    episodeId: string,
  ): Observable<TuneResponse> {
    TuneDelegate.logger.debug('tuneAODPodcast()');

    const params = _.cloneDeep(NowPlayingConsts.IRISPODCASTPARAM);
    params.episodeId = episodeId;
    const config: IHttpRequestConfig = { params: params };

    TuneDelegate.lastValidMediaEndpoints = [];

    return this.tune(
      ServiceEndpointConstants.endpoints.NOW_PLAYING.V4_PANDORA_PODCAST,
      ApiLayerTypes.RESPONSE_TYPE_PANDORA_PODCAST,
      config,
    );
  }

  /**
   * Used to Make API call to get AOD data for a show.
   * @param  channelId - Used to send value to the API
   * @param  caId - Used to send value to the API
   * @returns {Observable<TuneResponse>}
   */
  public tuneAOD(channelId: string, caId: string): Observable<TuneResponse> {
    TuneDelegate.logger.debug('tuneAOD()');

    const params = _.cloneDeep(NowPlayingConsts.AODPARAMS);
    params.channelId = channelId;
    params.caId = caId;
    const config: IHttpRequestConfig = { params: params };

    TuneDelegate.lastValidMediaEndpoints = [];

    return this.tune(
      ServiceEndpointConstants.endpoints.NOW_PLAYING.V2_AOD,
      ApiLayerTypes.RESPONSE_TYPE_AOD,
      config,
    );
  }

  /**
   * Used to make API call to tune a VOD aodEpisode
   * @param channelId - channel that the VOD aodEpisode belongs to
   * @param assetGUID - Unique id for the aodEpisode
   * @returns {Observable<TuneResponse>}
   */
  public tuneVOD(
    channelId: string,
    assetGUID: string,
    showGUID?: string,
  ): Observable<TuneResponse> {
    TuneDelegate.logger.debug('tuneVOD()');

    const params = _.cloneDeep(NowPlayingConsts.VODPARAMS);
    params.channelId = channelId;
    params.assetGUID = assetGUID;
    params.showGUID = showGUID ? showGUID : '';
    const config: IHttpRequestConfig = { params: params };

    TuneDelegate.lastValidMediaEndpoints = [];

    return this.tune(
      ServiceEndpointConstants.endpoints.NOW_PLAYING.V4_VOD,
      ApiLayerTypes.RESPONSE_TYPE_VOD,
      config,
    );
  }

  /**
   * Used to make API call to tune Additional channel
   * @param {string} channelGuid indicates which channel to tune
   * @returns {Observable<TuneResponse>}
   */
  public tuneAdditionalChannels(channelGuid: string): Observable<TuneResponse> {
    TuneDelegate.logger.debug('tuneLive()');

    const params = _.cloneDeep(NowPlayingConsts.ADDITIONALCHANNELSPARAMS);
    params.channelGuid = channelGuid;
    const config: IHttpRequestConfig = { params: params };
    TuneDelegate.lastValidMediaEndpoints = [];
    return this.tune(
      ServiceEndpointConstants.endpoints.ADDITIONAL_CHANNELS.V4_AIC,
      ApiLayerTypes.RESPONSE_TYPE_AIC,
      config,
    );
  }

  /**
   * Used to make API call to tune seeded radio channel
   * @param {string} sourceId
   * @retry {number} retry is the retry number for the call, defaults to zero
   * @returns {Observable<TuneResponse>}
   */
  public tuneSeededRadio(
    sourceId: string,
    retry: number = 0,
  ): Observable<TuneResponse> {
    TuneDelegate.logger.debug('tuneSeededRadio()');

    const SEEDED_RETRIES_ALLOWED = 2;
    const params = _.cloneDeep(NowPlayingConsts.SEEDEDRADIOSPARAMS);
    params.sourceId = sourceId;
    const config: IHttpRequestConfig = { params: params };
    TuneDelegate.lastValidMediaEndpoints = [];
    let setModelOnFailure = retry >= SEEDED_RETRIES_ALLOWED;

    return this.tune(
      ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_TUNE,
      ApiLayerTypes.RESPONSE_TYPE_SEEDED_RADIO,
      config,
      setModelOnFailure,
    ).pipe(
      catchError((error: any) => {
        if (
          error.code === ApiCodes.FLTT_SR_SOURCE_ENDED &&
          retry < SEEDED_RETRIES_ALLOWED
        ) {
          TuneDelegate.logger.warn(
            `Tune seeded ${error.code} encountered ... retry`,
          );
          return this.tuneSeededRadio(sourceId, retry + 1);
        }

        TuneDelegate.logger.error('Tune seeded error ${error}');
        throw error;
      }),
    ) as Observable<TuneResponse>;
  }

  /**
   * Normalizes the seeded Radio data.
   * @param mediaData
   * @param {TuneResponse} tuneResponse
   * @returns {TuneResponse}
   */
  public static normalizeSeededRadioData(
    mediaData: any,
    tuneResponse: TuneResponse,
    relativeUrls: Array<IRelativeUrlSetting> = [],
    seededRadioBackgroundUrl: string,
  ): TuneResponse {
    const stationFactory = _.get(mediaData, 'channel.stationFactory', null);

    tuneResponse.mediaId = stationFactory;
    tuneResponse.channelId = null;
    tuneResponse.stationId = mediaData.stationId;

    tuneResponse.mediaType = mediaData.mediaType;

    tuneResponse.updateFrequency = mediaData.updateFrequency;
    tuneResponse.liveCuePoint = null;
    tuneResponse.connectInfo = {
      email: '',
      facebook: '',
      phone: '',
      twitter: '',
    };
    tuneResponse.episodes = [];
    tuneResponse.segments = [];
    tuneResponse.futureEpisodes = [];
    tuneResponse.shows = [];
    tuneResponse.scoreUpdates = [];
    tuneResponse.hlsConsumptionInfo = '';
    tuneResponse.aodEpisodeCount = 0;
    tuneResponse.isDataComeFromResume = false;
    tuneResponse.isDataComeFromResumeWithDeepLink = false;
    tuneResponse.sequencerSessionId = mediaData.sequencerSessionId;
    tuneResponse.channel = TuneDelegate.normalizeSeededChannel(
      mediaData.channel,
      relativeUrls,
      seededRadioBackgroundUrl,
    );

    let clipArr =
      mediaData.clipList && mediaData.clipList.clips
        ? mediaData.clipList.clips.map(clip => {
            let newClip: IClip = {} as IClip;
            newClip.assetGUID = clip.trackToken;
            newClip.stationFactory = clip.stationFactory;
            newClip.index = clip.index;
            newClip.affinity = TuneDelegate.getAffinity(clip);
            newClip.albumName = clip.albumName;
            newClip.artistName = clip.artistName;
            newClip.assetType = clip.assetType;
            newClip.category = clip.category;
            newClip.clipImageUrl = relativeUrlToAbsoluteUrl(
              clip.clipImageUrl,
              relativeUrls,
            );
            newClip.consumptionInfo = clip.consumptionInfo;
            newClip.duration = clip.duration;
            newClip.title = clip.title;
            newClip.mediaEndPoints = [];
            newClip.contentUrlList = {
              contentUrls: clip.contentUrlList.contentUrls,
            };
            newClip.status = clip.status ? clip.status : ''; // NOTE: This is needed only for casting.
            // When user refreshes/other S connected, R Sends the status.
            clip.contentUrlList.contentUrls.forEach(contentUrl => {
              contentUrl.url = relativeUrlToAbsoluteUrl(
                contentUrl.url,
                relativeUrls,
              );
              newClip.mediaEndPoints.push({
                mediaFirstChunks: [],
                manifestFiles: [
                  {
                    url: contentUrl.url,
                    size: AudioPlayerConstants.SMALL_SIZE,
                    name: '',
                  },
                ],
                position: {
                  isoTimestamp: '',
                  zuluTimestamp: 0,
                  positionType: '',
                },
                url: contentUrl.url,
                size: AudioPlayerConstants.SMALL_SIZE,
                name: '',
              });
            });
            if (!clip.crossfade) {
              clip.crossfade = {};
            }
            newClip.crossfade = {
              crossFade:
                clip.crossfade.crossFade >= 0 ? clip.crossfade.crossFade : -1,
              fade: clip.crossfade.fade >= 0 ? clip.crossfade.fade : -1,
              fadeDownDuration:
                clip.crossfade.fadeDownDuration >= 0
                  ? clip.crossfade.fadeDownDuration
                  : -1,
              fadeDownPos:
                clip.crossfade.fadeDownPos >= 0
                  ? clip.crossfade.fadeDownPos
                  : -1,
              fadeUpDuration:
                clip.crossfade.fadeUpDuration >= 0
                  ? clip.crossfade.fadeUpDuration
                  : -1,
              fadeUpPos:
                clip.crossfade.fadeUpPos >= 0 ? clip.crossfade.fadeUpPos : -1,
              introPos:
                clip.crossfade.introPos >= 0 ? clip.crossfade.introPos : -1,
              outroPos:
                clip.crossfade.outroPos >= 0 ? clip.crossfade.outroPos : -1,
            };

            newClip = TuneDelegate.normalizeCrossfadeParameters(newClip);

            return newClip;
          })
        : [];

    tuneResponse.clips = new MultiTrackList(
      tuneResponse.channel.stationFactory,
      clipArr,
    );

    tuneResponse.cuts = TuneDelegate.normalizeAdditionalChannelCuts(
      tuneResponse.clips.toArray(),
      relativeUrls,
    );

    return tuneResponse;
  }

  /**
   * Normalizes the additional channel data.
   * @param {TuneResponse} tuneResponse
   * @param aicImageDomain
   * @returns {TuneResponse}
   */
  public static normalizeAdditionalChannelCuts(
    clips: IClip[],
    relativeUrls: Array<IRelativeUrlSetting> = [],
  ): IMediaCut[] {
    return clips.map(clip => {
      let newCut: IMediaCut = {} as IMediaCut;
      newCut.album = {
        title: clip.albumName,
        creativeArts: [
          {
            type: CreativeArtsTypes.IMAGE,
            url: relativeUrlToAbsoluteUrl(clip.clipImageUrl, relativeUrls),
            size: 'medium',
          },
        ],
      };

      newCut.artists = [
        {
          name: clip.artistName,
        },
      ];
      newCut.title = clip.title;
      newCut.cutContentType = ApiLayerTypes.CUT_CONTENT_TYPE_SONG; // TODO Vpaindla what need to do?
      newCut.contentType =
        clip.category &&
        clip.category.toLowerCase() === ApiLayerTypes.TRACK_CONTENT_TYPE_MUSIC
          ? ApiLayerTypes.CUT_CONTENT_TYPE_SONG
          : ApiLayerTypes.CUT_CONTENT_TYPE_EXT;
      newCut.consumptionInfo = clip.consumptionInfo;
      newCut.duration = clip.duration;
      newCut.legacyId = null;
      newCut.assetGUID = clip.assetGUID;
      newCut.affinity = clip.affinity;
      newCut.times = {
        zuluStartTime: 0,
        isoStartTime: null,
        zuluEndTime: clip.duration,
      } as ITime;
      newCut.galaxyAssetId = clip.galaxyAssetId;
      newCut.stationFactory = clip.stationFactory;

      return newCut;
    });
  }

  /**
   * Used to Make API call to get update live data for a channel.
   * @param {string} channelId
   * @param {string} streamingAdsId
   * @returns {Observable<TuneResponse>}
   * results of the API call.
   */
  public updateLive(
    channelId: string,
    streamingAdsId?: string,
    enableAdsWizz?: boolean,
  ): Observable<TuneResponse> {
    TuneDelegate.logger.debug('updateLive()');
    const params = streamingAdsId
      ? _.cloneDeep(NowPlayingConsts.LIVEUPDATEPARAMS_WITH_ADS)
      : _.cloneDeep(NowPlayingConsts.LIVEUPDATEPARAMS);
    params.channelId = channelId;
    params.hls_output_mode = TuneDelegate.HLS_OUTPUT_MODE_UPDATE;

    if (TuneDelegate.serviceConfig.adsWizzSupported) {
      params.adsEligible = true;
    }

    if (streamingAdsId && enableAdsWizz) {
      params['with-ads'] = streamingAdsId;
    }

    if (
      this.chromecastModel.playerType === PlayerTypes.REMOTE ||
      !enableAdsWizz
    ) {
      params.fbSXMBroadcast = true;
    }

    const config: IHttpRequestConfig = { params: params };

    return this.tune(
      ServiceEndpointConstants.endpoints.NOW_PLAYING.V4_LIVE,
      ApiLayerTypes.RESPONSE_TYPE_LIVE,
      config,
    );
  }

  /**
   * Normalizes the additional channel data.
   * @param mediaData
   * @param {TuneResponse} tuneResponse
   * @returns {TuneResponse}
   */
  public static normalizeAdditionalChannelData(
    mediaData: any,
    tuneResponse: TuneResponse,
    relativeUrls: Array<IRelativeUrlSetting> = [],
    lastIndex: number = 0,
  ): TuneResponse {
    tuneResponse.mediaId = mediaData.channel
      ? mediaData.channel.channelGuid
      : '';
    tuneResponse.updateFrequency = mediaData.updateFrequency;
    tuneResponse.liveCuePoint = null;
    tuneResponse.channelId = mediaData.channel
      ? mediaData.channel.channelGuid
      : ''; // this is terrifying and needs to be refactored.
    tuneResponse.connectInfo = {
      email: '',
      facebook: '',
      phone: '',
      twitter: '',
    };
    tuneResponse.episodes = [];
    tuneResponse.segments = [];
    tuneResponse.futureEpisodes = [];
    tuneResponse.shows = [];
    tuneResponse.scoreUpdates = [];
    tuneResponse.hlsConsumptionInfo = '';
    tuneResponse.aodEpisodeCount = 0;
    tuneResponse.isDataComeFromResume = false;
    tuneResponse.isDataComeFromResumeWithDeepLink = false;
    tuneResponse.sequencerSessionId = mediaData.sequencerSessionId;
    tuneResponse.channel = mediaData.channel;

    let clipArr =
      mediaData.clipList && mediaData.clipList.clips
        ? mediaData.clipList.clips.map(clip => {
            let newClip: IClip = {} as IClip;
            newClip.index = lastIndex++;
            newClip.assetGUID = clip.assetGuid;
            newClip.albumName = clip.albumName;
            newClip.artistName = clip.artistName;
            newClip.assetType = clip.assetType;
            newClip.category = clip.category;
            newClip.clipImageUrl = relativeUrlToAbsoluteUrl(
              clip.clipImageUrl,
              relativeUrls,
            );
            newClip.consumptionInfo = clip.consumptionInfo;
            newClip.duration = clip.duration;
            newClip.title = clip.title;
            newClip.mediaEndPoints = [];
            newClip.contentUrlList = {
              contentUrls: clip.contentUrlList.contentUrls.map(contentUrl => ({
                ...contentUrl,
                url: contentUrl.url.replace('v3.', 'v2.'),
              })),
            };
            newClip.status = clip.status ? clip.status : ''; // NOTE: This is needed only for casting.
            // When user refreshes/other S connected, R Sends the status.
            clip.contentUrlList.contentUrls.forEach(contentUrl => {
              contentUrl.url = relativeUrlToAbsoluteUrl(
                contentUrl.url.replace('v3.', 'v2.'),
                relativeUrls,
              );
              newClip.mediaEndPoints.push({
                mediaFirstChunks: [],
                manifestFiles: [
                  {
                    url: contentUrl.url,
                    size: AudioPlayerConstants.SMALL_SIZE,
                    name: '',
                  },
                ],
                position: {
                  isoTimestamp: '',
                  zuluTimestamp: 0,
                  positionType: '',
                },
                url: contentUrl.url,
                size: AudioPlayerConstants.SMALL_SIZE,
                name: '',
              });
            });
            if (!clip.crossfade) {
              clip.crossfade = {};
            }
            newClip.crossfade = {
              crossFade:
                clip.crossfade.crossFade >= 0 ? clip.crossfade.crossFade : -1,
              fade: clip.crossfade.fade >= 0 ? clip.crossfade.fade : -1,
              fadeDownDuration:
                clip.crossfade.fadeDownDuration >= 0
                  ? clip.crossfade.fadeDownDuration
                  : -1,
              fadeDownPos:
                clip.crossfade.fadeDownPos >= 0
                  ? clip.crossfade.fadeDownPos
                  : -1,
              fadeUpDuration:
                clip.crossfade.fadeUpDuration >= 0
                  ? clip.crossfade.fadeUpDuration
                  : -1,
              fadeUpPos:
                clip.crossfade.fadeUpPos >= 0 ? clip.crossfade.fadeUpPos : -1,
              introPos:
                clip.crossfade.introPos >= 0 ? clip.crossfade.introPos : -1,
              outroPos:
                clip.crossfade.outroPos >= 0 ? clip.crossfade.outroPos : -1,
            };

            newClip = TuneDelegate.normalizeCrossfadeParameters(newClip);

            newClip.galaxyAssetId = clip.galaxyAssetId;
            return newClip;
          })
        : [];

    tuneResponse.clips = new MultiTrackList(tuneResponse.channelId, clipArr);

    tuneResponse.cuts = TuneDelegate.normalizeAdditionalChannelCuts(
      tuneResponse.clips.toArray(),
      relativeUrls,
    );

    return tuneResponse;
  }

  /**
   * Used to normalize the video marker. API sends <videoMarker>.markers and normalizes to <videoMarker>
   * and including normalizes legacyIds into legacyId , time to times.
   * @param mediaData is returned from API and media data can be AOD or live.
   * @returns {any} - returns video marker list.
   */
  public static normalizeVideoMarker(
    mediaData: any,
    layer: string,
    processVideoMarkers: boolean,
  ): any {
    if (
      MediaUtil.isAudioMediaTypeLive(mediaData.mediaType) &&
      !processVideoMarkers
    ) {
      return [];
    }

    let videoMarkers = getMarkers(mediaData.markerLists, layer);

    let normalizedVideoMarkers = _.cloneDeep(videoMarkers);
    normalizedVideoMarkers = normalizedVideoMarkers.map((marker, index) => {
      if (marker.video) {
        marker.video['zeroStartTime'] = parseInt(
          marker.video['zeroStartTime'],
          10,
        );
        marker.video['liveVideoStatus'] = marker.video[
          'liveVideoStatus'
        ].toLowerCase();
      }
      // Attempt to fix the missing "duration" and incorrect "time" field from the markers.
      // TODO: BMR: 04/10/2018: Consider removing if the API does return these values correctly.
      if (marker.timestamp && !marker.duration && index < videoMarkers.length) {
        let nextMarker =
          index < videoMarkers.length - 1 ? videoMarkers[index + 1] : null;
        let nextTime = nextMarker
          ? DateUtil.convertIsoToTimeFromEpoch(nextMarker.timestamp.absolute)
          : NaN;
        let currentTime = DateUtil.convertIsoToTimeFromEpoch(
          marker.timestamp.absolute,
        );
        marker.time = currentTime;
        marker.duration = nextMarker
          ? (nextTime - currentTime) / 1000
          : undefined;
      }

      return marker;
    });

    return TuneDelegate.normalizeMarkers(
      'video',
      cleanupVideoMarker,
      normalizedVideoMarkers,
    );

    /**
     * Function that will perform final cleanup on the video markers.  This is used for functionality that
     * is specific to the marker type being processed and which cannot be done in the general normalizeMarkers
     * function
     *
     * @param {IVideoMarker} videoMarker is the show marker to cleanup
     * @returns {IVideoMarker} cleaned up episode marker will be returned
     */
    function cleanupVideoMarker(videoMarker: IMediaVideo): IVideoMarker {
      if (
        (videoMarker as any).zeroStartTime &&
        typeof (videoMarker as any).zeroStartTime === 'string'
      ) {
        videoMarker.zeroStartTime = parseInt(
          (videoMarker as any).zeroStartTime,
          10,
        );
      }
      if (videoMarker.liveVideoStatus) {
        videoMarker.liveVideoStatus = videoMarker.liveVideoStatus.toLowerCase();
      }

      delete ((videoMarker as any) as IVideoMarker).video;
      return (videoMarker as any) as IVideoMarker;
    }
  }

  /**
   * Used to make API call from AOD and Live channel and do the normalization and send it back to service.
   * @param endPoint - service end point
   * @param responseType - used to get the aod or live channel data from response.
   * @param config - configuration send to API.
   * @returns {Observable<TuneResponse>}- an observable that can be subscribed to to get the
   * results of the API call.
   */
  private tune(
    endPoint: string,
    responseType: string,
    config: IHttpRequestConfig,
    setModelOnFailure: boolean = true,
  ): Observable<TuneResponse> {
    this.tuneModel.tuneState = TuneState.TUNING;

    return this.refreshTracksModel.refreshTracksModel$.pipe(
      filter(refreshTracksModel => {
        return refreshTracksModel.refreshState === 'IDLE';
      }),
      take(1),
      flatMap(() => {
        if (!this.outStandingRequest) {
          return this.processOutStandingRequest(
            endPoint,
            config,
            responseType,
            setModelOnFailure,
          ) as Observable<TuneResponse>;
        } else if (
          !config.params ||
          config.params.hls_output_mode !== TuneDelegate.HLS_OUTPUT_MODE_UPDATE
        ) {
          return this.queueOutStandingRequest(
            endPoint,
            config,
            responseType,
          ) as Observable<TuneResponse>;
        }
        // Otherwise, we have a live channel refresh, which we will cancel so that we don't replace a new request
        // with a refresh for an old live channel
        else {
          return observableOf(null);
        }
      }),
      share(),
    );
  }

  /**
   * Check for a request that was queued since the last outstanding request was made.  If a request was queued
   * then create a new outstanding request for the queued request and throw an error indicating that the
   * outstanding request was canceled and a new request is outstanding.
   * @param response from the API for the previous outstanding request
   * @param config from the previous outstanding request
   */
  private checkForQueuedRequests(
    response: any,
    config: any,
  ): Observable<TuneResponse> {
    if (this.queuedRequest) {
      const queuedRequest = this.queuedRequest;
      this.queuedRequest = null;

      TuneDelegate.logger.debug(`Replacing outstanding request for
                                          ${config.params.channelId}
                                          with ${queuedRequest.config.params.channelId}`);

      const outStandingRequest = this.tune(
        queuedRequest.endPoint,
        queuedRequest.responseType,
        queuedRequest.config,
      );

      outStandingRequest.subscribe((tuneResponse: TuneResponse) => {
        queuedRequest.subject.next(tuneResponse);
      });

      return queuedRequest.subject;
    }

    return observableOf(response);
  }

  /**
   * If there is not an outstanding request, the record one and return that to the caller
   * @param endPoint
   * @param config
   * @param responseType
   * @param setModelOnFailure
   */
  private processOutStandingRequest(
    endPoint,
    config,
    responseType,
    setModelOnFailure,
  ): Observable<TuneResponse> {
    // If User connected to Casting and AIC then we need to get response from Receiver otherwise from API.
    let call = this.http.get(endPoint, null, config);

    if (
      this.chromecastModel.playerType === PlayerTypes.REMOTE &&
      endPoint === ServiceEndpointConstants.endpoints.ADDITIONAL_CHANNELS.V4_AIC
    ) {
      call = this.tuneChromecastService.tuneAdditionalChannels(
        config.params.channelGuid,
      );
    }
    if (
      this.chromecastModel.playerType === PlayerTypes.REMOTE &&
      endPoint === ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_TUNE
    ) {
      call = this.tuneChromecastService.tuneSeededRadio(config.params.sourceId);
    }

    this.outStandingRequest = call.pipe(
      flatMap(response => {
        this.outStandingRequest = null;
        return this.checkForQueuedRequests(response, config);
      }),
      filter(response => !!response),
      map(response => {
        const tuneResponse = this.handleResponse(
          response,
          endPoint,
          responseType,
        );
        this.tuneModel.tuneModelData = {
          tuneState: TuneState.IDLE,
          tuneStatus: TuneStatus.SUCCESS,
          currentMetaData: {
            mediaType: tuneResponse.mediaType,
            mediaId: tuneResponse.mediaId,
            channelId: tuneResponse.channel
              ? tuneResponse.channel.channelId
              : tuneResponse.channelId,
          },
        };
        return tuneResponse;
      }),
      catchError((error: any) => {
        this.outStandingRequest = null;
        if (setModelOnFailure) {
          this.tuneModel.tuneModelData = {
            tuneState: TuneState.IDLE,
            tuneStatus: TuneStatus.FAILURE,
            currentMetaData: this.tuneModel.currentMetaData,
          };
        }
        throw error;
      }),
      share(),
    ) as Observable<TuneResponse>;
    return this.outStandingRequest;
  }

  /**
   * If there is an outstanding request, and the new request it not a live channel refresh, the queue it up.
   * @param endPoint
   * @param config
   * @param responseType
   */
  private queueOutStandingRequest(
    endPoint,
    config,
    responseType,
  ): Observable<TuneResponse> {
    // If we had a previously queued request, then cancel it and notify the caller it was canceled with a
    // null response.
    if (this.queuedRequest) {
      TuneDelegate.logger.debug(
        `Canceling queued request for ${this.queuedRequest.config.params.channelId}`,
      );
      this.queuedRequest.subject.next(null);
    }

    TuneDelegate.logger.debug(
      `Queueing request for ${config.params.channelId}`,
    );

    this.queuedRequest = {
      endPoint: endPoint,
      responseType: responseType,
      config: config,
      subject: new BehaviorSubject<TuneResponse>(undefined),
    };

    return this.queuedRequest.subject.pipe(
      filter((tuneResponse: TuneResponse) => tuneResponse !== undefined),
    );
  }

  /**
   * Take care of a API response for the now playing request
   * @param response from the API for the previous outstanding request
   * @returns {TuneResponse} either the results from normalizing the API response, or an exception if the
   *     endpoint for the response is not one of the endpoint we are prepared to handle
   */
  private handleResponse(
    response: any,
    endPoint: string,
    responseType: string,
  ): TuneResponse {
    if (!response || !response.moduleType) {
      return response;
    }

    let mediaData = _.get(response, responseType) as any;
    mediaData.mediaType = response.moduleType.toLowerCase();
    mediaData.updateFrequency = response.updateFrequency;
    mediaData.wallClockRenderTime = response.wallClockRenderTime;

    switch (endPoint) {
      case ServiceEndpointConstants.endpoints.NOW_PLAYING.V2_AOD:
      case ServiceEndpointConstants.endpoints.NOW_PLAYING.V4_VOD:
      case ServiceEndpointConstants.endpoints.NOW_PLAYING.V2_LIVE:
      case ServiceEndpointConstants.endpoints.NOW_PLAYING.V4_LIVE:
      case ServiceEndpointConstants.endpoints.ADDITIONAL_CHANNELS.V4_AIC:
      case ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_TUNE:
      case ServiceEndpointConstants.endpoints.NOW_PLAYING.V4_PANDORA_PODCAST:
        const urlMaps: Array<IRelativeUrlSetting> = this.configService.getRelativeUrlSettings();
        const seededRadioBackgroundUrl = this.configService.getSeededRadioBackgroundUrl();
        return TuneDelegate.normalizeMediaData(
          mediaData,
          this.configService.liveVideoEnabled(),
          urlMaps,
          seededRadioBackgroundUrl,
        );
      default:
        throw { message: `Unknown endpoint type ${endPoint}` };
    }
  }

  /**
   * Return normalize MediaEndpoing method for AOD, VOD and Podcast
   * @param mediaType
   */
  private static normalizeMediaEndPoints(mediaType: string) {
    switch (mediaType) {
      case ContentTypes.VOD:
        return TuneDelegate.normalizeVideoEndPoints;
        break;
      case ContentTypes.PODCAST:
        return TuneDelegate.normalizeIrisPodcastEndPoints;
        break;
      default:
        return TuneDelegate.normalizeAudioEndPoints;
    }
  }

  /**
   * Used to normalize Iris podcast end point data provided from the API to a common format
   * API send mediaData.AudioInfos and normalizes into mediaEndPoints.
   * @param mediaData is returned from API and media data for podcast.
   * @returns list of endpoints that can be used to play audio
   */
  private static normalizeIrisPodcastEndPoints(mediaData: any): any {
    if (!mediaData.audioInfo) {
      return TuneDelegate.lastValidMediaEndpoints || [];
    }

    const position: IPosition = {
      isoTimestamp: '',
      positionType: '',
      zuluTimestamp: 0,
    };

    const audioInfo = {
      name: 'primary',
      size: 'small',
      url: mediaData.audioInfo.providerAudioUrl,
      position: position,
      type: mediaData.audioInfo.providerAudioEncoding,
      mediaFirstChunks: [],
      manifestFiles: [],
    };

    return [audioInfo];
  }

  /**
   * Normalize the Iris Podcast channel
   * @param mediaData
   * @param relativeUrls
   */
  private static normalizeIrisPodcastChannel(
    mediaData: any,
    relativeUrls: Array<IRelativeUrlSetting> = [],
  ): IChannel {
    return {
      imageList: [
        {
          name: NAME_COLOR_CHANNEL_LOGO,
          platform: PLATFORM_ANY,
          url: relativeUrlToAbsoluteUrl(
            mediaData.episode.icon.artUrl,
            relativeUrls,
          ),
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        },
      ],
      name: mediaData.show.sortableName,
      dmcaInfo: null,
      channelId: '',
      channelGuid: '',
      stationFactory: '',
    } as IChannel;
  }

  /**
   * Normalizes the additional channel data.
   * @param {TuneResponse} tuneResponse
   * @param aicImageDomain
   * @returns {TuneResponse}
   */
  public static normalizeIrisPodcastChannelCuts(
    episode: any,
    show: any,
    relativeUrls: Array<IRelativeUrlSetting> = [],
  ): IMediaCut[] {
    let newCut: IMediaCut = {} as IMediaCut;
    newCut.album = {
      title: show.name,
      creativeArts: [],
    };

    newCut.artists = [
      {
        name: episode.name,
      },
    ];

    newCut.title = show.programName;

    newCut.cutContentType = ApiLayerTypes.PODCAST; // TODO Vpaindla what need to do?
    newCut.contentType =
      episode.category &&
      episode.category.toLowerCase() === ApiLayerTypes.TRACK_CONTENT_TYPE_MUSIC
        ? ApiLayerTypes.CUT_CONTENT_TYPE_SONG
        : ApiLayerTypes.CUT_CONTENT_TYPE_EXT;
    newCut.consumptionInfo = episode.consumptionInfo;
    newCut.duration = episode.duration;
    newCut.legacyId = episode.pandoraId;
    newCut.assetGUID = episode.pandoraId;
    newCut.affinity = '';
    newCut.times = {
      zuluStartTime: 0,
      isoStartTime: null,
      zuluEndTime: episode.duration,
    } as ITime;
    newCut.galaxyAssetId = episode.podcastId;
    newCut.stationFactory = '';

    return [newCut];
  }

  /**
   * Return normalized iris podcast episodes
   */

  private static normalizedIrisPodcastEpisodes(
    mediaData: any,
  ): IMediaEpisode[] {
    const zuluStartTime: number = 0;
    const zuluEndTime: number = 1000 * mediaData.episode.duration;

    const show: IMediaShow = {
      assetGUID: mediaData.show.pandoraId,
      aodEpisodeCount: mediaData.show.episodeCount,
      isPlaceholderShow: false,
      isLiveVideoEligible: false,
      hideFromChannelList: true,
      isPodcast: false,
      isIrisPodcast: true,
      longTitle: mediaData.show.name,
      mediumTitle: mediaData.show.sortableName,
    } as IMediaShow;

    const episode: IMediaEpisode = {
      allowDownload: false,
      episodeGUID: mediaData.episode.pandoraId,
      assetGUID: mediaData.episode.pandoraId,
      show: show,
      originalSegments: [],
      duration: mediaData.episode.duration,
      title: mediaData.episode.name,
      longTitle: mediaData.show.programName,
      mediumTitle: mediaData.episode.sortableName,
      times: {
        zuluStartTime: zuluStartTime,
        zuluEndTime: zuluEndTime,
        isoStartTime: moment(zuluStartTime).toISOString(),
        isoEndTime: moment(zuluEndTime).toISOString(),
      },
      dmcaInfo: {
        irNavClass: IrNavClassConstants.UNRESTRICTED_0, // using dummy value to construct the DMCA info
        maxBackSkips: 10,
        maxFwdSkips: 10,
        maxSkipDur: 3600000,
        maxTotalSkips: 20,
      } as IDmcaInfo,
    } as IMediaEpisode;

    return [episode];
  }

  /**
   * Normalize the Seeded channel
   * @param channel
   * @param relativeUrls
   * @param seededRadioBackgroundUrl
   */
  private static normalizeSeededChannel(
    channel: any,
    relativeUrls: Array<IRelativeUrlSetting> = [],
    seededRadioBackgroundUrl: string,
  ): IChannel {
    return {
      imageList: [
        {
          name: NAME_COLOR_CHANNEL_LOGO,
          platform: PLATFORM_ANY,
          url: relativeUrlToAbsoluteUrl(channel.channelImageUrl, relativeUrls),
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        },
        {
          name: NAME_BACKGROUND,
          platform: PLATFORM_WEBEVEREST,
          url: relativeUrlToAbsoluteUrl(seededRadioBackgroundUrl, relativeUrls),
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        },
      ],
      name: channel.stationName,
      dmcaInfo: channel.dmcaInfo,
      channelId: channel.stationFactory,
      channelGuid: channel.stationFactory,
      stationFactory: channel.stationFactory,
    } as IChannel;
  }

  /**
   * Api sends affinity vale as 0, -1 and 1 . This method converts api value to AffinityType
   * @param clip
   */
  private static getAffinity(clip: IClip): AffinityType {
    let affinity = _.get(clip, 'affinity', 0);
    switch (affinity) {
      case AffinityConstMapping.NEUTRAL:
        affinity = AffinityConstants.NEUTRAL as AffinityType;
        break;
      case AffinityConstMapping.LIKE:
        affinity = AffinityConstants.LIKE as AffinityType;
        break;
      case AffinityConstMapping.DISLIKE:
        affinity = AffinityConstants.DISLIKE as AffinityType;
        break;
      default:
        affinity = '' as AffinityType;
        break;
    }
    return affinity;
  }

  /**
   * Used to normalize the video endpoint data provided from the API to a common format
   * @param mediaData is returned from API and media data can be VOD or live video.
   * @returns list of endpoints that can be used to play video
   */
  private static normalizeLiveVideoEndPoints(mediaData: any): any {
    if (!mediaData.videoStreamUrlInfos) {
      return [];
    }
    return mediaData.videoStreamUrlInfos.map((video: any) => {
      return TuneDelegate.createVideoEndPoint(video.url);
    });
  }

  /**
   * Used to normalize the video endpoint data provided from the API to a common format
   * @param mediaData is returned from API and media data can be VOD or live video.
   * @returns list of endpoints that can be used to play video
   */
  private static normalizeVideoEndPoints(mediaData: any): any {
    if (!mediaData.videoStreamUrlInfos) {
      return [];
    }

    return mediaData.videoStreamUrlInfos.map((video: any) => {
      const isoStartTime = video.timestamp
        ? video.timestamp.replace(
            /(.*)([+-]{1})([0-9]{2})([0-9]{2})/g,
            '$1$2$3:$4',
          )
        : '';

      let videoEndPoint = TuneDelegate.createVideoEndPoint(
        video.url,
        video.format,
      );

      videoEndPoint.position = {
        isoTimestamp: isoStartTime,
        zuluTimestamp: isoStartTime ? new Date(isoStartTime).getTime() : 0,
        positionType: undefined,
      };
      return videoEndPoint;
    });
  }

  /**
   * Used to create a video endpoint data provided- video url and foramt
   */
  private static createVideoEndPoint(url: string, format?: string) {
    return {
      name: format || MediaPlayerConstants.HLS,
      url: url,
      size: MediaPlayerConstants.PLAYLIST_SIZE_LARGE,
      position: {} as any,
      mediaFirstChunks: undefined,
      manifestFiles: [
        {
          name: MediaPlayerConstants.HLS,
          size: MediaPlayerConstants.PLAYLIST_SIZE_LARGE,
          url: url,
        },
      ],
    };
  }

  /**
   * Used to normalize audio end point data provided from the API to a common format
   * API send mediaData.customAudioInfos and normalizes into mediaEndPoints.
   * @param mediaData is returned from API and media data can be AOD or live audio.
   * @returns list of endpoints that can be used to play audio
   */
  private static normalizeAudioEndPoints(mediaData: any): any {
    if (!mediaData.customAudioInfos) {
      return TuneDelegate.lastValidMediaEndpoints || [];
    }

    const position: IPosition = {
      isoTimestamp: '',
      positionType: '',
      zuluTimestamp: 0,
    };

    return mediaData.customAudioInfos.map((audio: any) => {
      if (audio.position) {
        position.isoTimestamp = audio.position.timestamp;
        position.positionType = audio.position.position;
        position.zuluTimestamp = position.isoTimestamp
          ? moment(position.isoTimestamp).valueOf()
          : 0;

        if (
          mediaData.mediaType === ContentTypes.LIVE_AUDIO &&
          position.zuluTimestamp !== 0
        ) {
          if ((Date.now() - position.zuluTimestamp) / (1000 * 3600) > 5) {
            audio.chunks = null;
          }
        }
      }

      const audioInfo = {
        name: audio.name,
        size: 'LARGE', //audio.size,
        url: audio.url.replace('_v3.', '_v2.').replace('_small_', '_large_'),
        position: position,
        mediaFirstChunks: TuneDelegate.normalizeChunks(
          !!audio.chunks && !!audio.chunks.chunks ? audio.chunks.chunks : [],
        ) as Array<IChunk>,
        manifestFiles: _.filter(mediaData.hlsAudioInfos, {
          name: audio.name,
        }) as Array<IHlsManifestFile>,
      };

      if (
        audioInfo.mediaFirstChunks.length > 0 &&
        audioInfo.mediaFirstChunks[0].url === ''
      ) {
        audioInfo.mediaFirstChunks = [];
      }

      return audioInfo;
    });
  }

  /**
   * used to normalize chunks to this.
   * @param chunks
   * @returns {any}
   *
   */
  private static normalizeChunks(chunks): IChunk[] {
    return chunks.map(chunk => {
      chunk.interChunkOffset = chunk.offset;
      chunk.isoTimestamp = chunk.timestamp;
      chunk.zuluTimestamp = new Date(chunk.isoTimestamp).getTime();

      // TODO when latest greenfield audio player is built into app, get rid of lines from here to next
      // TODO

      const encrypted = chunk.url.indexOf('k_0_') < 0;

      if (!chunk.keyUrl) {
        // if chunk.keyUrl is an empty string or null or undefined make it undefined

        // if chunk.keyUrl is an empty string or null or undefined ...
        //   populate it if chunk is encrypted.
        //   make it undefined if chunk is not encrypted.
        //
        // empty chunk keyUrls can cause problems with audio players.
        //
        // Making an HTTP request to an empty string can result in a call to the endpoint the app was launched
        // from.  If the response to that comes back with data then a media player may try to decrypt the audio
        // with that data and this will NEVER work.  Endpoint to get keys from should ALWAYS be explicitly
        // defined.
        if (encrypted) {
          const chunkName = chunk.url.slice(0, chunk.url.indexOf('_v3') + 3);
          chunk.keyUrl = chunkName + '/key/1';
        } else {
          delete chunk.keyUrl;
          delete chunk.key;
        }
      }

      if (!chunk.key && encrypted) {
        chunk.key = atob('ME5zY283TUFneG93R3ZrVVQ4YVlhZz09'); // this will only work for live
      }

      // TODO when latest greenfield audio player is built into app, get rid of lines from here to above
      // TODO

      delete chunk.offset;
      delete chunk.timestamp;
      return chunk;
    });
  }

  /**
   * Used to normalize all marker types in a generic fashion.  Any work that is specific to the marker type should
   * be encapsulated in the cleanupFunction function which will be run on each marker once the generic normalization
   * has been performed
   *
   * @param mediaType is returned from API and is media type to which the markers apply
   * @param property is the property name in the marker interface that should be flattened into each marker
   * @param cleanupFunction is a function that is applied to every marker for extra processing
   * @param markers is the marker list to normalize
   * @returns {any} - normalized marker list
   */
  private static normalizeMarkers(
    property: string,
    cleanupFunction: Function,
    markers: Array<IMediaItem>,
    isLive: boolean = false,
  ) {
    return markers.map((marker: IMediaItem, index: number) => {
      const previousMarker = index > 0 ? markers[index - 1] : undefined;

      // Flatten the marker so that the properties contained within the named property are copied up to the
      // marker itself.  This means the client has less de-referencing to do
      if (marker.hasOwnProperty(property) && marker[property] !== undefined) {
        Object.keys(marker[property]).forEach((propertyName: string) => {
          marker[propertyName] = marker[property][propertyName];
        });

        // We are getting the last element in MarkerLists/Markers/Cut array with duration which is causing the issues
        // in consumestreamdatetime of Everest Royalty. API shouldn't be sending us the duration for the last element.
        // If we happen to see duration for the last element in cut array then we have to remove it to fix this issue.
        if (
          property === TuneDelegate.MARKER_PROPERTY_CUT &&
          isLive &&
          markers.length > 0 &&
          markers[markers.length - 1][TuneDelegate.MARKER_PROPERTY_DURATION] > 0
        ) {
          markers[markers.length - 1][
            TuneDelegate.MARKER_PROPERTY_DURATION
          ] = 0;
        }
      }

      marker = TuneDelegate.normalizeTime(marker, previousMarker);
      marker = TuneDelegate.normalizeLegacyIds(marker);
      marker = cleanupFunction(marker);

      return marker;
    });
  }

  /**
   * Used to normalize the cut marker. API sends <cutMarker>.markers and normalizes to <cutMarker>
   * and including normalizes legacyIds into legacyId , time to times.
   * @param mediaData is returned from API and media data can be AOD or live.
   * @returns {any} - returns cut marker list.
   */
  private static normalizeCutMarker(mediaData: any): any {
    let cutMarkers = getMarkers(mediaData.markerLists, ApiLayerTypes.CUT_LAYER);
    const isLive: boolean = mediaData.mediaType === ContentTypes.LIVE_AUDIO;

    return TuneDelegate.normalizeMarkers(
      'cut',
      cleanupCutMarker,
      cutMarkers,
      isLive,
    );

    /**
     * Function that will perform final cleanup on the cut markers.  This is used for functionality that
     * is specific to the marker type being processed and which cannot be done in the general normalizeMarkers
     * function
     *
     * @param {ICutMarker} cutMarker is the cut marker to cleanup
     * @returns {ICutMarker} cleaned up cut marker will be returned
     */
    function cleanupCutMarker(cutMarker: ICutMarker): ICutMarker {
      cutMarker = TuneDelegate.normalizeCutContentType(cutMarker);

      delete cutMarker.cut;
      delete cutMarker.containerGUID;
      delete cutMarker.layer;
      return cutMarker;
    }
  }

  /**
   * Used to normalize the segment marker. API sends <segmentMarker>.markers and normalizes to <segmentMarker>
   * and including normalizes legacyIds into legacyId , time to times.
   * @param mediaData is returned from API and media data can be AOD or live.
   * @returns {any} - segment marker list.
   */
  private static normalizeSegmentMarker(mediaData: any): any {
    let segmentMarkers = getMarkers(
      mediaData.markerLists,
      ApiLayerTypes.SEGMENT_LAYER,
    );

    /**
     * For VOD, the time property will always be zero.  For AOD and live the time property reflects the
     * timestamp.absolute property in unix time format.  The following code will ensure that time is correctly
     * set from timestamp.absolute so that we can use the time property to sort the segments BEFORE they get
     * normalized.
     */
    segmentMarkers.forEach((segmentMarker: ISegmentMarker) => {
      //X1 was returning NaN for new Date(segmentMarker.timestamp.absolute).getTime(). Replacing with moment instead
      segmentMarker.time = moment(segmentMarker.timestamp.absolute).valueOf();
    });

    // Sometimes API delivers segment markers out of time order.  This will take care of that
    segmentMarkers = _.orderBy(segmentMarkers, 'time', ['asc']);

    segmentMarkers = TuneDelegate.normalizeMarkers(
      'segment',
      cleanupSegmentMarker,
      segmentMarkers,
    );

    return segmentMarkers;

    /**
     * Function that will perform final cleanup on the segment markers.  This is used for functionality that
     * is specific to the marker type being processed and which cannot be done in the general normalizeMarkers
     * function
     *
     * @param {ISegmentMarker} segmentMarker is the segment marker to cleanup
     * @returns {ISegmentMarker} cleaned up segment marker will be returned
     */
    function cleanupSegmentMarker(
      segmentMarker: ISegmentMarker,
    ): ISegmentMarker {
      segmentMarker.type = segmentMarker.segmentType;

      delete segmentMarker.segment;
      delete segmentMarker.segmentType;
      delete segmentMarker.containerGUID;
      delete segmentMarker.layer;
      return segmentMarker;
    }
  }

  /**
   * Used to normalize the show marker. API sends <showMarker>.markers and normalizes to <showMarker> and including
   * normalizes legacyIds into legacyId , time to times.
   * @param mediaData is returned from API and media data can be AOD or live.
   * @returns {any} - marker
   */
  private static normalizeShowMarker(mediaData: any): any {
    let showMarkers = getMarkers(
      mediaData.markerLists,
      ApiLayerTypes.SHOW_LAYER,
    );

    return TuneDelegate.normalizeMarkers(
      'show',
      cleanupShowMarker,
      showMarkers,
    );

    /**
     * Function that will perform final cleanup on the show markers.  This is used for functionality that
     * is specific to the marker type being processed and which cannot be done in the general normalizeMarkers
     * function
     *
     * @param {IShowMarker} showMarker is the show marker to cleanup
     * @returns {IShowMarker} cleaned up show marker will be returned
     */
    function cleanupShowMarker(showMarker: IShowMarker): IShowMarker {
      delete showMarker.show;
      delete showMarker.layer;
      delete showMarker.containerGUID;
      return showMarker;
    }
  }

  /**
   * Used to normalize the aodEpisode marker. API sends <episodeMarker>.markers and normalizes to <episodeMarker>
   * and including normalizes legacyIds into legacyId , time to times and show.
   * @param  mediaData is returned from API and media data can be AOD or live.
   * @param segmentMarkers is sorted and normalized segments.  Segments that belong to the episode will be added
   * @param cutMarkers is the normalized cuts.  Cuts that belong to the episode will be added
   * @param layer is the layer for the episode markers to be normalized
   * @returns {any} - returns aodEpisode marker list.
   */
  private static normalizeEpisodeMarker(
    mediaData: any,
    segmentMarkers: Array<IMediaSegment>,
    cutMarkers: Array<IMediaCut>,
    videoMarkers: Array<IMediaVideo>,
    layer: string,
  ): any {
    const episodeMarkers = getMarkers(mediaData.markerLists, layer) as Array<
      IEpisodeMarker
    >;

    return TuneDelegate.normalizeMarkers(
      'episode',
      cleanupEpisodeMarker,
      episodeMarkers,
    );

    /**
     * Function that will perform final cleanup on the episode markers.  This is used for functionality that
     * is specific to the marker type being processed and which cannot be done in the general normalizeMarkers
     * function
     *
     * This function will collect cuts and segments that belong to the episode and populate the cuts and
     * segments properties on the episode with those markers.  This makes it convenient for the client when
     * the segments and cuts for whichever episode is currently playing are needed.
     *
     * @param {IEpisodeMarker} episodeMarker is the show marker to cleanup
     * @returns {IEpisodeMarker} cleaned up episode marker will be returned
     */
    function cleanupEpisodeMarker(
      episodeMarker: IEpisodeMarker | any,
    ): IEpisodeMarker {
      let episode = TuneDelegate.normalizeShow(
        episodeMarker,
        mediaData.channelId,
      ) as IMediaEpisode;

      episode.originalIsoAirDate = episodeMarker.episode.originalAirDate;

      // TODO : If we have no dmca from the API, we will make the episode unrestricted
      if (!episode.dmcaInfo) {
        episode.dmcaInfo = {
          irNavClass: IrNavClassConstants.PATTERN_UNRESTRICTED,
        } as IDmcaInfo;
      }

      const startCut = findMediaItemByTimestamp(
        episode.times.zuluStartTime,
        cutMarkers,
      ) as ICutMarker;
      const endCut = findMediaItemByTimestamp(
        episode.times.zuluEndTime - 1,
        cutMarkers,
      ) as ICutMarker;
      const startSegment = findMediaItemByTimestamp(
        episode.times.zuluStartTime,
        segmentMarkers,
      ) as ISegmentMarker;
      const endSegment = findMediaItemByTimestamp(
        episode.times.zuluEndTime - 1,
        segmentMarkers,
      ) as ISegmentMarker;

      const startVideoMarker = findMediaItemByTimestamp(
        episode.times.zuluStartTime,
        videoMarkers,
      ) as IMediaVideo;
      const endVideoMarker = findMediaItemByTimestamp(
        episode.times.zuluEndTime - 1,
        videoMarkers,
      ) as IMediaVideo;

      // Find the cuts and segments that belong to this episode, add them to the episode
      episode.cuts = cutMarkers.slice(
        _.findIndex(cutMarkers, startCut),
        _.findIndex(cutMarkers, endCut) + 1,
      ) as Array<IMediaCut>;
      episode.segments = segmentMarkers.slice(
        _.findIndex(segmentMarkers, startSegment),
        _.findIndex(segmentMarkers, endSegment) + 1,
      ) as Array<IMediaSegment>;
      episode.originalSegments = episode.segments;

      episode.videos = videoMarkers.slice(
        _.findIndex(videoMarkers, startVideoMarker),
        _.findIndex(videoMarkers, endVideoMarker) + 1,
      ) as Array<IMediaVideo>;

      episode = normalizeEpisodeSegments(episode);

      episode.images =
        episodeMarker.images && episodeMarker.images.images
          ? episodeMarker.images.images
          : [];
      delete episodeMarker.episode;
      delete episodeMarker.dataSiftStreamId;
      delete episodeMarker.episodeGUID;
      delete episodeMarker.layer;
      delete episodeMarker.originalAirDate;

      return episodeMarker;
    }
  }

  /**
   * Takes an array of episodeMarkers,
   *  1. Finds the markers that come from placholder shows
   *  2. Breaks the placeholder episode marker into smaller hourly markers.
   *  3. Replaces the original placeholder marker with the new fake hourly placeholders
   * @param { Array<IMediaEpisode> } episodeMarkers an array of episode markers to modify
   * @returns {Array<IMediaEpisode>} returns a new Array of episodeMarkers
   **/
  private static normalizePlaceholderEpisodeMarker(
    episodeMarkers: Array<IMediaEpisode>,
  ): Array<IMediaEpisode> {
    if (!episodeMarkers) {
      return [];
    }

    let newEpisodeMarkers = episodeMarkers;

    newEpisodeMarkers
      .filter(episodeMarker => episodeMarker.show.isPlaceholderShow)
      .forEach(episodeMarker => {
        let index = newEpisodeMarkers.indexOf(episodeMarker);
        newEpisodeMarkers.splice(
          index,
          1,
          ...this.generateFakeEpisodeMarkers(episodeMarker),
        );
      });

    return newEpisodeMarkers;
  }

  /**
   * Expects an episode marker for a placeholder show.
   * Breaks that down into an array of fake episode markers.
   * @param {IMediaEpisode} episodeMarker takes a single episode marker.
   **/
  private static generateFakeEpisodeMarkers(
    episodeMarker: IMediaEpisode,
  ): Array<IEpisodeMarker> {
    const MILLISECONDS_IN_1_HOUR = 3600000;

    let fakeStartTimes = _.range(
      episodeMarker.times.zuluStartTime,
      episodeMarker.times.zuluEndTime,
      MILLISECONDS_IN_1_HOUR,
    );

    return fakeStartTimes.map((zuluStartTime, i) => {
      let clone = _.clone(episodeMarker);

      clone.times = _.clone(episodeMarker.times);
      clone.times.zuluEndTime =
        fakeStartTimes[i + 1] || episodeMarker.times.zuluEndTime;
      clone.times.zuluStartTime = zuluStartTime;

      clone.times.isoStartTime = moment(
        clone.times.zuluStartTime,
      ).toISOString();
      clone.times.isoEndTime = moment(clone.times.zuluEndTime).toISOString();

      clone.duration =
        (clone.times.zuluEndTime - clone.times.zuluStartTime) / 1000;

      return clone as IEpisodeMarker;
    });
  }

  /**
   * Create fake video markers to turn video on when an episode marker starts and off when the episode marker stops
   * @param mediaData is the media time line object to create the fake markers for
   **/
  private static generateFakeVideoMarkers(mediaData: any): any {
    let videoLayer = {
      layer: ApiLayerTypes.VIDEO_LAYER,
      markers: [],
    };

    const episodeMarkerList: IMarkerList = _.find(
      mediaData.markerLists,
      (markers: IMarkerList) => markers.layer === ApiLayerTypes.EPISODE_LAYER,
    );

    const episodeMarkers = _.get(episodeMarkerList, 'markers', []);
    const marker = episodeMarkers[0];

    let videoStartMarker = {
      zeroStartTime: 0,
      assetGUID: marker.assetGUID,
      timestamp: {
        absolute: marker.timestamp.absolute,
      },
      time: new Date(marker.timestamp.absolute).getTime(),
      layer: 'video',
      video: {
        airingType: ContentTypes.VOD,
        clientMessage: marker.episode.shortDescription,
        concurrentAudioCutGuid: null,
        description: marker.episode.shortDescription,
        episodeGUID: marker.episode.episodeGUID,
        vodEpisodeGUID: marker.episode.vodEpisodeGUID,
        liveVideoStatus: VideoPlayerConstants.LIVE_VIDEO_ON,
        showGuid: marker.episode.showGuid,
        title: marker.episode.longTitle,
        videoStreamTimestamp: marker.episode.originalAirDate,
        zeroStartTime: 0,
        duration: marker.duration,
      },
    };

    //endMarkerAbsoluteTime absolute time will be next second after the StartMarker ended
    // so adding a sec -- (marker.duration + 1)
    const endMarkerAbsoluteTime =
      new Date(
        DateUtil.convertIsoToTimeFromEpoch(videoStartMarker.timestamp.absolute),
      ).getTime() +
      (marker.duration + 1) * 1000;

    let videoEndMarker = {
      zeroStartTime: 0,
      assetGUID: marker.assetGUID,
      timestamp: {
        absolute: new Date(endMarkerAbsoluteTime).toISOString(),
      },
      time: endMarkerAbsoluteTime,
      layer: 'video',
      video: {
        airingType: ContentTypes.VOD,
        clientMessage: marker.episode.shortDescription,
        concurrentAudioCutGuid: null,
        description: marker.episode.shortDescription,
        episodeGUID: marker.episode.episodeGUID,
        vodEpisodeGUID: marker.episode.vodEpisodeGUID,
        liveVideoStatus: VideoPlayerConstants.LIVE_VIDEO_OFF,
        showGuid: marker.episode.showGuid,
        title: marker.episode.longTitle,
        videoStreamTimestamp: new Date(endMarkerAbsoluteTime).toISOString(),
        zeroStartTime: marker.duration,
        duration: 0,
      },
    };

    videoLayer.markers.push(videoStartMarker);
    videoLayer.markers.push(videoEndMarker);

    mediaData.markerLists.push(videoLayer);
    return mediaData;
  }

  /**
   * Used to normalize the show And assigns marker.show.
   * @param marker -it is show marker exists under marker list . marker list returned by API.
   * @param channelId is the channel fod the show
   * @returns {any} - returns marker
   */
  private static normalizeShow(marker: any, channelId: string): any {
    if (marker.show) {
      marker.show = {
        assetGUID: marker.show.guid ? marker.show.guid : marker.show.showGUID,
        aodEpisodeCount: marker.show.aodEpisodeCount,
        connectInfo: marker.show.connectInfo,
        disableRecommendations: marker.show.disableRecommendations,
        futureAirings: marker.show.futureAirings,
        legacyId: TuneDelegate.normalizeLegacyId(marker.show),
        longTitle: marker.show.longTitle,
        longDescription: marker.show.longDescription,
        mediumTitle: marker.show.mediumTitle,
        shortDescription: marker.show.shortDescription,
        images: marker.show.creativeArts,
        isPlaceholderShow: marker.show.isPlaceholderShow,
        isLiveVideoEligible: marker.show.isLiveVideoEligible,
        type: 'show',
        channelId: channelId,
        hideFromChannelList: marker.show.hideFromChannelList,
        isPodcast: marker.show.isPodcast ? marker.show.isPodcast : false,
      };
    }
    return marker;
  }

  /**
   * Used to normalize legacyId's to LegacyId.
   * @param marker - it can be any type under marker list . marker list returned by API.
   * @returns {ILegacyId} - returns legacyId object
   */
  private static normalizeLegacyId(marker: any): ILegacyId {
    if (!marker.legacyIds) {
      return {
        siriusXMId: '',
        pid: '',
        shortId: '',
      };
    }
    return {
      siriusXMId: marker.legacyIds.siriusXMId,
      pid: marker.legacyIds.pid,
      shortId: marker.legacyIds.shortId,
    };
  }

  /**
   * Used to normalize the legacyId.
   * API returns legacyIds and normalized to legacyId and assign to marker. After assign to marker deleted duplicate
   * properties under marker.
   * @param marker - it can be any type under marker list . marker list returned by API.
   * @returns {any} - marker
   */
  private static normalizeLegacyIds(marker: any): any {
    if (!marker.legacyIds) {
      return marker;
    }
    marker.legacyId = TuneDelegate.normalizeLegacyId(marker);

    delete marker.legacyIds;

    return marker;
  }

  /**
   * Method used to normalize the time.
   * API sends time, absolute and duration, This method normalize given properties into ITime Object and
   * also calculates the end time of the each marker and assign to marker. After assign to marker deleted duplicate
   * properties under marker
   * @param marker can be any type under marker list . marker list returned by API.
   * @param previousMarker is the marker before the marker, undefined if marker is the first marker
   * @returns {any} - marker
   */
  private static normalizeTime(marker: any, previousMarker: any): any {
    let zuluEndTime = undefined;
    let isoEndTime = '';
    let isoStartTime = '';
    let zeroStartTime = undefined;
    let zeroEndTime = undefined;

    if (typeof marker.zeroStartTime === 'number') {
      zeroStartTime = marker.zeroStartTime;
      zeroEndTime = marker.zeroStartTime + marker.duration;
    }

    if (marker.timestamp) {
      //NOTE: To support safari Zulu Offset to Hours:Minutes
      isoStartTime = marker.timestamp.absolute.replace(
        /(.*)([+-]{1})([0-9]{2})([0-9]{2})/g,
        '$1$2$3:$4',
      );
    }

    let zuluStartTime = new Date(isoStartTime).getTime();

    /**
     * Because the duration field is in seconds, but the zulu times are in milliseconds, we see gaps in markers
     * between the start time of the next marker and the end time of the previous marker.
     *
     * To combat this, we look at the previous marker end time and the current marker start time then adjust the
     * end time of the previous marker to end one millisecond before the current marker starts
     */
    if (previousMarker && previousMarker.times && !isNaN(zuluStartTime)) {
      previousMarker.times.zuluEndTime = zuluStartTime - 1; // make sure previous marker ends right before
      previousMarker.times.isoEndTime = new Date(
        previousMarker.times.zuluEndTime,
      ).toISOString();
    }

    if (marker.duration && marker.duration >= 0 && isoStartTime) {
      const durationMs = marker.duration * 1000;
      const durationInMs = marker.duration * 1000;
      const endTimeMs = Date.parse(isoStartTime) + durationMs;
      zuluEndTime = zuluStartTime + durationInMs;
      isoEndTime = new Date(zuluEndTime).toISOString();

      zuluStartTime = new Date(isoStartTime).getTime();
    }

    marker.times = {
      zuluStartTime: zuluStartTime,
      isoStartTime: isoStartTime,
      zuluEndTime: zuluEndTime,
      isoEndTime: isoEndTime,
      zeroStartTime: zeroStartTime,
      zeroEndTime: zeroEndTime,
    };

    delete marker.time;
    delete marker.timestamp;
    delete marker.zeroStartTime;

    return marker;
  }

  /**
   * We leave cutContentType untouched for debugging because we receive this from the API
   * cutContentType might be "Link" or "Song" or not exist -> undefined
   * contentType is always "song" or ext"
   * @param cutMarker
   */
  private static normalizeCutContentType(cutMarker: any): any {
    cutMarker.cutContentType = cutMarker.cutContentType; // has a side-effect of creating a property,
    // if the property does not exist,
    // and setting the property to undefined.
    if (
      cutMarker.cutContentType &&
      cutMarker.cutContentType.toLowerCase() ===
        ApiLayerTypes.CUT_CONTENT_TYPE_SONG
    ) {
      cutMarker.contentType = ApiLayerTypes.CUT_CONTENT_TYPE_SONG;
      return cutMarker;
    } else if (
      cutMarker.cutContentType &&
      cutMarker.cutContentType.toLowerCase() ===
        ApiLayerTypes.CUT_CONTENT_TYPE_TALK
    ) {
      cutMarker.contentType = ApiLayerTypes.CUT_CONTENT_TYPE_TALK;
      return cutMarker;
    } else if (
      cutMarker.cutContentType &&
      cutMarker.cutContentType.toLowerCase() ===
        ApiLayerTypes.CUT_CONTENT_TYPE_PROMO
    ) {
      cutMarker.contentType = ApiLayerTypes.CUT_CONTENT_TYPE_PROMO;
      return cutMarker;
    } else if (
      cutMarker.cutContentType &&
      cutMarker.cutContentType.toLowerCase() ===
        ApiLayerTypes.CUT_CONTENT_TYPE_EXP
    ) {
      cutMarker.contentType = ApiLayerTypes.CUT_CONTENT_TYPE_EXP;
      return cutMarker;
    }

    const pid =
      cutMarker.legacyId && cutMarker.legacyId.pid
        ? cutMarker.legacyId.pid
        : '';

    if (
      pid &&
      (pid.toUpperCase().indexOf('$O') === 0 ||
        pid.toUpperCase().indexOf('!C') === 0)
    ) {
      cutMarker.contentType = ApiLayerTypes.CUT_CONTENT_TYPE_SONG;
    } else {
      cutMarker.contentType = ApiLayerTypes.CUT_CONTENT_TYPE_EXT;
    }

    return cutMarker;
  }

  /**
   * Makes sure the crossfade parameters are not ridiculous
   * and change them if they are.
   * @param {IClip} clip
   */
  private static normalizeCrossfadeParameters(clip: IClip): IClip {
    if (typeof clip.duration !== 'number') {
      return clip;
    }

    if (clip.duration < 0) {
      clip.duration = Math.abs(clip.duration);
    }

    // crossFade
    const ratio = msToSeconds(clip.crossfade.crossFade) / clip.duration;
    if (ratio > 0.4) {
      clip.crossfade.crossFade = -1;
    }

    //fadeUpDuration
    if (msToSeconds(clip.crossfade.fadeUpDuration) > clip.duration) {
      clip.crossfade.fadeUpDuration = -1;
    }

    //fadeDownDuration
    if (msToSeconds(clip.crossfade.fadeDownDuration) > clip.duration) {
      clip.crossfade.fadeDownDuration = -1;
    }

    //fadeUpPos
    if (msToSeconds(clip.crossfade.fadeDownPos) > clip.duration) {
      clip.crossfade.fadeDownPos = -1;
    }

    //fadeDownPos
    if (msToSeconds(clip.crossfade.fadeUpPos) > clip.duration) {
      clip.crossfade.fadeUpPos = -1;
    }

    return clip;
  }
}
