import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ReactComponent as SiriusXMLogo } from '../../assets/images/sxm-logo-blue.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import { getServiceConfig } from '../../sxmservicelayer/service.config.utils';
import './open-access.component.scss';

export const OpenAccessConfirmationPageComponent = () => {
  const history = useHistory();

  const [appConfig, setAppConfig] = useState({} as any);
  useEffect(() => {
    setAppConfig(getServiceConfig());
  }, []);

  useEffect(() => {
    of(appRouteConstants.HOME_FORYOU)
      .pipe(delay(5000))
      .subscribe(route => {
        history.replace(route);
      });
  }, []);

  return (
    <div className="open-access-confirmation-page">
      <div className="open-access-confirmation-logo-container">
        <div>
          <SiriusXMLogo />
        </div>
      </div>
      <div className="open-access-confirmation-content-container">
        <div className="open-access-confirmation-text-container">
          <span className="title">Welcome to SiriusXM</span>
          <span className="message-enjoy">
            Enjoy the best radio, for the next 72 hours!
          </span>
          <span className="message-sign-up">
            or sign up for streaming at{' '}
            {appConfig &&
            appConfig.deviceInfo &&
            appConfig.deviceInfo.appRegion === 'US'
              ? 'siriusxm.com/tvtrial'
              : 'siriusxm.ca/tvtrial'}
          </span>
        </div>
      </div>
    </div>
  );
};
