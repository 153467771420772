import React from 'react';
import { useSelector } from 'react-redux';
import { getFreeTrialCounter } from '../../redux/selector/xdk.store';
import './free-trial-counter-component.scss';
import { storageManager } from '../../config/storage-manager';
import { StorageKeyConstant } from '../../servicelib';

const FreeTrialCounterComponent = props => {
  const timeRemaining = useSelector(getFreeTrialCounter);
  const partnerId = storageManager.cloudCookieManager.get(
    StorageKeyConstant.PARTNER_ID,
  );

  return (
    <div className="free-trial-counter-container">
      <div>Your preview expires in</div>
      <div className="free-trial-expiration-time">
        &nbsp;{timeRemaining}.&nbsp;
      </div>
      <div className="free-trial-subscription-url">
        Subscribe to SiriusXM, go to: SiriusXM.com/{partnerId || 'xclass'}
      </div>
    </div>
  );
};

export default FreeTrialCounterComponent;
