import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectViewAllCarousel } from '../../redux/selector/carousel.store';
import { ServiceFactory } from '../../servicelib';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { focusManager } from '@accedo/vdkweb-navigation';
import { Page } from '@accedo/vdkweb-tv-ui';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import { useHistory } from 'react-router';
import './view-all.component.scss';
import { VIEWALL } from '../../utils/navigationMap';
import { StickyNavbar } from '../../components/sticky-navbar';
import Grid from '../../components/grid/Grid';
import ChannelTile from '../../components/tiles/ChannelTile';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { verticalScroll } from '../../utils/pageUtils';

const { PAGE } = VIEWALL;

const pageNav = {
  id: PAGE,
};

export const ViewAllPage = props => {
  const { isCoreMenuOpen, setIsCoreMenuOpen, isOnFreeTrial } = props;

  const viewAllCarousel = useSelector(selectViewAllCarousel);
  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  const [navIds, setNavIds] = useState({});
  const [animation, setAnimation] = useState(true);
  const dispatch = useDispatch();
  const forwardFocus = useRef(null);

  useEffect(() => {
    setIsCoreMenuOpen(false);
    const carouselGuid = location.pathname.split('/').pop();
    carouselStoreService.selectViewAll(carouselGuid);
  }, []);

  /** Handles the BACK button navigation logic **/
  const history = useHistory();
  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    history.goBack();
  }, [backId]);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    } else {
      window.scroll(0, 0);
    }

    if (lastFocusedTile[pageNav.id]) {
      forwardFocus.current = lastFocusedTile[pageNav.id];
      focusManager.changeFocus(pageNav.id);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  useEffect(() => {
    let gridId = '';
    if (viewAllCarousel && viewAllCarousel.zone) {
      const ids = [];
      viewAllCarousel.zone.forEach(zone => {
        zone.content.forEach(contentCarousel => {
          gridId = contentCarousel.guid;
          ids.push(contentCarousel.guid);
        });
      });

      const navs = {};
      ids.forEach((id, index) => {
        navs[id] = {
          id: id,
          nextup: ids[index - 1] || undefined,
          nextdown: ids[index + 1],
        };
      });

      navs['page'] = {
        ...pageNav,
        forwardFocus: ids[0],
      };

      setNavIds(navs);
    }
    if (gridId && !lastFocusedTile[pageNav.id]) {
      focusManager.changeFocus(gridId);
    }
  }, [viewAllCarousel]);

  return (
    <Page className="view-all-page" nav={navIds['page']}>
      <StickyNavbar
        isCoreMenuOpen={isCoreMenuOpen}
        isOnFreeTrial={isOnFreeTrial as boolean}
        children={
          viewAllCarousel &&
          viewAllCarousel.zone &&
          viewAllCarousel.zone[0].content &&
          viewAllCarousel.zone[0].content[0].title
            ? viewAllCarousel.zone[0].content[0].title.textValue
            : ''
        }
      />
      <div className="view-all-content-container">
        {viewAllCarousel &&
          viewAllCarousel.zone &&
          viewAllCarousel.zone.map(zone => {
            return (
              <Fragment key={zone.zoneId}>
                {zone.content &&
                  zone.content.map(contentCarousel => {
                    return (
                      <Grid
                        pageId={pageNav.id}
                        nav={{
                          ...navIds[contentCarousel.guid],
                        }}
                        data={contentCarousel.tiles}
                        component={ChannelTile}
                        className="content-grid grid-general-style"
                        classNameItemWrapper={''}
                        maxItemsRow={5}
                        forwardFocus={forwardFocus.current}
                        animation={animation}
                        onFocus={id => {
                          if (!animation) {
                            verticalScroll(id);
                          }
                        }}
                      />
                    );
                  })}
              </Fragment>
            );
          })}
      </div>
    </Page>
  );
};
