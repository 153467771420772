import { filter } from 'rxjs/operators';
import * as _ from "lodash";
import { Observable , BehaviorSubject } from "rxjs";
import {ResponseInterceptor} from "../http/http.provider.response.interceptor";

import {
    IProviderDescriptor,
    addProvider,
    msToSeconds,
    Logger
} from "../index";
import {
    LiveTime
} from "./live-time.interface";
import { DateUtil } from "../util/date.util";
import { ChannelLineupService } from "../channellineup/channel.lineup.service";
import { MediaTimeLineService } from "../media-timeline/media.timeline.service";


export class LiveTimeService
{
    /**
     * Logger.
     */
    private static logger: Logger = Logger.getLogger("LiveTimeService");


    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the current live timestamp in seconds.
     */
    private _liveTime$ : BehaviorSubject<LiveTime>;
    public liveTime$: Observable<LiveTime>;

    /**
     * Contains permutations of the live time value in both the zero-based and zulu-based milliseconds and seconds.
     */
    private liveTime: LiveTime = new LiveTime();

    /**
     * holds the lastLivePoint when Wallclock has updated, used to check the how much wallclock and livepoint has drifted.
     */
    private lastLivePoint: LiveTime = null;

    /**
     * A subscription to the live timer interval so we can unsubscribe whenever we need to change media.
     */
    private liveTimeInterval: any = null;

    /**
     * A timer interval so we can cancel whenever we need to reset.
     */
    private wallClockInterval: any = null;

    /**
     * The number of milliseconds in between ticks
     */
    private static TIMER_INTERVAL: number = 1000;

    /**
     * The number of milliseconds to to fire off the live timer.
     */
    private static MAX_INTERVAL_OFF_BY: number = 3000;

    /**
     * The number of milliseconds behind the wall clock that the live point should
     * be. We use this if no live point comes in resume.
     */
    private static ESTIMATED_TIME_BEHIND_WALL_CLOCK: number = 170 * 1000;


    /**
     * An observable (hot, subscribe returns wall clock date synced with API
     * @type {zuluTimeStamp}
     */
    public wallClock: Observable<number> = null;

    /**
     * wallClock Data
     */
    private wallClockData: number = null;

    /**
     * subject for delivering wall clock time through the wallClock observable
     * @type {zuluTimeStamp}
     */
    private wallClockSubject: BehaviorSubject<number> = null;

    /**
     * lastWallClock value, used to check how much the wallclock is drifted with its previous value
     */
    private lastWallClock: number = null;

    /**
     *  The channel id that we have synced the live time from (if any)
     */
    private channelId : string = "";

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(LiveTimeService, LiveTimeService, [
            MediaTimeLineService,
            ResponseInterceptor,
            ChannelLineupService
        ]);
    }();

     /**
     * Constructor.
     * @param {MediaTimeLineService} mediaTimeLineService
     * @param {GeolocationService} geoLocationService
     * @param {ChannelLineupService} channelLineupService
     */
    constructor(private mediaTimeLineService: MediaTimeLineService,
                private httpIntercepter: ResponseInterceptor,
                private channelLineupService : ChannelLineupService)
    {
        this._liveTime$ = new BehaviorSubject(new LiveTime());
        this.liveTime$ = this._liveTime$.pipe(filter((time) => (time.zuluMilliseconds !== 0)));

        this.wallClockSubject = new BehaviorSubject(null);
        this.wallClock = this.wallClockSubject.pipe(filter((wallClockZulu: number) => wallClockZulu !== null));

        this.liveTime$.subscribe((liveTime:LiveTime) => this.mediaTimeLineService.updateMediaTimeLineWithLiveTime(liveTime));

        this.observeLiveTimeUpdates();

        /**
         * NOTE : in the absence of any timing information from the API, we will use the local client clock to set
         * live time.  Under most situations, we will re-sync with an API supplied time shortly.
         */
        this.reSyncInterval(Date.now() - LiveTimeService.ESTIMATED_TIME_BEHIND_WALL_CLOCK);
    }

    /**
     * Observe both the now playing data and media timeline and generate a live time basis where there's new media.
     * Also kick off an observable based interval that updates the live time by 1 second from the basis (when
     * new media was detected and the live point for it was created).
     */
    private observeLiveTimeUpdates(): void
    {
        this.mediaTimeLineService.mediaTimeLine.subscribe((response) => this.checkTuneResponse(response));
        this.httpIntercepter.wallClock.subscribe((wallClock) => this.checkWallClockRenderTime(wallClock));
    }

    public checkWallClockRenderTime(wallClockZulu: number)
    {
        if(this.lastWallClock && this.lastLivePoint )
        {
            const wallClockDrift = wallClockZulu - this.wallClockData;

            // const liveTimeDrift = this.liveTime.zuluMilliseconds - this.lastLivePoint.zuluMilliseconds;

            //TODO: Logging this to check if the drift is large than 9 sec, then we might notice change in livePoint on UI
            if(Math.abs(wallClockDrift) > 9000)
            {
                LiveTimeService.logger.warn(`WallClock is Drifted by :${wallClockDrift}, so we might see noticeable UI in the progress bar`);
            }

            if( Math.abs(wallClockDrift) > 0 )
            {
                this.reSyncInterval(this.liveTime.zuluMilliseconds + wallClockDrift);
            }
        }

        this.updateWallClock(wallClockZulu);
        this.lastWallClock = wallClockZulu;
        this.lastLivePoint = _.cloneDeep(this.liveTime);
    }

    public checkTuneResponse(tuneResponse)
    {
        if (tuneResponse
            && tuneResponse.channelId !== this.channelId
            && tuneResponse.wallClockRenderTime)
        {
            this.reSyncInterval(tuneResponse.wallClockRenderTime - LiveTimeService.ESTIMATED_TIME_BEHIND_WALL_CLOCK);

            this.channelId = tuneResponse.channelId || "";

            // ESTIMATED_TIME_BEHIND_WALL_CLOCK will change depends on the diff b/w wallClockRenderTime and liveCuePoint
            if(tuneResponse.wallClockRenderTime)
            {
                this.checkWallClockRenderTime(tuneResponse.wallClockRenderTime);
            }
        }
        else if(tuneResponse.wallClockRenderTime && DateUtil.isZulu(tuneResponse.wallClockRenderTime))
        {
            this.reSyncInterval(tuneResponse.wallClockRenderTime - LiveTimeService.ESTIMATED_TIME_BEHIND_WALL_CLOCK);
        }
    }

    private extractZulu(wallClockRenderTime)
    {
        return DateUtil.convertToDate(wallClockRenderTime).getTime();
    }

    /**
     * Updates the live time value for the player and broadcasts this change to any listening modules.
     * @param {number} zuluMilliseconds
     */
    private updateLiveTime(zuluMilliseconds)
    {
        if (!this.liveTime)
        {
            this.liveTime = new LiveTime();
        }

        this.liveTime.zuluMilliseconds = zuluMilliseconds;
        this.liveTime.zuluSeconds = msToSeconds(zuluMilliseconds);
        this._liveTime$.next(this.liveTime);
        this.channelLineupService.setChannelLivePdt(this.liveTime);
    }

    /**
     * Resets the live time values and cancels the interval.
     */
    private resetLiveTime(): void
    {
        this.cancelLiveTimeInterval();
        this.liveTime = new LiveTime();
    }

    /**
     * Cancels the live time interval.
     */
    private cancelLiveTimeInterval(): void
    {
        if (this.liveTimeInterval)
        {
            clearInterval(this.liveTimeInterval);
            this.liveTimeInterval = null;
        }
    }

    private reSyncInterval(zuluMilliseconds)
    {
        this.resetLiveTime();
        this.updateLiveTime(zuluMilliseconds);
        this.createLiveTimerInterval();
    }

    /**
     * kick off an observable based interval that updates the live time by 1 second from the basis, that being when
     * new media was detected and the live point for it was created.
     */
    private createLiveTimerInterval(): void
    {
        this.cancelLiveTimeInterval();
        this.liveTimeInterval = setInterval(onTick.bind(this), LiveTimeService.TIMER_INTERVAL);

        function onTick()
        {
            this.updateLiveTime(this.liveTime.zuluMilliseconds + LiveTimeService.TIMER_INTERVAL);
        }
    }

    /**
     * Updates the liveWallClockTime from the API
     * @param {wallClock} zuluMilliseconds
     */
    public updateWallClock(wallClockZulu)
    {
        this.wallClockData = wallClockZulu;
        this.wallClockSubject.next(this.wallClockData);

        clearInterval(this.wallClockInterval);

        this.wallClockInterval = setInterval(updateWallClockFromInterval.bind(this), LiveTimeService.TIMER_INTERVAL);

        function updateWallClockFromInterval()
        {
            this.wallClockData = this.wallClockData + LiveTimeService.TIMER_INTERVAL;
            this.wallClockSubject.next(this.wallClockData);
        }
    }
}
