export const settingsItems = [
  {
    id: 'profile-button',
    nextdown: 'tune-start-button',
    className: 'profile-button',
    classNameFocused: 'profile-button-focused',
  },
  {
    id: 'tune-start-button',
    nextup: 'profile-button',
    nextdown: 'autoplay-on-demand-button',
    className: 'middle-section-large-button',
    classNameFocused: 'middle-section-large-button-focused',
    content: 'TuneStart',
    detail: 'Start songs at the beginning when you tune to a music channel.',
    settingsName: 'TuneStart',
    toggle: true,
  },
  {
    id: 'autoplay-on-demand-button',
    nextup: 'tune-start-button',
    nextdown: 'autoplay-on-startup-button',
    className: 'middle-section-large-button',
    classNameFocused: 'middle-section-large-button-focused',
    content: 'Autoplay on Demand',
    detail:
      'At the conclusion of an On Demand episode, automatically play the Next Up episode.',
    settingsName: 'AutoPlay',
    toggle: true,
  },
  {
    id: 'autoplay-on-startup-button',
    nextup: 'autoplay-on-demand-button',
    nextdown: 'legal-button',
    className: 'middle-section-large-button',
    classNameFocused: 'middle-section-large-button-focused',
    content: 'Autoplay on Startup',
    detail:
      'When the SiriusXM app launches, automatically play the last channel you heard.',
    toggle: true,
    useStorageService: true,
  },
  {
    id: 'legal-button',
    nextup: 'autoplay-on-startup-button',
    nextdown: 'sign-out-button',
    className: 'middle-section-button',
    classNameFocused: 'middle-section-button-focused',
    content: 'Legal',
    detail:
      'Find policy details by visiting these webpages on your connected device:',
    toggle: false,
  },
  {
    id: 'sign-out-button',
    nextup: 'legal-button',
    className: 'sign-out-button',
    classNameFocused: 'sign-out-button-focused',
    content: 'Sign Out',
    toggle: false,
  },
];

export const settingsToggleItems = ['TuneStart', 'AutoPlay'];

export const legalButtonExtra = [
  {
    title: 'Customer Agreement',
    website: 'siriusxm.com/customeragreement',
  },
  {
    title: 'Privacy Policy',
    website: 'siriusxm.com/privacy',
  },
  {
    title: 'Your Privacy Choices',
    website: 'siriusxm.com/yourprivacychoices',
  },
];

export const popupCloseButtonNav = {
  id: 'popup-close-button',
  nextright: 'popup-sign-out-button',
};

export const popupSignOutButtonNav = {
  id: 'popup-sign-out-button',
  nextleft: 'popup-close-button',
};

export type SettingsConfig = {
  rightSideTitle?: string;
  rightSideDetail?: string;
  trackFocus?: string;
  autoplayOnStartup?: string;
  appVersion?: string;
  appBuild?: string;
  showVersion?: boolean;
};
