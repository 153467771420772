import React, { useRef, useEffect } from 'react';
import { withFocus } from '@accedo/vdkweb-navigation';
import './text-input.component.scss';

const TextInput = props => {
  const {
    className,
    isPassword,
    showPassword = false,
    nav,
    value,
    isFocused,
    placeholder,
    hasError,
    isPlaceholder = true,
    ...rest
  } = props;
  const theme = {
    input: `text-input ${className} ${hasError ? 'has-error' : ''}`,
    inputFocused: 'text-input-focused',
  };

  const inputEl = useRef(null);

  useEffect(() => {
    // @ts-ignore: Object is possibly 'null'.
    inputEl.current && inputEl.current.focus();
  }, [isFocused]);

  const { input: themeInput, inputFocused } = {
    ...theme,
  };

  const klassName = `${themeInput} ${nav.isFocused && inputFocused}`.trim();

  return (
    <div className={klassName} style={props.style}>
      <input
        ref={inputEl}
        onChange={() => {}}
        onKeyDown={event => event.preventDefault()}
        type={showPassword ? 'password' : 'text'}
        value={showPassword ? Array(value.length + 1).join('•') : value}
        placeholder={isPlaceholder ? placeholder : ''}
        {...rest}
      />
      {isPlaceholder ? (
        <div className="placeholder">
          <label>{placeholder}</label>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default withFocus(TextInput);
