import React, { useCallback, useEffect, useRef, useState } from 'react';
import { distinctUntilChanged } from 'rxjs/operators';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuButton from './menu-button';
import MiniPlayer from './mini-player.component';
import { focusManager } from '@accedo/vdkweb-navigation';
import {
  appRouteConstants,
  coreMenuRoutes,
} from '../../routing/app.route.constants';
import './navigation-menu.component.scss';
import { INavMap, getHorizontalNav } from '../../utils/navigationHelper';
import FocusDiv from '../focus-div/FocusDiv';
import {
  checkCoreIconWithPath,
  checkCoreNextdownWithPath,
  coreMenuItems,
} from './navigation-menu-utils';
import { MENU } from '../../utils/navigationMap';
import { ServiceFactory, BypassMonitorService } from '../../servicelib';
import { getChannel } from '../../redux/selector/now-playing.store';

const { CORE_MENU } = MENU;
const miniPlayerTimeout = { id: undefined, DELAY: 300000 };

export const CoreNavigationMenu = ({ nav, setIsCoreMenuOpen }) => {
  const location = useLocation();
  const channel = useSelector(getChannel);

  const getContainerNav = (containersList: any[]) => {
    const containerNavIds = getHorizontalNav(
      containersList.map(container => `id-${container.id}`),
      { parent: CORE_MENU },
    );

    return containerNavIds;
  };

  const containerNavIds: INavMap<string, any> = getContainerNav(coreMenuItems);
  const navNextdown = useRef<string>(
    checkCoreNextdownWithPath(location.pathname),
  );
  const [isFocused, setIsFocused] = useState<string>('');
  const [isSelected, setIsSelected] = useState<string>(
    checkCoreIconWithPath(location.pathname),
  );
  const history = useHistory();

  useEffect(() => {
    return () => {
      clearTimeout(miniPlayerTimeout.id);
    };
  }, []);

  const focusMenuButton = () => {
    focusManager.changeFocus('id-now-playing-bar');
    setIsCoreMenuOpen(true);
  };

  useEffect(() => {
    focusMenuButton();
  }, []);

  const onMenuClick = useCallback((containerId: string) => {
    setIsSelected(containerId);
    navNextdown.current = checkCoreNextdownWithPath(
      coreMenuRoutes[containerId],
    );
    history.replace(coreMenuRoutes[containerId]);
  }, []);

  const leftoverMenuItems = coreMenuItems.slice(1, coreMenuItems.length);

  //Toggles the flags if there's a bypass mode active so that the corresponding menu items get disabled.
  const [skippableMenuItems, setSkippableMenuItems] = useState({
    Favorites: false,
    Recent: false,
    Search: false,
  });
  useEffect(() => {
    const bypassMonitorService = ServiceFactory.getInstance(
      BypassMonitorService,
    ) as BypassMonitorService;

    const subscription = bypassMonitorService.bypassErrorState
      .pipe(
        distinctUntilChanged((prev, current) => {
          return (
            prev.GUP_BYPASS === current.GUP_BYPASS &&
            prev.GUP_BYPASS2 === current.GUP_BYPASS2 &&
            prev.SEARCH_BYPASS === current.SEARCH_BYPASS
          );
        }),
      )
      .subscribe(bypassErrorState => {
        skippableMenuItems['Favorites'] =
          bypassErrorState.GUP_BYPASS || bypassErrorState.GUP_BYPASS2;
        skippableMenuItems['Recent'] =
          bypassErrorState.GUP_BYPASS || bypassErrorState.GUP_BYPASS2;
        skippableMenuItems['Search'] = bypassErrorState.SEARCH_BYPASS;
        setSkippableMenuItems(skippableMenuItems);
      });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <FocusDiv nav={{ ...nav }} onFocus={focusMenuButton} className="core-menu">
      <MenuButton
        type={'player'}
        nav={{
          ...containerNavIds[`id-${coreMenuItems[0].id}`],
          nextdown: navNextdown.current,
        }}
        onClick={() => {
          if (channel) {
            history.push(appRouteConstants.NOW_PLAYING);
          }
        }}
        onFocus={() => {
          miniPlayerTimeout.id = setTimeout(
            () => history.push(appRouteConstants.NOW_PLAYING),
            miniPlayerTimeout.DELAY,
          );
          setIsFocused(coreMenuItems[0].id);
        }}
        onBlur={() => clearTimeout(miniPlayerTimeout.id)}
      >
        <MiniPlayer isFocused={isFocused === coreMenuItems[0].id} />
      </MenuButton>
      {leftoverMenuItems?.length > 0 &&
        leftoverMenuItems.map((container: any) => {
          return container ? (
            <MenuButton
              key={container.id}
              type={'icon'}
              nav={{
                ...containerNavIds[`id-${container.id}`],
                nextdown: navNextdown.current,
                skip: skippableMenuItems[container.name],
              }}
              onFocus={() => {
                setIsFocused(container.id);
              }}
              onClick={() => onMenuClick(container.id)}
            >
              <div
                className={'core-menu-icon-container'}
                style={{
                  opacity: skippableMenuItems[container.name] ? '0.25' : '1',
                }}
              >
                {isSelected === container.id ||
                isFocused === container.id ||
                (location.pathname.includes(appRouteConstants.ALL_PODCASTS) &&
                  container.id == 'discover-button') ||
                (location.pathname.includes(appRouteConstants.ALL_VIDEO) &&
                  container.id == 'discover-button') ? (
                  <img
                    src={container.iconOn}
                    className="core-menu-icon"
                    alt={`${container.iconName}' On'`}
                  />
                ) : (
                  <img
                    src={container.iconOff}
                    className="core-menu-icon"
                    alt={`${container.iconName}' Off'`}
                  />
                )}
                <div
                  className={`${
                    isSelected === container.id ||
                    (location.pathname.includes(
                      appRouteConstants.ALL_PODCASTS,
                    ) &&
                      container.id == 'discover-button') ||
                    (location.pathname.includes(appRouteConstants.ALL_VIDEO) &&
                      container.id == 'discover-button')
                      ? 'core-menu-icon-name-focused'
                      : 'core-menu-icon-name'
                  }`}
                >
                  {container.name}
                  {isSelected === container.id && (
                    <span className="underline"></span>
                  )}
                </div>
              </div>
            </MenuButton>
          ) : (
            <div />
          );
        })}
    </FocusDiv>
  );
};
