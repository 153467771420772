export interface IPlayhead {
  /**
   * The zero-based and zulu-based (time from Epoch) playhead time from the start of an episode in milliseconds and seconds.
   */
  currentTime: IPlayheadTime;

  /**
   * The zero-based and zulu-based (time from Epoch) playhead start time of an episode in milliseconds and seconds.
   */
  startTime: IPlayheadTime;

  /**
   * The id of the entity that is currently playing.
   * It might be a channelId, or a assetGUID (if additional channel)
   */
  id: string;

  /**
   * The assetGuid of the entity that is currently playing.
   * It must be a  assetGUID
   */
  assetGuid?: string;

  /**
   * The type of audio player event.
   */
  type: string;

  /**
   *  Indicates whether another playhead is taking precedence at the moment.
   */
  isBackground: boolean;

  /**
   *  Indicates if the current playhead represents a Tune Start timestamp
   */
  isTuningToTuneStart?: boolean;

  /**
   *  Indicates if the current playhead represents a tuneIn timestamp event
   */
  isTuningIn?: boolean;
}

export interface IPlayheadTime {
  /**
   * The zero-based playhead time from the start of an episode in milliseconds.
   */
  milliseconds: number;

  /**
   * The zero-based playhead time from the start of an episode in seconds.
   */
  seconds: number;

  /**
   * The zulu-based (time from Epoch) playhead time from the start of an episode in milliseconds.
   */
  zuluMilliseconds: number;

  /**
   * The zulu-based (time from Epoch) playhead time from the start of an episode in seconds.
   */
  zuluSeconds: number;
}

export class Playhead implements IPlayhead {
  /**
   * The zero-based and zulu-based (time from Epoch) playhead time from the start of an episode in milliseconds and seconds.
   */
  currentTime: IPlayheadTime;

  /**
   * The zero-based and zulu-based (time from Epoch) playhead start time of an episode in milliseconds and seconds.
   */
  startTime: IPlayheadTime;

  /**
   * default id.
   */
  id: string = '';

  /**
   * default type.
   */
  type: string = '';

  /**
   *  Indicates whether another playhead is taking precedence at the moment.
   */
  isBackground: boolean = false;

  /**
   * Constructor.
   */
  constructor() {
    this.currentTime = {
      milliseconds: 0,
      seconds: 0,
      zuluMilliseconds: 0,
      zuluSeconds: 0,
    };

    this.startTime = {
      milliseconds: 0,
      seconds: 0,
      zuluMilliseconds: 0,
      zuluSeconds: 0,
    };
  }
}
