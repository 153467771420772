export class XdkActions {
  static SAVE_LAST_FOCUSED_TILE_ID = 'SAVE_LAST_FOCUSED_TILE_ID';
  static PAGE_RIGHT = 'vdk/page/RIGHT';
  static PAGE_LEFT = 'vdk/page/LEFT';
  static SHOW_PODCASTS_MENU_OPTION = 'SHOW_PODCASTS_MENU_OPTION';
  static SHOW_VIDEOS_MENU_OPTION = 'SHOW_VIDEOS_MENU_OPTION';
  static KEY_PRESSED = 'KEY_PRESSED';
  static FFW_KEY_PRESSED = 'FFW_KEY_PRESSED';
  static REWIND_KEY_PRESSED = 'REWIND_KEY_PRESSED';
  static PLAY_PAUSE_KEY_PRESSED = 'PLAY_PAUSE_KEY_PRESSED';
  static UPDATE_FREE_TRIAL_COUNTER = 'UPDATE_FREE_TRIAL_COUNTER';
  static DOUBLE_TAP_RIGHT = 'DOUBLE_TAP_RIGHT';
  static DOUBLE_TAP_LEFT = 'DOUBLE_TAP_LEFT';
}

export const saveLastFocusedTileId = lastFocusedTile => {
  return {
    type: XdkActions.SAVE_LAST_FOCUSED_TILE_ID,
    lastFocusedTile,
  };
};

export const showPodcastsMenuOption = shouldShowPodcastsMenuOption => {
  return {
    type: XdkActions.SHOW_PODCASTS_MENU_OPTION,
    shouldShowPodcastsMenuOption,
  };
};

export const showVideosMenuOption = shouldShowVideosMenuOption => {
  return {
    type: XdkActions.SHOW_VIDEOS_MENU_OPTION,
    shouldShowVideosMenuOption,
  };
};

export const pageRight = () => {
  return {
    type: XdkActions.PAGE_RIGHT,
  };
};

export const pageLeft = () => {
  return {
    type: XdkActions.PAGE_LEFT,
  };
};

export const keyPressed = () => {
  return {
    type: XdkActions.KEY_PRESSED,
  };
};

export const ffwKeyPressed = () => {
  return {
    type: XdkActions.FFW_KEY_PRESSED,
  };
};

export const rewindKeyPressed = () => {
  return {
    type: XdkActions.REWIND_KEY_PRESSED,
  };
};

export const playPauseKeyPressed = () => {
  return {
    type: XdkActions.PLAY_PAUSE_KEY_PRESSED,
  };
};

export const updateFreeTrialCounter = timeRemaining => {
  return {
    type: XdkActions.UPDATE_FREE_TRIAL_COUNTER,
    timeRemaining,
  };
};

export const doubleTapRight = () => {
  return {
    type: XdkActions.DOUBLE_TAP_RIGHT,
  };
};

export const doubleTapLeft = () => {
  return {
    type: XdkActions.DOUBLE_TAP_LEFT,
  };
};
