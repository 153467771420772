import { DeviceInfo } from '../config/device.info';
import { IRelativeUrlSetting } from '../config';

export const parseNavigationInfo = (url: string): any => {
  const [path] = url.split('?');
  return { path: path, queryParams: parseQueryParams(url) };
};

export const parseQueryParams = (url: string): any => {
  const paramsStrIndex = url.indexOf('?');

  if (paramsStrIndex === -1) {
    return {};
  }

  return url
    .slice(paramsStrIndex + 1)
    .split('&')
    .reduce((acc, currValue) => {
      const [prop, value] = currValue.split('=');

      acc[prop] = value;

      return acc;
    }, {});
};

export const getCurrentUrlQueryParams = (): any => {
  const currentUrl = window.location.href;
  return parseQueryParams(currentUrl);
};

export function getQueryParameterValue(parameterName: string): string {
  const vars = window.location.search.substring(1).split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === parameterName) {
      return pair[1];
    }
  }

  return '';
}

/**
 * Converts milliseconds to seconds.
 * @param {number} v - The value to convert from milliseconds to seconds.
 * @param {boolean} round - Flag indicating if the result should be rounded, i.e., no decimals.
 * @return {number}
 */
export const msToSeconds = (v: number, round: boolean = false): number => {
  const result: number = v / 1000;
  return round ? Math.floor(result) : result;
};

/**
 * Converts seconds to milliseconds.
 * @param v
 * @returns {number}
 */
export const secondsToMs = (v): number => {
  return Math.floor(v * 1000);
};

/**
 * Attempts to convert a string value or "true" to a boolean true.
 * Or attempts to convert a string value or "false" to a boolean false.
 * Or simply returns the original string.
 *
 * @param {string} setting
 * @returns {boolean | string}
 */
export const convertToBoolOrString = (setting: string): boolean | string => {
  if (setting.toLowerCase() === 'true') {
    return true;
  } else if (setting.toLowerCase() === 'false') {
    return false;
  }
  return setting;
};

/**
 * Returns string converted to Title case
 * @param str
 */
export const toTitleCase = (str): string => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * Generates UUIDs.
 * @returns {string}
 */
export const generateUUID = (): string => {
  let d: number = new Date().getTime();
  const uuid: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function(c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
};

/**
 * Determines if the user's browser is a version of 11.x or greater?
 *
 * This requires moving things around since we use a 3rd party library in the client to help with
 * browser and OS detection.
 * @returns {boolean}
 */
export const isSafari11 = (deviceInfo: DeviceInfo): boolean => {
  const isSafari = deviceInfo.browser.toLowerCase() === 'safari';
  const isAtLeastVersion11 = parseFloat(deviceInfo.browserVersion) >= 11;
  return isSafari && isAtLeastVersion11;
};

/**
 * Determines if the user's browser is a safari or NOT?
 * @returns {boolean}
 */
export const isSafari = (deviceInfo: DeviceInfo): boolean => {
  return deviceInfo.browser.toLowerCase() === 'safari';
};

/**
 * Returns true if we are running in a production environment
 *
 * @param {string} endpoint is the endpoint we want to check for production
 * @returns {boolean} true is we are in production and false otherwise
 */
export function inProduction(endpoint: string) {
  return endpoint.indexOf('player.siriusxm') >= 0;
}

/**
 * Returns true if we are running in a company beta environment
 *
 * @param {string} endpoint is the endpoint we want to check for companybeta
 * @returns {boolean} true is we are in company beta and false otherwise
 */
export function inCompanyBeta(endpoint: string) {
  return endpoint.indexOf('companybeta.siriusxm') >= 0;
}

/**
 * Returns true if we are running in beta environment
 *
 * @param {string} endpoint is the endpoint we want to check for beta
 * @returns {boolean} true is we are in beta and false otherwise
 */
export function inBeta(endpoint: string) {
  return endpoint.indexOf('beta.siriusxm') >= 0 && !inCompanyBeta(endpoint);
}

/**
 * Returns true if we are running in QA environment
 *
 * @param {string} endpoint is the endpoint we want to check for QA
 * @returns {boolean} true is we are in beta and false otherwise
 */
export function inQA(endpoint: string) {
  return endpoint.indexOf('k2qa') >= 0;
}

/**
 * Returns true if we are running in dev environment
 *
 * @param {string} endpoint is the endpoint we want to check for dev
 * @returns {boolean} true is we are in beta and false otherwise
 */
export function inDev(endpoint: string) {
  return !(
    inProduction(endpoint) ||
    inCompanyBeta(endpoint) ||
    inBeta(endpoint) ||
    inQA(endpoint)
  );
}

/**
 * Returns relative URL tag replaced with actual value from the map if found, else original target value is returned
 * this method will only replace one instance of the key, assuming target only has one key that needs translation
 *
 * @param {string} target is the string that probably has the one of the key from the map
 * that needs to be replaced the value from the map
 * @returns {string} targeted string with possibly key tag translated if found
 */
export function relativeUrlToAbsoluteUrl(
  target: string,
  relativeUrls: Array<IRelativeUrlSetting>,
): string {
  target = target
    ? target
        .replace('http://pri.art.uat.streaming.siriusxm.com', '%Image%')
        .replace('http://pri.art.prod.streaming.siriusxm.com', '%Image%')
    : '';
  let returnVal = target;
  //target = target || "";
  relativeUrls.some(map => {
    let pattern = new RegExp('%' + map.name + '%');
    if (target.match(pattern)) {
      returnVal = target.replace(pattern, map.url);
      return true;
    }
  });

  // For media content (HLS or mp4), we must get the content from an https endpoint.  Here we ensure we are
  // pulling from https endpoint to avoid issues with bad API configs telling us to pull from http
  if (
    !!returnVal &&
    (returnVal.indexOf('.m3u8') >= 0 ||
      returnVal.indexOf('.aac') >= 0 ||
      returnVal.indexOf('.mp4') >= 0)
  ) {
    return returnVal.replace('http:', 'https:');
  }

  return returnVal;
}

export function translateRelativeUrlsForTheList(
  items,
  relativeUrls: Array<IRelativeUrlSetting>,
) {
  return items.map(item => {
    if (item.relativeUrl) {
      item.urlFromApi = item.url;
      item.url = relativeUrlToAbsoluteUrl(item.relativeUrl, relativeUrls);
    }
    return item;
  });
}

export function translateRelativeUrlsForAlbumArt(
  items,
  relativeUrls: Array<IRelativeUrlSetting>,
) {
  return items.map(item => {
    if (item.relativeUrl) {
      item.urlFromApi = item.url;
      item.url = relativeUrlToAbsoluteUrl(item.relativeUrl, relativeUrls);
    }
    return item;
  });
}
