import {
    IAppConfig,
    DeviceInfo
} from "../../config";

export class ModuleList
{
    public moduleList: { modules: Array<any>; };

    constructor()
    {
        this.moduleList = { modules: [] };
    }
}

export class ModuleRequest
{
    resultTemplate: string;
    deviceInfo: DeviceInfo;

    constructor()
    {
        this.resultTemplate = "";
        this.deviceInfo = null;
    }

    public setConfig(appConfig: IAppConfig)
    {
        this.resultTemplate = appConfig.resultTemplate;
        this.deviceInfo = appConfig.deviceInfo;
    }
}

export class AlternateLoginModuleRequest {
    resultTemplate: string;
    deviceInfo: any;

    constructor()
    {
        this.resultTemplate = "";
        this.deviceInfo = null;
    }

    public setConfig(appConfig: IAppConfig)
    {
        this.resultTemplate = appConfig.resultTemplate;
        this.deviceInfo = {
            deviceModel: appConfig.deviceInfo.deviceModel,
            deviceMake: appConfig.deviceInfo.deviceMake,
            deviceId: appConfig.deviceInfo.clientDeviceId,
            clientDeviceId: appConfig.deviceInfo.clientDeviceId,
            sxmAppVersion: appConfig.deviceInfo.sxmAppVersion,
        };
    }
}

export class RefreshTracksAICModuleRequest
{
    type: string;
    channelGuid: string;
    sessionId: string;
    tracks: Array<any>;
}

export class SeededRadioModuleRequest
{
    type: string;
    stationFactory: string;
    stationId: string;
    tracks: Array<any>;
}

export class ModuleListRequest
{
    public moduleList: { modules: Array<{ moduleRequest: ModuleRequest | RefreshTracksAICModuleRequest | SeededRadioModuleRequest }> };

    constructor()
    {
        const modules: Array<{ moduleRequest: ModuleRequest | RefreshTracksAICModuleRequest | SeededRadioModuleRequest }> = [];
        this.moduleList = { modules: modules };
    }

    public addModuleRequest(request: ModuleRequest | RefreshTracksAICModuleRequest | SeededRadioModuleRequest)
    {
        this.moduleList.modules.push({ moduleRequest: request });
    }
}

export class ModuleArea
{
    public moduleArea: string;
    public moduleType: string;

    constructor(area: string, type: string)
    {
        this.moduleArea = area;
        if (type) this.moduleType = type; // don't put in module type unless we have a module type
    }
}

export class ModuleAreaRequest extends ModuleArea
{
    public moduleRequest: any;

    constructor(area: string, type: string, request: any)
    {
        super(area, type);
        this.moduleRequest = request;
    }
}

export class ModuleAreaWithSubtype extends ModuleArea
{
    public moduleSubType: any;

    constructor(area: string, type: string, subType: string)
    {
        super(area, type);
        this.moduleSubType = subType;
    }
}

export class ModuleAreaRequestWithSubtype extends ModuleAreaRequest
{
    public moduleSubType: any;

    constructor(area: string, type: string, subType: string, request: any)
    {
        super(area, type, request);
        this.moduleSubType = subType;
    }
}

export class ModuleAreaDetailRequest extends ModuleArea
{
    public moduleDetails: any;

    constructor(area: string, type: string, request: any)
    {
        super(area, type);
        this.moduleDetails = request;
    }
}


export class ModuleListAreaRequest
{
    public moduleList: { modules: Array<ModuleArea> };

    constructor()
    {
        const modules: Array<ModuleAreaRequest> = [];
        this.moduleList = { modules: modules };
    }

    public addRequest(request: ModuleAreaRequest | ModuleAreaDetailRequest)
    {
        this.moduleList.modules.push(request);
    }

    public addRequests(requests: Array<ModuleArea>)
    {
        requests.forEach((request: ModuleArea) =>
        {
            this.moduleList.modules.push(request);
        });
    }
}
