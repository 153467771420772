import {
  from,
  of,
  throwError as observableThrowError,
  BehaviorSubject,
  Observable,
  concat,
} from 'rxjs';
import { first, filter, mergeMap, switchMap, take } from 'rxjs/operators';
import { IAudioPlayer } from './audio-player.interface';
import { IPlayhead } from '../playhead.interface';
import { IAudioPlayerPlayheadEvent } from './audio-player.event.interface';
import { IMediaAssetMetadata } from '../media-asset-metadata.interface';
import { IProviderDescriptor } from '../../service';
import * as _ from 'lodash';
import { addProvider } from '../../service';
import {
  ContentTypes,
  InitializationStatusCodes,
  PlayerTypes,
} from '../../index';
import { MediaUtil } from '../media.util';
import { MediaTimeLine } from '../../tune';
import { Logger } from '../../logger';
import { msToSeconds } from '../../util/utilities';
import { InitializationService } from '../../initialization';
import { MediaPlayer } from '../media-player';
import { MediaPlayerConstants } from '../media-player.consts';
import { AudioPlayerConfigFactory } from './audio-player.config.factory';
import { AudioPlayerConstants } from './audio-player.consts';
import { AudioPlayerEventMonitor } from '../audio-player.event-monitor';
import { AudioPlayerEventTypes } from './audio-player.event-types';
import { CurrentlyPlayingService } from '../../currently-playing/currently.playing.service';
import { PlayheadTimestampService } from '../playhead-timestamp.service';
import { TuneService } from '../../tune/tune.service';
import { ChromecastService } from '../../chromecast/chromecast.service';
import { ChromecastPlayerConsts } from '../chromecastplayer/chromecast-player.consts';
import { SessionTransferService } from '../../sessiontransfer/session.transfer.service';
import { MediaTimeLineService } from '../../media-timeline/media.timeline.service';
import { NoopService } from '../../noop';
import { PlayerConfig } from './audio-player.config';
import { IMediaTrigger } from '../media-player.interface';

class AudioPlayerState {
  config: PlayerConfig;
  state: string = AudioPlayerConstants.STOPPED;
}

/**
 * @MODULE:     service-lib
 * @CREATED:    09/18/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  AudioPlayerService used to play the audio.
 */
export class AudioPlayerService extends MediaPlayer {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('AudioPlayerService');

  // TODO: BMR: 10/12/2017: We may not need this but in refactoring the playhead controls to be reusable it's necessary for video right now.
  private mediaAssetMetadata: IMediaAssetMetadata = {} as IMediaAssetMetadata;

  /**
   * Indicates when the video player is ready for playback.
   */
  public playbackReady$: Observable<any> = null;

  /**
   * Indicates when the audio player is underflowing.
   */
  public bufferEmpty$: Observable<any> = null;

  /**
   * Indicates when the Audio player needs to restart.
   */
  public restart$: BehaviorSubject<any> = null;

  /**
   * Used store the current playback state.
   * @type {AudioPlayerState}
   */
  private audioPlaybackState: AudioPlayerState = new AudioPlayerState();

  private playerTypeLocal: string = PlayerTypes.LOCAL;

  private set playerState(playerState: string) {
    this.audioPlaybackState.state = playerState;
    this.playbackStateSubject.next(playerState);
  }

  private get playerState(): string {
    return this.audioPlaybackState.state;
  }

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(AudioPlayerService, AudioPlayerService, [
      'IAudioPlayer',
      InitializationService,
      TuneService,
      CurrentlyPlayingService,
      PlayheadTimestampService,
      NoopService,
      AudioPlayerConfigFactory,
      ChromecastService,
      SessionTransferService,
      MediaTimeLineService,
      AudioPlayerEventMonitor,
    ]);
  })();

  /**
   * Constructor
   * @param audioPlayer - the audio player that can be used to control audio playback
   * @param initializationService - used to determine if the app is still initialization or is running
   * @param {TuneService} tuneService
   * @param {CurrentlyPlayingService} currentlyPlayingService
   * @param {PlayheadTimestampService} playheadTimestampService
   * @param {NoopService} noopService
   * @param {AudioPlayerConfigFactory} audioPlayerConfigFactory
   * @param {ChromecastService} chromecastService
   * @param {SessionTransferService} sessionTransferService
   * @param {MediaTimeLineService} mediaTimeLineService
   * @param {AudioPlayerEventMonitor} audioPlayerEventMonitor
   */
  constructor(
    private audioPlayer: IAudioPlayer,
    private initializationService: InitializationService,
    protected tuneService: TuneService,
    protected currentlyPlayingService: CurrentlyPlayingService,
    protected playheadTimestampService: PlayheadTimestampService,
    private noopService: NoopService,
    private audioPlayerConfigFactory: AudioPlayerConfigFactory,
    private chromecastService: ChromecastService,
    protected sessionTransferService: SessionTransferService,
    protected mediaTimeLineService: MediaTimeLineService,
    private audioPlayerEventMonitor: AudioPlayerEventMonitor,
  ) {
    super(
      tuneService,
      currentlyPlayingService,
      sessionTransferService,
      mediaTimeLineService,
    );

    this.playbackTypes = [
      /*
      ContentTypes.LIVE_AUDIO,
      ContentTypes.AOD,
      */
      ContentTypes.PODCAST,
    ];

    this.playbackReady$ = this.audioPlayerEventMonitor.playbackReady$;
    this.playhead$ = this.audioPlayerEventMonitor.playhead$;
    this.playbackComplete$ = this.audioPlayerEventMonitor.playbackComplete$;
    this.bufferEmpty$ = this.audioPlayerEventMonitor.bufferEmpty$;
    this.restart$ = this.audioPlayerEventMonitor.restart$;
    this.playbackStateSubject = new BehaviorSubject(this.playerState);
    this.playbackState = this.playbackStateSubject;

    audioPlayer.addEventListener(
      AudioPlayerEventTypes.RESTART,
      handleFailure.bind(this),
    );
    audioPlayer.addEventListener(
      AudioPlayerEventTypes.BUFFER_EMPTY,
      onBufferEmpty.bind(this),
    );

    /**
     * TODO: Need to work on audio add event listener to make it trigger.
     * audioPlayer.addEventListener(AudioPlayerEventTypes.FINISHED, onFinished.bind(this));
     */

    this.playbackComplete$.pipe(filter(val => val === true)).subscribe(val => {
      if (!this.isCurrent) return;
      this.playerState = AudioPlayerConstants.FINISHED;
    });

    // Note: Do this last, because it could cause observables to trigger that may be dependent on the object
    // being completely set up
    this.setSubscribers();
    this.monitorPlaybackComplete();

    /**
     * When playing on demand content it's highly possible that the buffer empty event triggers before the
     * finished event from the audio player. In this case buffer empty is expected and not a true
     * error; we'll determine if this is an actual error if this is not the last chunk being played.
     *
     * NOTE: The reason this happens is due to the fact that the web audio player calculates an approximate
     * playhead timestamp. We've (Brian Riley and Chris Christine) discussed possibly changing it to derive
     * the value from the native AudioContext and not calculating it, BUT this is a huge change to the audio
     * player's underpinnings that could introduce massive regression defects and requires everything in the
     * app to be retested (as the playhead is the heartbeat of the app).
     *
     * @param {IAudioPlayerPlayheadEvent} event
     */
    function onBufferEmpty(event: IAudioPlayerPlayheadEvent) {
      if (!this.isCurrent) return;
      const lastChunkInterval: number = 1000 * 10;
      const isOnDemand: boolean = MediaUtil.isAudioMediaTypeOnDemand(
        this.mediaType,
      );
      const isLastChunk: boolean =
        event.duration - event.playhead <= lastChunkInterval;
      const isFinished: boolean =
        this.playerState === AudioPlayerConstants.FINISHED;

      if (isOnDemand && !isLastChunk && !isFinished) {
        handleFailure(event);
      }
    }

    function handleFailure(event: IAudioPlayerPlayheadEvent) {
      if (!this.isCurrent) return;
      AudioPlayerService.logger.error(`onFailure( ${event.type} )`);
      this.playerState = AudioPlayerConstants.STOPPED;
      this.tuneMediaTimeLine = null;
    }

    function onFinished(event: IAudioPlayerPlayheadEvent) {
      if (!this.isCurrent) return;
      AudioPlayerService.logger.debug(`onFinished( ${event} )`);
      this.playerState = AudioPlayerConstants.FINISHED;
    }
  }

  /**
   * Used to Pause or Resume based on playback state.
   */
  public togglePausePlay(): Observable<string> {
    const state: string = this.playerState;
    AudioPlayerService.logger.debug(`togglePausePlay( state: ${state} )`);
    switch (state) {
      case AudioPlayerConstants.PLAYING:
        return this.pause();
      case AudioPlayerConstants.PAUSED:
        return this.resume();
      case AudioPlayerConstants.PRELOADING:
      case AudioPlayerConstants.STOPPED:
      case AudioPlayerConstants.PLAY:
        return this.play();
      case AudioPlayerConstants.FINISHED:
        return this.retune();
      case AudioPlayerConstants.IDLE: {
        return this.sessionTransfer().pipe(
          switchMap(() => this.stop(-1)),
          take(1),
          switchMap(() => {
            const playhead: IPlayhead = this.playheadTimestampService.playhead.getValue();
            const startTime =
              playhead.assetGuid === MediaUtil.getAssetId(this.mediaTimeLine)
                ? playhead.currentTime.zuluMilliseconds
                : this.mediaTimeLine.startTime;
            this.startAudio(startTime);
            return this.playerState;
          }),
        );
      }
      default:
        AudioPlayerService.logger.warn(
          `togglePausePlay( Unknown State: ${this.playerState} )`,
        );
        return observableThrowError('error') as any;
    }
  }

  /**
   * Starts audio playback and returns the current state as "playing" when complete.
   *
   * @param {number} startTime - Optional parameter that tells the audio player the start time in milliseconds from
   *     epoch, aka zulu.
   * @returns {Observable<string>}
   */
  public play(startTime: number = 0): Observable<any> {
    const sessionTransferObservable = this.sessionTransfer();
    sessionTransferObservable.subscribe(() => {
      const config: PlayerConfig = this.getConfig();
      config.startTime = startTime;

      // Save the current config object for use later.
      this.audioPlaybackState.config = config;

      // Shortcut to the current playback URL.
      const url = config.primaryAudioURL;

      AudioPlayerService.logger.debug(`play( ${url} )`);

      let prevState: string = this.playerState;

      this.playerState = AudioPlayerConstants.PLAY;

      this.hasWarmedUp = true;

      return from(
        this.audioPlayer
          .play(url, this.audioPlaybackState.config)
          .then(playSuccess.bind(this))
          .catch(playFault.bind(this)),
      );

      function playSuccess(result: any) {
        /**
         * Failure Race condition
         * It is possible that in the recovery from certain failure conditions, we will get back a result here
         * that is undefined.  Playback is ready, but paused.  In this case we can resume playback, wait for the
         * resume to come back, and then we will be playing
         */

        let resumeObs = !result ? this.resume() : of(result);
        resumeObs = concat(this.handlePodcastRestart(), resumeObs);

        resumeObs.pipe(first()).subscribe(() => {
          AudioPlayerService.logger.debug(`playSuccess()`);

          this.resume();

          // Set the state to playing/paused.
          if (this.isPlaying()) {
            // Sequencing Playhead callback followed by Playstate to normalize HLS and Media Element player.
            this.playhead$
              .pipe(
                filter(
                  (data: IPlayhead) =>
                    this.audioPlaybackState.config.id === data.id,
                ),
                take(1),
              )
              .subscribe(() => {
                this.playerState = AudioPlayerConstants.PLAYING;
              });
          } else {
            this.playerState = AudioPlayerConstants.PAUSED;
          }
        });

        return result;
      }

      function playFault(fault) {
        AudioPlayerService.logger.error(`playFault( ${fault} )`);
        this.playerState =
          prevState === AudioPlayerConstants.STOPPED
            ? AudioPlayerConstants.PAUSED
            : prevState;
        throw fault;
      }
    });
    return sessionTransferObservable;
  }

  /**
   * Preloads audio playback and returns the current state as "paused" when complete.
   *
   * @param {number} startTime - Optional parameter that tells the audio player the start time in milliseconds from
   *     epoch, aka zulu.
   * @returns {Observable<string>}
   */
  public preload(startTime: number = 0): Observable<any> {
    this.playerState = AudioPlayerConstants.PRELOADING;

    if (this.audioPlaybackState.config) {
      this.stop(this.audioPlaybackState.config.id);
    }

    this.audioPlaybackState.config = this.getConfig();
    this.audioPlaybackState.config.startTime = startTime;
    const url = this.audioPlaybackState.config.primaryAudioURL;

    AudioPlayerService.logger.debug(`preload( ${url} )`);

    let finalState: string = this.playerState;

    const promise = this.audioPlayer
      .preload(url, this.audioPlaybackState.config)
      .then(preloadSuccess.bind(this))
      .catch(preloadFault.bind(this))
      .finally(() => {
        // always trigger observable, even if state has not changed
        this.playerState = finalState;
        this.handlePodcastRestart().subscribe();
      });
    return from(promise);

    function preloadSuccess(result: any) {
      AudioPlayerService.logger.debug(`preloadSuccess()`);

      // If we are not in the playing state, set the playback state to paused.  If we are in the playing state,
      // then the user hit play while we were preloading, and the play request put us in the playing state, in
      // which case we do not want to change the state to paused because we are actually playing.
      if (this.playerState !== AudioPlayerConstants.PLAYING) {
        finalState = AudioPlayerConstants.PAUSED;
      }
      return result;
    }

    function preloadFault(fault) {
      AudioPlayerService.logger.error(`preloadFault( ${fault} )`);
      throw fault;
    }
  }

  /**
   * Used to stop Audio for given mediaId
   * @param {string} id
   */
  public stop(id: string | number): Observable<any> {
    let playbackId = id ? id : -1;

    AudioPlayerService.logger.debug(`stop( ${playbackId} )`);

    let finalState: string = this.playerState;

    const stopPromise = this.audioPlayer
      .stop(playbackId)
      .then(stopSuccess.bind(this))
      .catch(stopFault.bind(this))
      .finally(() => {
        this.playerState = finalState;
      }); // always trigger observable, even if state has not changed

    function stopSuccess() {
      AudioPlayerService.logger.debug(`stopSuccess()`);
      finalState = AudioPlayerConstants.STOPPED;
      return;
    }

    function stopFault(fault) {
      AudioPlayerService.logger.warn(`stopFault( ${fault} )`);
      throw fault;
    }
    return from(stopPromise);
  }

  /**
   * Used to Pause the audio player.
   */
  public pause(): Observable<string> {
    AudioPlayerService.logger.debug(`pause()`);

    let finalState: string = this.playerState;

    const promise = this.audioPlayer
      .pause()
      .then(pauseSuccess.bind(this))
      .catch(pauseFault.bind(this))
      .finally(() => {
        this.playerState = finalState;
      }); // always trigger observable, even if state has not changed

    return from(promise);

    function pauseSuccess(result: any) {
      AudioPlayerService.logger.debug(`pauseSuccess()`);
      finalState = AudioPlayerConstants.PAUSED;
      return finalState;
    }

    function pauseFault(fault) {
      AudioPlayerService.logger.warn(`pauseFault( ${fault} )`);
      throw fault;
    }
  }

  /**
   * Used to resume the audio player.
   */
  public resume(): Observable<any> {
    const sessionTransferObs = this.sessionTransfer();
    return sessionTransferObs.pipe(
      switchMap(() => {
        AudioPlayerService.logger.debug(`resume()`);
        this.hasWarmedUp = true;

        const promise = this.audioPlayer
          .resume()
          .then(resumeSuccess.bind(this))
          .catch(resumeFault.bind(this));

        return from(promise);

        function resumeSuccess() {
          AudioPlayerService.logger.debug(`resumeSuccess()`);
          this.playerState = this.isPlaying()
            ? AudioPlayerConstants.PLAYING
            : this.playerState;

          return this.playerState;
        }

        function resumeFault(fault) {
          AudioPlayerService.logger.warn(`resumeFault( ${fault} )`);
          throw fault;
        }
      }),
    );
  }

  /**
   * Invokes the seek method on the audio player.
   *
   * @param {number} timestamp
   */
  public seek(timestamp: number): Observable<string> {
    const sessionTransferObs = this.sessionTransfer();
    return sessionTransferObs.pipe(
      switchMap(() => {
        const endpoint: string = AudioPlayerConstants.PRIMARY_END_POINT;
        const size: string = AudioPlayerConstants.LARGE_SIZE;
        const url: string = this.audioPlayerConfigFactory.getAudioUrl(
          endpoint,
          size,
        );

        AudioPlayerService.logger.debug(`seek( ${timestamp}, ${url} )`);

        // Save the seek time for BI consume purposes.
        this.lastSeekTime = timestamp;

        // The final player state after the seeking state is finished.
        let finalState: string = this.playerState;

        // Were in the act of seeking, so set this state for consume purposes and it'll later be set to
        // the last known state once the seek is completed.
        this.playerState = AudioPlayerConstants.SEEKING;

        const promise = this.audioPlayer
          .seek(timestamp, url)
          .then(seekSuccess.bind(this))
          .catch(seekFault.bind(this));

        return from(promise);

        function seekSuccess() {
          AudioPlayerService.logger.debug(`seekSuccess()`);
          this.playerState =
            this.playerState !== AudioPlayerConstants.FINISHED
              ? finalState
              : this.playerState;
        }

        function seekFault(fault) {
          AudioPlayerService.logger.warn(`seekFault( ${fault} )`);
          throw fault;
        }
      }),
    );
  }

  /**
   * Accessor to the player's ID.
   * @returns {boolean}
   */
  public getId(): string {
    return 'audio-player-a';
  }

  /**
   * Gets the duration for the currently playing audio content in milliseconds.
   * @returns {number}
   */
  public getDuration(): number {
    const duration: number = this.audioPlayerEventMonitor.getDuration();
    if (isNaN(duration)) {
      return this.currentlyPlayingService.getDuration();
    }
    return duration;
  }

  /**
   * Gets the duration for the currently playing audio content in seconds.
   * @returns {number}
   */
  public getDurationInSeconds(): number {
    return msToSeconds(this.getDuration());
  }

  /**
   * Getter for the zero-based playhead timestamp in milliseconds.
   * @returns {number}
   */
  public getPlayheadTime(): number {
    const playheadZulu: number = this.getPlayheadZuluTime();
    const zuluStartTime: number = this.getEpisodeStartTimeZulu();
    const zuluEndTime: number = this.getEpisodeEndTimeZulu();
    const liveTimestamp: number = _.get(
      this.mediaTimeLine,
      'liveCuePoint.times.zuluStartTime',
      0,
    );
    const duration: number = (zuluEndTime - zuluStartTime) / 1000;
    const playheadSeconds: number = (playheadZulu - zuluStartTime) / 1000;
    const liveSeconds: number = (liveTimestamp - zuluStartTime) / 1000;
    const result: number = playheadZulu - zuluStartTime;

    // TODO: BMR: 10/14/2017: Keep this here for now as the playhead work is still under dev and this is extremely helpful.
    // console.log("bmr episode start", zuluStartTime);
    // console.log("bmr episode end", this.getEpisodeEndTimeZulu());
    // console.log("bmr episode duration", duration);
    // console.log("bmr playhead", playheadZulu);
    // console.log("bmr playhead seconds", playheadSeconds);
    // console.log("bmr live", liveTimestamp);
    // console.log("bmr live seconds", liveSeconds);
    // console.log("bmr playhead in range", ( (playheadZulu >= zuluStartTime) && (playheadZulu <= zuluEndTime) ));
    // console.log("bmr live in range", ( (liveTimestamp >= zuluStartTime) && (liveTimestamp <= zuluEndTime) ));
    // console.log("bmr live < duration", (liveSeconds <= duration));

    return result >= 0 ? result : 0;
  }

  /**
   * Getter for the zero-based playhead timestamp in seconds.
   * @returns {number}
   */
  public getPlayheadTimeInSeconds(): number {
    return msToSeconds(this.getPlayheadTime());
  }

  /**
   * Getter for the UNIX-based/Epoch playhead timestamp in milliseconds.
   * @returns {number}
   */
  public getPlayheadZuluTime(): number {
    return this.audioPlayerEventMonitor.getPlayheadTime();
  }

  /**
   * Getter for the initial playhead value of the current video in seconds.
   * @returns {number}
   */
  public getPlayheadStartTime(): number {
    return this.audioPlayerEventMonitor.getPlayheadStart();
  }

  /**
   * Getter for the UNIX-based/Epoch initial playhead timestamp in milliseconds.
   * @returns {number}
   */
  public getPlayheadStartZuluTime(): number {
    return this.audioPlayerEventMonitor.getPlayheadStart();
  }

  /**
   * Used to get playback type based on Live or AOD
   * @returns {string}
   */
  public getPlaybackType(): string {
    if (this.isLive()) {
      return AudioPlayerConstants.LIVE;
    } else if (MediaUtil.isAudioMediaTypeOnDemand(this.mediaType)) {
      return AudioPlayerConstants.AOD;
    } else if (MediaUtil.isPodcastMediaType(this.mediaType)) {
      return AudioPlayerConstants.PODCAST;
    }

    return '';
  }

  public getDefaultType(): string {
    return 'audio';
  }

  /**
   * Getter for the state of the player.
   * @returns {string}
   */
  public getState(): string {
    return this.playerState;
  }

  /**
   * Getter for the type of player.
   * @returns {string}
   */
  public getType(): string {
    return AudioPlayerConstants.TYPE;
  }

  /**
   * Getter for the media asset metadata.
   *
   * TODO: BMR: 11/02/2017: This may not be necessary.
   *
   * @returns {IMediaAssetMetadata}
   */
  public getMediaAssetMetadata(): IMediaAssetMetadata {
    return this.mediaAssetMetadata;
  }

  /**
   * Destroys the player.
   */
  public destroy(): void {}

  /**
   * Indicates if the player is playing.
   * @returns {boolean}
   * TODO: Audio player should emit false when audio is finished then remove the Finish status check
   */
  public isPlaying(): boolean {
    return this.audioPlayer ? this.audioPlayer.isPlaying() : false;
  }

  /**
   * Indicates if the player is paused.
   * @returns {boolean}
   */
  public isPaused(): boolean {
    return this.audioPlayer ? this.audioPlayer.isPaused() : false;
  }

  /**
   * Indicates if the player is stopped.
   * @returns {boolean}
   */
  public isStopped(): boolean {
    return this.audioPlayer ? this.audioPlayer.isStopped() : false;
  }

  /**
   * Indicates if the player is finished.
   * @returns {boolean}
   */
  public isFinished(): boolean {
    return (
      this.playerState.toLowerCase() ===
      AudioPlayerConstants.FINISHED.toLowerCase()
    );
  }

  /**
   * Indicates the player volume
   * @returns {number}
   */
  public getVolume(): number {
    let volume = this.audioPlayer.getVolume();

    return MediaUtil.isVolumeValid(volume, 1)
      ? volume * MediaPlayerConstants.MAX_VOLUME_VALUE
      : NaN;
  }

  /**
   * Sets the volume to audio player
   * @param {number} volume - volume
   */
  public setVolume(volume: number): void {
    if (this.audioPlayer) {
      const value: number = volume / MediaPlayerConstants.MAX_VOLUME_VALUE;
      AudioPlayerService.logger.debug(`setVolume( ${value} )`);
      this.audioPlayer.setVolume(value);
    }
  }

  /**
   * Mutes the volume of the audio player.
   */
  public mute(): void {
    if (this.audioPlayer) {
      this.isMuted = true;
      this.mutedVolume = this.getVolume();
      AudioPlayerService.logger.debug(
        `mute( Last known volume: ${this.mutedVolume} )`,
      );
      this.setVolume(0);
    }
  }

  /**
   * Unmutes the volume of the audio player.
   */
  public unmute(): void {
    if (this.audioPlayer) {
      this.isMuted = false;
      AudioPlayerService.logger.debug(
        `unmute( Last known volume: ${this.mutedVolume} )`,
      );
      this.setVolume(this.mutedVolume);
    }
  }

  /**
   * Used to determine if the player is live.
   * @returns {boolean}
   */
  public isLive(): boolean {
    return MediaUtil.isAudioMediaTypeLive(this.mediaType);
  }

  /**
   * Used to invoke web audio player play function .
   */
  private startAudio(startTime: number = 0): void {
    AudioPlayerService.logger.debug(`startAudio( Start time = ${startTime} )`);

    this.initializationService.initState
      .pipe(
        // wait for app to be RUNNING
        filter(initState => initState === InitializationStatusCodes.RUNNING),
        mergeMap(() => this.sessionTransferService.sessionClaimed),
        // wait for app to own the user session
        filter(sessionClaimed => sessionClaimed === true),
        mergeMap(() => this.chromecastService.state$),
        // wait until Chromecast is NOT connected
        filter(
          castState => castState !== ChromecastPlayerConsts.STATE.CONNECTED,
        ),
        take(1),
      )
      .subscribe(castState => {
        // Kickoff the NoOp service to ensure fresh CDN access tokens as soon as the audio starts playback.
        this.noopService.start();

        //Auto play handled on SDK
        this.play(startTime).subscribe(
          startAudioSuccess.bind(this),
          startAudioFault,
        );
      });

    function startAudioSuccess() {
      AudioPlayerService.logger.debug(`startAudioSuccess()`);
    }

    function startAudioFault(fault) {
      AudioPlayerService.logger.warn(`startAudioFault( ${fault} )`);
    }
  }

  /**
   * Creates the config object can be used to send config to audio player.
   * Based on config data audio player plays audio.
   * @returns {PlayerConfig}
   */
  public getConfig(): PlayerConfig {
    return this.audioPlayerConfigFactory.getConfig(
      this.mediaTimeLine,
      this.playerState,
    );
  }

  /**
   * Once tuneMediaTimeLine available, updates the Media end point urls and starts the Audio.
   * @param {MediaTimeLine} mediaTimeLine
   * @param {IMediaTrigger} mediaTrigger
   */
  protected onNewMedia(
    mediaTimeLine: MediaTimeLine,
    mediaTrigger: IMediaTrigger,
  ) {
    if (this.isCurrentPlayer(mediaTimeLine.mediaType)) {
      if (mediaTimeLine.playerType === PlayerTypes.REMOTE) {
        if (
          this.playerState === AudioPlayerConstants.STOPPED ||
          this.playerState === AudioPlayerConstants.IDLE
        ) {
          return;
        }
        this.chromecastService.autoplay$.next(this.isPlaying());
        this.audioPlayer.pause();
        this.playerState = AudioPlayerConstants.IDLE;
        this.playerTypeLocal = mediaTimeLine.playerType;
      } else if (
        this.playerTypeLocal === PlayerTypes.REMOTE &&
        mediaTimeLine.playerType === PlayerTypes.LOCAL
      ) {
        this.playheadTimestampService.playhead
          .pipe(first())
          .subscribe(playhead => {
            this.audioPlayerEventMonitor.playhead$.next(playhead);
            this.playerTypeLocal = PlayerTypes.LOCAL;
          });
      } else {
        AudioPlayerService.logger.debug(
          `onMediaTimeLineSuccess( ID: ${mediaTimeLine.mediaId} )`,
        );

        const isAudio: boolean = MediaUtil.isAudioMediaType(
          mediaTimeLine.mediaType,
        );
        const currentMediaType: string = this.mediaType;
        const isNewMediaType: boolean =
          currentMediaType !== mediaTimeLine.mediaType;
        const startAudio: boolean = isAudio;
        const isForceRetune: boolean = mediaTimeLine.forceRetune;
        const stopAudio: boolean =
          (!!currentMediaType && isNewMediaType) || isForceRetune;

        if (stopAudio) {
          this.stop(-1);
        }

        if (startAudio) {
          this.startAudio(this.lastMediaPlayerPlayheadZulu);
        } else {
          this.mediaTimeLine = null;
        }
      }
    } else {
      // If we are switching to different mediaType other than Audio, then stop everything with audio
      if (!MediaUtil.isAudioMediaType(this.mediaType)) {
        this.stop(-1);
      } else {
        if (this.audioPlaybackState.config) {
          this.stop(this.audioPlaybackState.config.id);
        }
      }
    }
  }

  /**
   * Set up subscribers to observables.
   */
  protected setSubscribers(): void {
    super.setSubscribers();
    AudioPlayerService.logger.debug(`setSubscribers()`);
  }

  public warmUp(): Observable<string> {
    if (this.hasWarmedUp) return of('warmed up');
    const obs = from(this.audioPlayer.warmUp());
    obs.pipe(take(1)).subscribe(() => {
      this.hasWarmedUp = true;
    });
    return obs;
  }

  /**
   *  Iris Podcast Episode Duration from API tune is Incorrect, so handling restart episode
   *  by checking actual duration from media player with startTime and using buffer time (edgecase) of 10000ms.
   */
  public handlePodcastRestart() {
    let seekObs: Observable<any> = of(true);
    // For edge case time in ms if actual edisode duration < pausePoint(StartTime)
    const edgeTimeinMs = 10000;
    const actualDuration = this.audioPlayer.getDuration() - edgeTimeinMs;

    if (
      MediaUtil.isPodcastMediaType(this.mediaType) &&
      this.audioPlaybackState.config.startTime >= actualDuration
    ) {
      seekObs = this.seek(0);
    }
    return seekObs;
  }
}
